import { UserStore } from '@/entities/user'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { formatEther } from 'viem'
import { useAccount, useBalance } from 'wagmi'

export const useWallet = () => {
	const { address: walletAddress, chainId, connector } = useAccount()
	const { signatureAddress } = UserStore()
	const { contracts } = ContractsStore()

	const token = contracts.SavvaToken.address
	const staked = contracts.Staking.address
	const address = signatureAddress || walletAddress
	
	const { data: savvaBalance } = useBalance({
		address,
		token
	})

	const { data: stakedBalance } = useBalance({
		address,
		token: staked
	})
	
	const { data: plsBalance, refetch: refetchPls } = useBalance({
		address
	})

	const differentAuth = signatureAddress && signatureAddress !== address

	const getLowStake = (amount: number) => (stakedBalance?.value || 0) > amount
	
	return {
		address,
		differentAuth,
		plsBalance: plsBalance?.value ? Number(formatEther(plsBalance.value)) : 0,
		plsBalanceUnparced: plsBalance?.value || 0,
		savvaBalance: savvaBalance?.value ? Number(formatEther(savvaBalance.value)) : 0,
		savvaBalanceUnparced: savvaBalance?.value || 0,
		chainId,
		provider: connector?.name,
		stakedBalance: stakedBalance?.value ? Number(formatEther(stakedBalance.value)) : 0,
		stakedBalanceUnparced: stakedBalance?.value || 0,
		getLowStake,
		refetchPls
	}
}
