import Fundraise from "@/features/fundraise-block/ui/fundraise"
import { useParams } from "react-router-dom"

const FundraisePage = () => {
const { id } = useParams()
  return (
    <div>
        <Fundraise id={id} />
    </div>
  )
}

export default FundraisePage