import { useMemo, useCallback, useEffect } from 'react'
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { useToast } from './use-toast'
import { LangStore } from '../model/store'



type Props = {
	onSuccess?: (res) => void
	onError?: () => void
	hideMessage?: boolean
}

const useNotifier = ({ onSuccess, onError, hideMessage = false }: Props = {}) => {
    const { toast } = useToast()
    const { langPack } = LangStore()
	
    const { writeContract, writeContractAsync, isPending, data, isError, error, isSuccess } = useWriteContract()
	if (isError) {
		console.error('Error in useWriteContract', error)
	}
	const {
		isLoading: isConfirming,
		isSuccess: isConfirmed,
		isError: isErrorTransaction
	} = useWaitForTransactionReceipt({
		hash: data
	})

	const key = useMemo(() => (Math.random() * Math.random()).toString(), [])
	const showNotification = useCallback(
		(config) => {
			toast({
				duration: config.duration,
				key,
				title: config.message,
				description: config.description,
				type: config.type
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[key]
	)

	useEffect(() => {
		if (isPending && !hideMessage) {
			showNotification({
				duration: 5000,
				message: langPack('notification.transaction_completed'),
				description: `${langPack('notification.transaction_completed_desc_one')} PROVIDER ${langPack(
					'notification.transaction_completed_desc_two'
				)}`,
				variant: 'sucsess'
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPending])

	useEffect(() => {
		if (isConfirming && !hideMessage) {
			showNotification({
				duration: 5000,
				message: langPack('notification.in_progress'),
				type: 'process',
				description: `${langPack('notification.in_progress_desc_one')} ${data} ${langPack(
					'notification.in_progress_desc_two'
				)}`
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConfirming])

	useEffect(() => {
		if (isSuccess && !hideMessage) {
			showNotification({
				duration: 5000,
				message: langPack('notification.ready'),
				description: langPack('notification.ready_desc')
			})
			onSuccess && onSuccess(data)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConfirmed])

	useEffect(() => {
		if (isError || isErrorTransaction) {
			showNotification({
				duration: 5000,
				message: langPack('notification.declined'),
				description: langPack('notification.declined_desc')
			})
			onError && onError()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError])

	const isLoading = isPending || isConfirming

	return {
		writeContract,
		writeContractAsync,
		isLoading,
		isConfirmed,
		isConfirming,
		isSuccess,
		isError,
		data
	}
}

export default useNotifier
