import { useTheme } from '@/shared/lib'
import { LangStore } from '@/shared/model/store'
import { Tabs, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import { Moon, Sun } from 'lucide-react'

export const ThemeToggleRow = () => {
	const { setTheme, theme } = useTheme()
	const { langPack } = LangStore()

	return (
		<Tabs onValueChange={setTheme} value={theme} className='mb-2 flex w-full'>
			<TabsList className='w-full'>
				<TabsTrigger value='light' className='w-full gap-4'>
					<Sun className='h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90' />
					{langPack('default.light')}
				</TabsTrigger>
				<TabsTrigger value='dark' className='w-full gap-4'>
					<Moon className='dark:rotate-360 h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all' />
					{langPack('default.dark')}
				</TabsTrigger>
			</TabsList>
		</Tabs>
	)
}
