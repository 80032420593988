import { DEFAULT_PREC } from "@/shared/lib"

export interface TTypeConfig {
    name: string
    type: string
    units: string
    description: string
}


export const typesGet = {
    Uint: 'getUInt',
    Address: 'getAddr',
    str: 'get',
}

export const typesSet = {
    setAddr: 'setAddr',
    Uint: 'setUInt',
    str: 'set',
}

export const calculateUnits = {
    x100: (value: number) => `${Number(value) / 100}%`,
    SAVVA: (value: string) => `${Number(value) / DEFAULT_PREC} SAVVA`,
    PLS: (value: string) => `${Number(value) / DEFAULT_PREC} PLS`,
    seconds: (value: string) => `${value} seconds`,
    percents: (value: string) => `${value} %`,
    str: (value: string) => value,
}

export const calculateRevertUnits = {
    x100: (value: number) => Number(value) * 100,
    SAVVA: (value: string) => Number(value) * DEFAULT_PREC,
    PLS: (value: string) =>  Number(value) * DEFAULT_PREC,
    seconds: (value: string) => Number(value),
    percents: (value: string) => Number(value),
    str: (value: string) => value,
}

export const typeOptions = [
    {
        label: "Uint",
        value: 'Uint'
    },
    {
        label: "Address",
        value: 'Address'
    },
    {
        label: "String",
        value: 'str'
    },
]

export const typeUnits = [
    {
        label: "x100 percent",
        value: 'x100'
    },
    {
        label: "SAVVA",
        value: 'SAVVA'
    },
    {
        label: "PLS",
        value: 'PLS'
    },
    {
        label: "Seconds",
        value: 'seconds'
    },
    {
        label: "Percents",
        value: 'percents'
    },
    {
        label: "String",
        value: 'str'
    },
]

export const types_config: TTypeConfig[] = [
    {
      "name": "authorShare",
      "type": "Uint",
      "units": "x100",
      "description": "share of the author in the content fund ( 100 = 1%)"
    },
    {
      "name": "nftOwnerCut",
      "type": "Uint",
      "units": "x100",
      "description": "NFT owner's cut in percents * 100 ( 100 = 1%)"
    },
    {
      "name": "minContribution",
      "type": "Uint",
      "units": "SAVVA",
      "description": "minimum contribution to the content fund in SAVVA tokens"
    },
    {
      "name": "timeForRound",
      "type": "Uint",
      "units": "seconds",
      "description": "how long is the round of the content fund in seconds"
    },
    {
      "name": "winnerShare",
      "type": "Uint",
      "units": "percents",
      "description": "round prize share of the fund in percents"
    },
    {
      "name": "minFundToShare",
      "type": "Uint",
      "units": "SAVVA",
      "description": "minimum fund to share in SAVVA tokens"
    },
    {
      "name": "staking_withdraw_delay",
      "type": "Uint",
      "units": "seconds",
      "description": "staking cooldown time in seconds"
    },
    {
      "name": "contentNFT_mintPrice",
      "type": "Uint",
      "units": "PLS",
      "description": "price to mint a content NFT"
    },
    {
      "name": "pulsex_slippage",
      "type": "Uint",
      "units": "percents",
      "description": "slippage tolerance ( reserve/amount min)"
    },
    {
      "name": "min_staked_to_post",
      "type": "Uint",
      "units": "SAVVA",
      "description": "minimum staked amount to post content"
    },
    {
      "name": "sac_min_deposit",
      "type": "Uint",
      "units": "PLS",
      "description": "minimum deposit to sac"
    },
    {
      "name": "patron_payment_period",
      "type": "Uint",
      "units": "seconds",
      "description": "patron payment period in seconds"
    },
    {
      "name": "gov_proposal_price",
      "type": "Uint",
      "units": "PLS",
      "description": "price to create a proposal"
    },
    {
      "name": "contract_savvaToken",
      "type": "Address",
      "units": "",
      "description": "SAVVA token contract"
    },
    {
      "name": "contract_randomOracle",
      "type": "Address",
      "units": "",
      "description": "random oracle (0 - no oracle)"
    },
    {
      "name": "contract_staking",
      "type": "Address",
      "units": "",
      "description": "Staking contract"
    },
    {
      "name": "contract_userProfile",
      "type": "Address",
      "units": "",
      "description": "Staking contract"
    },
    {
      "name": "contract_contentNFT",
      "type": "Address",
      "units": "",
      "description": "Content NFT contract"
    },
    {
      "name": "contract_contentFund",
      "type": "Address",
      "units": "",
      "description": "Content Fund contract"
    },
    {
      "name": "contract_governance",
      "type": "Address",
      "units": "",
      "description": "Governance contract"
    },
    {
      "name": "contract_contentRegistry",
      "type": "Address",
      "units": "",
      "description": "Content Registry contract"
    },
    {
      "name": "contract_savvaFaucet",
      "type": "Address",
      "units": "",
      "description": "SAVVA Faucet contract"
    },
    {
      "name": "contract_nftMarketplace",
      "type": "Address",
      "units": "",
      "description": "NFT Market Place contract"
    },
    {
      "name": "contract_promo",
      "type": "Address",
      "units": "",
      "description": "Promo contract"
    },
    {
      "name": "contract_buyBurn",
      "type": "Address",
      "units": "",
      "description": "Buy&Burn contract"
    },
    {
      "name": "contract_listMarket",
      "type": "Address",
      "units": "",
      "description": "List Market contract"
    },
    {
      "name": "contract_authorOfTheMonth",
      "type": "Address",
      "units": "",
      "description": "Author of the Month contract"
    },
    {
      "name": "pulsex_factory",
      "type": "Address",
      "units": "",
      "description": "Buy&Burn PulseX factory contract"
    },
    {
      "name": "WPLS",
      "type": "Address",
      "units": "",
      "description": "WETH contract (WPLS)"
    }
  ]