import './style.css'
import ContentImage from '@/features/content-image/ui/content-image'
import ReactionsPreview from '@/features/reaction-preview/ui/reaction-preview'
import { formattedDate, parceEthAmount } from '@/shared/lib'
import { UserName } from '@/shared/ui/user-name'
import { EyeIcon, MessageCircle } from 'lucide-react'
import { Link } from 'react-router-dom'
import SavvaIcon from '@/shared/assets/images/savva.svg?react'
import { getCurrentTitles } from '../model/utils'
import { ContentCategories } from './content-categories'
import { ContentStore } from '../model/content-store'
import { ContentType } from '../model/types'
import { FundRibbon } from './fund-ribbon'
import { NftIcon } from '@/shared/assets'
import Blocked from './blocked'
import { LangStore } from '@/shared/model/store'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import NftTooltip from './nft-tooltip'
import { UserStore } from '@/entities/user'
import { useState } from 'react'

type Props = {
	content: ContentType
}

// TODO ribbon css to tailwind
const FeedBlockLine = ({ content }: Props) => {
	const { setActivePost } = ContentStore()
	const { userData } = UserStore()
	const { lang, langPack } = LangStore()


	const [showContent, setShowContent] = useState<boolean>(!content.nsfw || (content.nsfw && userData?.nsfw === 's'))

	const nsfwhandler = (event) => {
		event.preventDefault()
		event.stopPropagation();
		setShowContent(!showContent)
	}

	const linkToAuthor = content.author.name.length ? `/@${content.author.name}` : `/${content.author.address}`
	return (
		<Link
			className='flex mx-auto mt-6 w-full rounded-xl shadow-md lg:mb-3 relative bg-card over'
			to={`/content/${content.short_cid.length ? content.short_cid : content.savva_cid}`}
			key={content.savva_cid}
			state={content}
			onClick={() => setActivePost(content)}
		>
				<Blocked content={content}/>
				<div 
					className='flex w-full rounded-xl relative bg-transparent overflow-hidden'
				>
				<div className='rcontentative relative justify-between md:flex w-full'>
					<div
						className='w-full px-[20px] py-[8px] flex flex-col justify-between'
					>
						<div>
							<object style={{ display: 'flex' }}>
								<Link to={linkToAuthor}>
									<UserName account={content.author} sliceLen={19} />
								</Link>
							</object>
							<div className='text-500 text-base font-semibold uppercase tracking-wide'>
								{!showContent ? langPack('default.warning') : getCurrentTitles(content.savva_content.locales, 'title')}
							</div>
							<p className='mb-2 mt-1 text-gray-500 postItem leading-5 line-clamp-2'>
								{content.banned && 'BANNED'}
								{!showContent
									? langPack('default.advisory')
									: getCurrentTitles(content.savva_content.locales,'text_preview')}
								{!showContent && 
									<div className='flex w-full flex-row justify-start items-center gap-2 cursor-pointer hover:underline mt-2' onClick={nsfwhandler}>
											<EyeIcon size={18}/>
											{langPack('default.show')}
									</div>
								}
							</p>
						</div>
						
						<div>
							<ContentCategories content={content} className='w-full justify-start' />
							<div className='flex w-full flex-row items-center justify-between pl-1'>
								<div className='flex w-full flex-row items-center'>
									<div className='text-muted-foreground text-sm'>
										{formattedDate(content.timestamp, lang)}
									</div>
									<div className='row mt-[2px] flex items-center gap-1 pl-6 text-[grey]'>
										<MessageCircle color='grey' size={15} />
										<span className='text-[12px]'>{content.total_childs}</span>
									</div>
									<div className='mt-[2px] pl-6'>
										<ReactionsPreview reactions={content.reactions} />
									</div>
								</div>
								{content.fund.total_author_share > 0 && (
									<div className='flex flex-row items-center text-[14px] font-[400]'>
										{parceEthAmount(content.fund.total_author_share, '', 2)}
										<SavvaIcon className='scale-50' />
									</div>
								)}
							</div>
						</div>
					</div>
					<div className='rcontentative overflow-hidden rounded-b-lg md:rounded-b-none lg:rounded-r-lg  md:flex-shrink-0 relative'>
						<ContentImage content={content} show={showContent}/>
					</div>
				</div>
				</div>
				<FundRibbon content={content}/>
				{content.nft.owner?.address && <div className='absolute top-[0px] md:-top-[10px] -right-[10px] md:right-[225px] scale-125 z-10 flex flex-row'>
					<NftIcon className='z-10'/>
					<div className='relative text-[#fff]'>
						{
							content.nft.on_auction && <div className='absolute flex flex-row bg-[#337DB2] pl-6 -left-[10px] z-1 top-[2px] rounded-r-full gap-4'>
								<AmountBlock amount={content.nft.highest_bid > 0 ? content.nft.highest_bid : content.nft.min_bid} iconPosition='right' description={<NftTooltip nft={content.nft}/>}/>
							</div>
						}
						{
							content.nft.on_market && <div className='absolute flex flex-row bg-[#337DB2] pl-6 -left-[10px] z-1 top-[2px] rounded-r-full gap-4'>
								<AmountBlock amount={content.nft.price} iconPosition='right' />
							</div>
						}
					</div>
				</div>}
		</Link>
	)
}

export default FeedBlockLine
