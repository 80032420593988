import { useChainModal } from '@rainbow-me/rainbowkit'
import { useAccount, useSignMessage } from 'wagmi'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { UserStore } from '@/entities/user/model/user-store'
import useRequest from '@/shared/lib/use-request'
import { Button } from '@/shared/ui'

//TODO: Вынести метод из profile getUserFetch

export const Auth = () => {
	const { chain } = useAccount()
	const { openChainModal } = useChainModal()
	const { address } = useAccount()
	const { signatureAddress, setAutData } = UserStore()
	const {domain} = ConfigStore()
	const message = AppInfoStore(state => state.appInfo.auth_text_to_sign)
	const {langPack} = LangStore()
	//FIXME: Придумать как сделать так что бы не пришлось придумывать названия методам или обектам
	const fetchAuth = useRequest('auth')
	const fetchLogout = useRequest('logout')

	//FIXME: Добавить типы 
	const siweMessage = async (signature: string) => {
		if (!address) return

		await fetchAuth.fetch({
			params: {
				user_addr: address,
				signature,
				domain
			},
			onSuccess: async (res) => {
				if(res.result !== 'ok')  return
				setAutData({
					signatureAddress: address,
					isAdmin: res.admin
				})
			}
		})
	}

	const { signMessageAsync } = useSignMessage({
		mutation: {
			onSuccess: async data => {
				await siweMessage(data)
			}
		}
	})

	const siwe = async () => (!chain && openChainModal ? openChainModal() : signMessageAsync({ message }))

	//FIXME: Обработать ошибки
	const logout = async () => {
		await fetchLogout.fetch({
			onSuccess: async () => {
				setAutData({
					signatureAddress: false,
					isAdmin: false
				})
			}
		})
	}

	return (
		<Button variant="secondary" className='w-full' onClick={signatureAddress ? logout : siwe} loading={fetchLogout.isLoading || fetchAuth.isLoading}>
			{signatureAddress ? langPack('action.logout') : langPack('action.login')}
		</Button>
	)
}
