import { RefreshCw } from 'lucide-react'
import { cn } from '../lib'

type Props = {
    className?: string
    size?: number
    text?: string
}

const LoadingIcon = ({ className, size, text }: Props) => (
    <div className='flex flex-col gap-4 items-center justify-center'>
        <RefreshCw className={cn("h-4 w-4 animate-spin", size &&  `w-[${size}px] h-[${size}px]`,className && className)} />
        <span className='animate-pulse text-[grey]'>{text || 'Loading...'}</span>
    </div>
)

export default LoadingIcon