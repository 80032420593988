export const nft_mock = {
    "on_market": false,
    "price": 0,
    "owner": {
        "address": "0xd871456a5d3420E67D72812100D53201E0329722",
        "name": "firstvoice",
        "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
        "staked": 1.7600091509280037e+26,
        "n_following": 6,
        "n_followers": 5,
        "n_sponsoring": 3,
        "n_sponsored": 0,
        "total_sponsoring": 800000000000000000000,
        "total_sponsored": 0,
        "n_nfts": 0,
        "banned": false,
        "ban_comment": "",
        "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
        "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
        "chat_read_price": 0,
        "chat_write_price": 0
    },
    "on_auction": true,
    "min_bid": 10000,
    "highest_bid": 2080880,
    "auction_end_time": 1738198310
}

export const user_mock = {
    "address": "0xd871456a5d3420E67D72812100D53201E0329722",
    "name": "firstvoice",
    "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
    "staked": 1.7600091509280037e+26,
    "n_following": 6,
    "n_followers": 5,
    "n_sponsoring": 3,
    "n_sponsored": 0,
    "total_sponsoring": 800000000000000000000,
    "total_sponsored": 0,
    "n_nfts": 0,
    "banned": false,
    "ban_comment": "",
    "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
    "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
    "chat_read_price": 0,
    "chat_write_price": 0
}

export const null_address: `0x${string}` = '0x0000000000000000000000000000000000000000'

export const bid_event = {
    "type": "nft_auction_bid",
    "domain": "savva.app",
    "error": "",
    "data": {
        "content_id": "0x1c34ea53a30bd6cca73c77440c23ff3fcd2c26017696776cab1c0b6f4d94b6dd",
        "title": {
            "ru": "New post for auct"
        },
        "author": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "owner": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "bidder": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "price": 100000000000000000000,
        "token": "SAVVA"
    }
}

export const nft_auction_created = {
    "type": "nft_auction_created",
    "domain": "savva.app",
    "error": "",
    "data": {
        "content_id": "0x91508940b510fc7bb905786c38c3edd6066fd0fbd0c6f37f76718a7e429dff92",
        "title": {
            "en": "Туцфывлфыждалыв"
        },
        "author": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "QmUNtMhUeX6TzuGn66UqQCi3xiUzmncj6hYLNcGMvFyifb?filename=.png",
            "staked": 1.7600091509280037e+26,
            "n_following": 6,
            "n_followers": 5,
            "n_sponsoring": 3,
            "n_sponsored": 0,
            "total_sponsoring": 800000000000000000000,
            "total_sponsored": 0,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmaC32CWVsSUuU9PmLqXZusEAovjAuPuTQGY8YszgVjLgw",
            "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
            "chat_read_price": 0,
            "chat_write_price": 0
        },
        "min_price": 100000000000000000000,
        "end_time": 1738859999
    }
}

export const fundraiser_campaign_created = {
    "type": "fundraiser_campaign_created",
    "domain": "",
    "error": "",
    "data": {
        "id": 1,
        "title": "I want to raise funds to buy a motorcycle, and I will stick token logos on it and beautifully advertise the platform",
        "target_amount": 4.9e+23,
        "creator": {
            "address": "0xd871456a5d3420E67D72812100D53201E0329722",
            "name": "firstvoice",
            "avatar": "Qmch9SWzHMPaWFdMMYg9DTA7SCdZDdp1GHqGfqMGgMdZt2?filename=.png",
            "staked": 2.1465688096556887e+24,
            "n_following": 2,
            "n_followers": 2,
            "n_sponsoring": 0,
            "n_sponsored": 1,
            "total_sponsoring": 0,
            "total_sponsored": 400000000000000000000,
            "n_nfts": 0,
            "banned": false,
            "ban_comment": "",
            "profile_cid": "QmYxNTo8cxsL64BXaMRkB7PZP4R9wGwLTYQqTrsALCCeou",
            "display_name": "",
            "chat_read_price": 0,
            "chat_write_price": 0
        }
    }
}

export const content_processed_mock = {
    "type": "content_processed",
    "domain": "savva.app",
    "error": "",
    "data": {
        "content": {
            "savva_cid": "0xe0607819e87ce85617b1bc18bb27a0af7cdf868b45e79aaaf4e1528a3bf18009",
            "short_cid": "new_post_3",
            "domain": "savva.app",
            "guid": "495960bb-df2d-49b3-bb0b-da934552f7eb",
            "ipfs": "QmXQHthyp1SwPNfa1FS1TLhqR9Pxfcj6TC65sZyRhm5Jhx",
            "timestamp": "2025-02-22T22:24:09Z",
            "effective_time": "2025-02-22T22:24:09Z",
            "savva_content": {
                "savva_spec_version": "2.0",
                "mime_type": "text/markdown",
                "thumbnail": "",
                "root_savva_cid": "",
                "parent_savva_cid": "",
                "root_post": null,
                "parent_post": null,
                "content_type": "post",
                "nsfw": false,
                "fundraiser": 0,
                "encryption": {
                    "type": "",
                    "key_exchange_alg": "",
                    "key_exchange_pub_key": "",
                    "addresses": null
                },
                "locales": {
                    "en": {
                        "title": "new post ",
                        "text_preview": "asdasfasfsadfsdaf",
                        "categories": [
                            "SAVVA Talk"
                        ],
                        "tags": []
                    }
                }
            },
            "total_childs": 0,
            "fund": {
                "amount": null,
                "total_contributed": null,
                "total_author_share": null,
                "round_time": null
            },
            "nft": {
                "on_market": false,
                "price": null,
                "owner": null,
                "on_auction": false,
                "min_bid": null,
                "highest_bid": null,
                "auction_end_time": null
            },
            "author": {
                "address": "0xd871456a5d3420E67D72812100D53201E0329722",
                "name": "firstvoice",
                "avatar": "QmZkSCa1qgtxBRtBBUZ6Dbia9jrciK51cg93fW1jkBw42H?filename=.png",
                "staked": 1.759925650928004e+26,
                "n_following": 6,
                "n_followers": 5,
                "n_sponsoring": 4,
                "n_sponsored": 0,
                "total_sponsoring": 1.35e+21,
                "total_sponsored": 0,
                "n_nfts": 0,
                "banned": false,
                "ban_comment": "",
                "profile_cid": "QmUNshELMVTWHtdrZLxrtgFfmVTqveemoeDDJHgWSizLeR",
                "display_name": "Голосящий КиВиН с длинным именем, оооооооочень",
                "chat_read_price": 0,
                "chat_write_price": 0
            },
            "reactions": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "my_reaction": -1,
            "banned": false,
            "ban_comment": "",
            "pinned": false,
            "nsfw": false
        },
        "edited": false
    }
}