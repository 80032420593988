import { useEffect, useRef, useState } from 'react'
import { UserCard, UserStore } from '@/entities/user'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import {
	Badge,
	Button,
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Separator
} from '@/shared/ui'
import { ArrowLeft, ArrowRight, Eye, EyeOff, EyeOffIcon, MessageSquarePlus, PencilLine, ShieldX } from 'lucide-react'
import { Link, useParams } from 'react-router-dom'
import ReactTimeAgo from 'react-timeago'
import { ContentLangToggle, getContentLang } from '@/features/content-lang-toggle'
import { cn, toast, useTheme } from '@/shared/lib'
import { useAccount } from 'wagmi'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/shared/ui/collapsible'
import { ButtonLike } from './button-like'
import { ContentStore } from '@/entities/feed-block/model/content-store'
import { Announcements } from '@/features/announcements'
import { Reactions } from '@/features/reactions'
import useRequest from '@/shared/lib/use-request'
import MarkdownPreview from '@uiw/react-markdown-preview'
import { Media } from '@/features/md-editor/ui/media'
import { SimpleComment } from './simple-сomment'
import { getContentFromIpfs } from '../lib/get-content-from-yaml'
import { IContent } from '../model/types'
import { ContentType } from '@/entities/feed-block/model/types'
import { CommentCard } from '@/entities/comments/ui/comment'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { AdminPanel } from './admin-panel'
import { NoContent } from '@/entities/feed-block/ui/no-content'
import remarkBreaks from 'remark-breaks'
import { DeleteConfirmationDialog } from '@/features/remove-content/ui/modal'
import SocialShare from '@/shared/ui/share-block/share-block'

export const Post = () => {
	const { activePost, setActivePost, setCommentList, commentList } = ContentStore()
	const { isAdmin, signatureAddress, userData } = UserStore()
	const { domain, baseUrl } = ConfigStore()
	const { lang, langPack } = LangStore()
	const { appInfo } = AppInfoStore()

	const [content, setConten] = useState<IContent | null>(null)
	const [showContent, setShowContent] = useState<boolean>(true)
	const [currentChapter, setCurrentChapter] = useState(0)
	const [contentLang, setContentLang] = useState('')
	const [isOpen, setIsOpen] = useState(false)

	const { id } = useParams<{ id: string }>()
	const { address } = useAccount()
	const { theme } = useTheme()

	const { message } = useSubscribe('content_processed')
	const { message: reactions } = useSubscribe('react')

	const ref = useRef<HTMLDivElement | null>(null)

	const { fetch: fetchContent, isLoading, result } = useRequest<{ list: ContentType[] }>('getContentList')
	const { fetch: getComments } = useRequest('getContentChildrensList')

	const getPost = async (cid: string) => {
		fetchContent({
			params: {
				domain,
				savva_cid: cid.startsWith('0x') ? cid : '',
				short_cid: cid.startsWith('0x') ? '' : cid,
				show_nsfw: true,
				...(userData?.address && {
					my_addr: userData.address
				})
			},
			onSuccess: async res => {
				if (!res.list.length) {
					toast({ title: 'Content not found or bad spec', variant: 'destructive' })
					return
				}
				const data = res.list[0]
				if (data.nsfw) {
					setShowContent(userData?.nsfw === 's')
				}
				await getContent(data?.ipfs)

				setActivePost({
					...data,
					fund: activePost?.fund ?? data.fund
				})
				setContentLang(getContentLang(data.savva_content.locales, lang))
			},
			onError: () => toast({ title: 'Content not found or bad spec', variant: 'destructive' })
		})
	}

	const getContent = async (ipfs: string) => {
		const url = `${baseUrl}ipfs/${ipfs}`

		const data = await getContentFromIpfs(url)

		Object.values(data).forEach((el: any) => {
			if (el.chapters) {
				el.chapters[0].title = langPack('post.default_chapter_title')
			}
		})

		setConten(data)
	}

	useEffect(() => {
		if (!id) return

		getPost(id)
	}, [id])

	useEffect(() => {
		if (isOpen && ref.current) {
			ref.current.scrollIntoView({ block: 'end' })
		}
	}, [isOpen])

	useEffect(() => {
		if (!activePost?.savva_cid) return
		getComments({
			params: {
				domain: domain,
				savva_cid: activePost.savva_cid,
				max_deep: 100,
				my_addr: address,
				limit: 1000
			},
			onSuccess(res) {
				const filtred = res.list.filter((el: ContentType) => el.savva_cid !== activePost.savva_cid)
				setCommentList(filtred)
			}
		})
	}, [activePost?.savva_cid])

	const rootComments = commentList.filter(
		(el: ContentType) =>
			el.savva_content.root_savva_cid.length > 0 &&
			el.savva_content.parent_savva_cid === el.savva_content.root_savva_cid
	)

	useEffect(() => {
		return () => {
			setCommentList([])
			setActivePost(null)
		}
	}, [])

	useEffect(() => {
		if (!message) return

		const old = [...commentList]
		const index = old.findIndex((el: ContentType) => el.savva_cid === message.content.savva_cid)
		if (index < 0) {
			old.unshift(message.content)
			setCommentList(old)
		} else {
			old[index] = message.content
			setCommentList(old)
		}
	}, [message])

	useEffect(() => {
		if (!reactions) return

		if (reactions.data.savva_cid === activePost?.savva_cid) {
			setActivePost(reactions.data)
		}
	}, [reactions])

	const isBanned = activePost?.banned

	const isAuthorBanned = activePost?.author?.banned

	if (!result?.list.length || isLoading) return <NoContent isLoading={isLoading} />

	if (!activePost?.savva_cid || !content) return <></>

	if (!content[contentLang]) return <NoContent isLoading={false} text='Spec error' />

	return (
		<Collapsible className='w-full rounded-md' open={isOpen} onOpenChange={setIsOpen}>
			{isAuthorBanned && (
				<Card className='mb-3 flex flex-col p-8'>
					<div className='flex flex-row gap-2 font-bold text-[red]'>
						<ShieldX size={22} />
						<div>This author is blocked:</div>
					</div>
					<div>{activePost.author.ban_comment}</div>
				</Card>
			)}
			{isBanned && (
				<Card className='mb-3 flex flex-col p-8'>
					<div className='flex flex-row gap-2 font-bold text-[red]'>
						<ShieldX size={22} />
						<div>This post is blocked:</div>
					</div>
					<div>{activePost.ban_comment}</div>
				</Card>
			)}
			<Card className='mb-4 flex h-full w-full flex-col justify-between'>
				<CardHeader>
					<div className='mb-4 flex justify-between gap-10'>
						<UserCard user={activePost?.author} />

						<div className='flex flex-row items-center gap-3'>
							{activePost.nsfw && userData?.address && userData.nsfw === 'w' && (
								<Button className='gap-4' onClick={() => setShowContent(!showContent)}>
									{showContent ? <EyeOff /> : <Eye />}
									{showContent ? langPack('default.hide') : langPack('default.show')}
								</Button>
							)}
							<ContentLangToggle locales={Object.keys(content)} value={contentLang} setValue={setContentLang} />
							{isAdmin && <AdminPanel domain={domain} activePost={activePost} onAction={() => getPost(id || '')} />}
						</div>
					</div>
					{showContent && (
						<div>
							<div className='flex justify-between'>
								<div>
									<CardTitle>{content[contentLang].title}</CardTitle>
									<CardDescription>
										<ReactTimeAgo className='text-sm text-muted-foreground' date={activePost.timestamp} />
									</CardDescription>
								</div>
							</div>
						</div>
					)}
					{content?.[contentLang]?.chapters.length > 1 && (
						<Select onValueChange={e => setCurrentChapter(Number(e))} value={String(currentChapter)}>
							<SelectTrigger>
								<SelectValue>
									{`${currentChapter !== 0 ? currentChapter + '.' : ''} ${content?.[contentLang]?.chapters[currentChapter].title}`}
								</SelectValue>
							</SelectTrigger>
							<SelectContent>
								{content?.[contentLang]?.chapters.map((chapter, idx) => (
									<SelectItem key={idx} value={idx} textValue={idx}>
										{`${idx !== 0 ? idx + '.' : ''} ${chapter.title}`}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					)}
				</CardHeader>
				<CardContent className='relative flex h-full flex-col pb-2'>
					{showContent ? (
						<>
							{' '}
							<div className='mb-4'>
								<MarkdownPreview
									source={content[contentLang].chapters[currentChapter].data}
									remarkPlugins={[remarkBreaks]}
									style={{ backgroundColor: 'inherit' }}
									urlTransform={url => {
										return url.includes('http') ? url : `${appInfo.ipfs_gateways[0]}${activePost.ipfs}/${url}`
									}}
									wrapperElement={{
										'data-color-mode': theme
									}}
									components={{
										img: Media
									}}
								/>
							</div>
							{content?.[contentLang]?.chapters.length > 1 && (
								<div className='mb-3'>
									<div className='flex'>
										<Button
											className={cn('flex w-full justify-start', currentChapter === 0 && 'hidden')}
											variant={'ghost'}
											onClick={() => setCurrentChapter(state => state - 1)}
										>
											<ArrowLeft />
										</Button>
										<Button
											variant={'ghost'}
											className={cn(
												'flex w-full justify-end ps-8',
												currentChapter === content[contentLang]?.chapters.length - 1 && 'hidden'
											)}
											onClick={() => setCurrentChapter(state => state + 1)}
										>
											<ArrowRight />
										</Button>
									</div>
								</div>
							)}
							<div className='flex justify-between'>
								<div>
									{content[contentLang].categories?.length > 0 && (
										<div className='mb-3 flex flex-wrap gap-1'>
											{content[contentLang].categories.map(el => (
												<Badge variant='secondary' className='rounded-md' key={el}>
													{el}
												</Badge>
											))}
										</div>
									)}

									{content[contentLang].tags?.length > 0 && (
										<div className='mb-3 flex flex-wrap gap-1'>
											{content[contentLang].tags.map(tag => (
												<Badge variant='secondary' className='rounded-md' key={tag}>
													{tag}
												</Badge>
											))}
										</div>
									)}
								</div>
								{content?.[contentLang]?.chapters.length > 1 && (
									<div className='text-sm text-muted-foreground'>
										{currentChapter + 1 + '/' + content?.[contentLang]?.chapters.length}
									</div>
								)}
							</div>
						</>
					) : (
						<Card className='flex w-full flex-col items-center justify-center bg-background py-8 leading-[30px]'>
							<EyeOffIcon size={35} />
							<div className='text-[25px] font-bold'>{langPack('default.warning')}</div>
							<div>{langPack('default.advisory')}</div>
						</Card>
					)}
					<Reactions reactions={activePost.reactions} />
				</CardContent>

				<CardFooter className='flex flex-col p-0'>
					<Separator />
					<div className='flex w-full justify-between'>
						{signatureAddress && <ButtonLike activePost={activePost} />}
						<Separator orientation='vertical' />
						{!activePost.nft.owner && (
							<Link
								to={`/post-edit/${id}`}
								state={activePost}
								className={cn(address !== activePost?.author.address && 'hidden', 'w-full')}
							>
								<Button size={'icon'} className='w-full hover:text-primary' variant='ghost'>
									<PencilLine />
								</Button>
							</Link>
						)}
						{activePost.author.address === address && !activePost.nft.owner && (
							<>
								<DeleteConfirmationDialog guid={activePost.guid} type='post' />
								<Separator orientation='vertical' />
							</>
						)}
						<Separator orientation='vertical' />
						<CollapsibleTrigger asChild>
							<Button size={'icon'} className='w-full hover:text-primary' variant='ghost'>
								<MessageSquarePlus />
							</Button>
						</CollapsibleTrigger>
						<Separator orientation='vertical' />
						<Announcements isOnlyBuy savva_cid={activePost?.savva_cid} />
					</div>
				</CardFooter>
			</Card>
			<CollapsibleContent className='flex flex-col gap-4' ref={ref}>
				<SimpleComment lang={lang} domain={domain} address={address} state={activePost} />
			</CollapsibleContent>
			<div className='flex items-center justify-end gap-2'>
				<span>Share with:</span>
				<SocialShare shareUrl={`${window.location.origin}/content/${activePost.short_cid}`} />
			</div>
			{rootComments.length > 0 ? (
				<div className='mt-5 flex flex-col'>
					<CardTitle className='mb-5 border-b-2 pb-2'>Comments</CardTitle>
					{(rootComments || []).map((el: ContentType) => (
						<CommentCard content={el} key={el.savva_cid} level={0} isPost />
					))}
				</div>
			) : null}
			<div className='h-[70px]' />
		</Collapsible>
	)
}
