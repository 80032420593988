import { LangStore } from '@/shared/model/store'
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
	Button
} from '@/shared/ui'
import { Trash2 } from 'lucide-react'
import useRemoveContent from '../lib/use-remove-content'
import { useNavigate } from 'react-router-dom'

export function DeleteConfirmationDialog({ guid, type }: { guid: string; type: 'comment' | 'post' }) {
	const navigate = useNavigate()
	const { langPack } = LangStore()

	const onSuccess = () => {
		if (window.history.length > 1) {
			navigate(-1) // Переходим на предыдущую страницу, если есть история
		} else {
			navigate('/') // Переходим на главную страницу, если истории нет
		}
	}

	const { removeContent, isLoading } = useRemoveContent(onSuccess)

	const handle = () => {
		removeContent(guid, type)
	}
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>
				<Button size={'icon'} className='w-full hover:text-primary' variant='ghost'>
					<Trash2 className='h-18 w-18' />
				</Button>
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{langPack('remove_content.delete_confirmation_title')}</AlertDialogTitle>
					<AlertDialogDescription>{langPack('remove_content.delete_confirmation_message')}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>{langPack('remove_content.cancel')}</AlertDialogCancel>
					<AlertDialogAction disabled={isLoading} onClick={handle}>
						{langPack('remove_content.confirm')}
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
