import { AppInfoStore } from '@/shared/model/store'
import { ContentType } from '@/entities/feed-block/model/types'
import { getCurrentPostImage } from '@/entities/feed-block/model/utils'
import { cn } from '@/shared/lib'

type Props = {
	content: ContentType
	show: boolean
	className?: string
}

const ContentImage = ({ content, show = true, className }: Props) => {
	const { appInfo } = AppInfoStore()

	const imageSrc = getCurrentPostImage(content, appInfo)

	if (!show)
		return (
			<div className='relative h-[190px] w-full bg-[#fff] object-cover uppercase md:w-60'>
				<div className='flex h-[33%] items-center justify-center bg-[black] font-sans text-[35px] font-bold tracking-[10px] text-[#fff]'>
					parental
				</div>
				<div className='font-roboto flex h-[34%] scale-y-[1.4] items-center justify-center text-[45px] font-bold tracking-[5px] text-[#000]'>
					advisory
				</div>
				<div className='flex h-[33%] w-full items-center justify-center bg-[black] font-sans text-[#fff]'>
					<span className='-ml-5 w-full scale-x-[0.8] scale-y-[1.4] whitespace-nowrap text-[25px] font-bold tracking-[6px]'>
						explicit content
					</span>
				</div>
			</div>
		)

	return (
		<img
			className={cn('h-[190px] w-full object-cover md:w-60', className && className)}
			src={imageSrc}
			alt='Event image'
		/>
	)
}

export default ContentImage
