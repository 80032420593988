import { IUser } from '@/entities/user/model/types'
import { useEffect, useState } from 'react'
import { IPFSProfileType } from '../profile'
import { Button, CardDescription, CardTitle, Popover, PopoverContent, PopoverTrigger, TimeAgo } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { formatEther } from 'viem'
import { cn, contractRead, getContractData } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { Clock, EllipsisVertical } from 'lucide-react'
import StakeModal from './modals/stake-modal'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { UserStore } from '@/entities/user'
import TransferModal from './modals/transfer-modal'
import { LangStore } from '@/shared/model/store'
import { publicClientWeb3 } from '@/app/model'
import { useAccount } from 'wagmi'
import useRequest from '@/shared/lib/use-request'

type Props = {
	profile: IUser & IPFSProfileType
}



const WalletBlock = ({ title, description, amount, menu, disableBorder = false }: any) => {
	return (
		<div className={cn('mb-7 flex w-full flex-row justify-between pb-5', !disableBorder && 'border-b-[1px]')}>
			
			<div className='flex max-w-[70%] flex-col'>
				<CardTitle>{title}</CardTitle>
				<CardDescription className='mt-3'>{description}</CardDescription>
			</div>
			<div>
				<div className='flex flex-row items-center gap-4'>
					{amount}
					{menu ? (
						<Popover modal>
							<PopoverTrigger className='mt-[12px]' asChild>
								<Button size='icon' variant={'secondary'}>
									<EllipsisVertical size={16} />
								</Button>
							</PopoverTrigger>
							<PopoverContent className='mr-[250px]'>
								{menu.map((el, index) => (
									<div className='mb-3' key={index}>{el}</div>
								))}
							</PopoverContent>
						</Popover>
					) : (
						<div className='w-[42px]' />
					)}
				</div>
			</div>
		</div>
	)
}

const Wallet = ({ profile }: Props) => {
	const { contracts } = ContractsStore()
	const { langPack } = LangStore()
	const { userData } = UserStore()
	const [balances, setBalances] = useState({
		savva: 0n,
		pls: 0n,
		nftGain: 0n,
		staking: {
			staked: 0n,
			reward: 0n,
			available: 0n,
			unstake_list: []
		}
	})
	const { fetch } = useRequest('fix')


	const account = useAccount()

	const address = profile.address as `0x${string}`

	const isMy = userData?.address === profile.address

	const updateBalance = async () => {
		const tokensAmount: bigint = await contractRead('SavvaToken', 'balanceOf', [address])
		setBalances(prev => ({ ...prev, savva: tokensAmount }))
	}

	const getPls = async () => {
		const client = publicClientWeb3()
		const balance: bigint = await client.getBalance({
			address
		})
		setBalances(prev => ({ ...prev, pls: balance }))
	}

	const getNftBlance = async () => {
		const balance: bigint = await contractRead('ContentFund', 'claimableNftGain', [address || ''])
		setBalances(prev => ({ ...prev, nftGain: balance }))
	}

	const getPendingGain = async () => {
		const rewardsAmount: bigint = await contractRead('Staking', 'claimable', [address])
		setBalances(prev => ({ ...prev, staking: { ...prev.staking, reward: rewardsAmount } }))
	}

	const getStakingBalance = async () => {
		const res: bigint = await contractRead('Staking', 'balanceOf', [address])
		if(Number(res) != Number(profile.staked)){
			fetch({
				params: {
					user: profile.address
				}
			})
		}

		setBalances(prev => ({ ...prev, staking: { ...prev.staking, staked: res } }))
	}

	const getUnstakeRequests = async () => {
		const res: bigint = await contractRead('Staking', 'getUnstakeRequests', [address])
		setBalances(prev => ({ ...prev, staking: { ...prev.staking, unstake_list: Array.isArray(res) ? res : [] } }))
	}

	const getData = async () => {
		await getStakingBalance()
		await updateBalance()
		await getPls()
		await getNftBlance()
		await getUnstakeRequests()
		await getPendingGain()
	}

	useEffect(() => {
		if (!profile) return
		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile])

	const { writeContract } = useNotifier({
		onSuccess() {
			const getData = async () => {
				await getStakingBalance()
				await updateBalance()
				await getPls()
				await getNftBlance()
				await getUnstakeRequests()
				await getPendingGain()
			}
			getData()
		}
	})

	const withdrawAllMyNFTCuts = async () => {
		writeContract({
			address: contracts.ContentFund.address,
			abi: contracts.ContentFund.abi,
			functionName: 'claimNFTGain',
			args: []
		})
	}

	const compoundRewards = () => {
		if (!balances.staking.reward) return
		writeContract({
			address: contracts.Staking.address,
			abi: contracts.Staking.abi,
			functionName: 'compoundGain',
			args: []
		})
	}

	const withdrawRewards = () => {
		if (!balances.staking.reward) return
		writeContract({
			address: contracts.Staking.address,
			abi: contracts.Staking.abi,
			functionName: 'claimGain',
			args: []
		})
	}

	const withdrwawRequestSumAvailable = balances.staking.unstake_list.reduce(
		(accumulator, el) =>
			accumulator +
			(Math.round(new Date().getTime() / 1000) > Number(el.timestamp) ? Number(formatEther(el.amount)) : 0),
		0
	)

	const claimHandler = async () => {
		if (!address) return

		writeContract({
			...getContractData('Staking', 'claimUnstaked'),
			account: address,
			args: []
		})
	}

	return (
		<div className='flex flex-col'>
			<WalletBlock
				title='SAVVA'
				description={langPack('profile.wallet_savva_detail')}
				amount={
					<AmountBlock
						size='md'
						amount={balances.savva}
						perUsd
						usdClassName='absolute -bottom-[10px] text-[grey] text-[13px]'
					/>
				}
				menu={isMy ? [<TransferModal getData={getData} />, <StakeModal getData={getData} />] : null}
			/>
			<WalletBlock
				title={account?.chain?.nativeCurrency.symbol || 'PLS'}
				description={langPack('profile.wallet_pls_detail')}
				amount={
					<AmountBlock
						size='md'
						amount={balances.pls}
						token='PLS'
						perUsd
						usdClassName='absolute -bottom-[10px] text-[grey] text-[13px]'
					/>
				}
				menu={isMy ? [<TransferModal getData={getData} isPls />] : null}
			/>
			<WalletBlock
				title={langPack('profile.wallet_reward_nft')}
				description={langPack('profile.wallet_reward_nft_detail')}
				amount={
					<AmountBlock
						size='md'
						amount={balances.nftGain}
						perUsd
						usdClassName='absolute -bottom-[10px] text-[grey] text-[13px]'
					/>
				}
				menu={
					balances.nftGain > 0 && isMy
						? [
								<Button className='w-full' onClick={withdrawAllMyNFTCuts}>
									{langPack('profile.wallet_withdraw')}
								</Button>
							]
						: null
				}
			/>
			<div className='flex w-full flex-col justify-between pb-5'>
				<div className='flex max-w-[70%] flex-col'>
					<CardTitle>{langPack('profile.wallet_staking')}</CardTitle>
				</div>
				<div className='ml-[50px] mt-8'>
					<WalletBlock
						title={langPack('profile.wallet_staked')}
						disableBorder
						description={langPack('profile.wallet_staked_details')}
						amount={
							<AmountBlock
								size='md'
								amount={balances.staking.staked}
								perUsd
								usdClassName='absolute -bottom-[10px] text-[grey] text-[13px]'
							/>
						}
						menu={
							isMy
								? [
										<StakeModal getData={getData} />,
										<StakeModal getData={getData} unStake />,
										<TransferModal getData={getData} isStake />
									]
								: null
						}
					/>
					<WalletBlock
						title={langPack('profile.wallet_staking_rewards')}
						disableBorder
						description={langPack('profile.wallet_staking_rewards_details')}
						amount={
							<AmountBlock
								size='md'
								amount={balances.staking.reward}
								perUsd
								usdClassName='absolute -bottom-[10px] text-[grey] text-[13px]'
							/>
						}
						menu={
							balances.staking.reward > 0 && isMy
								? [
										<Button className='w-full' onClick={compoundRewards}>
											{langPack('profile.wallet_compound')}
										</Button>,
										<Button className='w-full' onClick={withdrawRewards}>
											{langPack('profile.wallet_unstake')}
										</Button>
									]
								: null
						}
					/>
				</div>
				{balances.staking.unstake_list.length > 0 && profile.address === userData?.address && (
					<div>
						<div className='mb-4 flex flex-row items-center justify-between'>
							<CardTitle>{langPack('profile.wallet_unstake_request')}</CardTitle>
							<Button variant={'orange'} disabled={!(withdrwawRequestSumAvailable > 0)} onClick={claimHandler}>
								{langPack('profile.wallet_unstake')}
							</Button>
						</div>
						<div>
							{balances.staking.unstake_list.map((el: any) => (
								<div className='flex flex-row justify-between'>
									<div>
										<AmountBlock size='sm' amount={el.amount} />
									</div>
									<div>
										{Math.round(new Date().getTime() / 1000) > Number(el.timestamp) ? (
											<div className='flex flex-row gap-4'>
												<Clock style={{ color: 'green' }} />
												<div>AVAILABLE</div>
											</div>
										) : (
											<div className='flex flex-row items-center gap-4'>
												<Clock style={{ color: 'orange' }} />
												<div className='text-[12px]'>will be available at</div>
												<TimeAgo date={Number(el.timestamp) * 1000} future />
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Wallet
