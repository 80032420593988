import { UserCard, UserStore } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'
import { GuardButton } from '@/features/guard-button'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button, Dialog, DialogContent, DialogTitle, DialogTrigger, Input, Label, Switch } from '@/shared/ui'
import FindUser from '@/shared/ui/user-find/find-user'
import { useState } from 'react'

type Props = {
    onDelegate: () => void
    delegatedUser?: IUser | null | undefined
}

const DelegateModal = ({ onDelegate, delegatedUser }: Props) => {
    const { contracts } = ContractsStore()
    const { userData } = UserStore()
    const [isSelf, setIsSelf] = useState<boolean>(true)
    const { langPack } = LangStore()
    
    const [open, setOpen] = useState(false)

    const { writeContract, isLoading } = useNotifier({
            onSuccess(){
                onDelegate()
                setOpen(false)
            }
        })
    

    const delegate = (address: `0x${string}`) => {
            writeContract({
                address: contracts.Staking.address,
                abi: contracts.Staking.abi,
                functionName: 'delegate',
                args: [address],
            })
    }


    const selectUser = (el: IUser) => {
        delegate(el.address)
    }

    const whoHandler = (state: boolean) => {
        setIsSelf(state)
    }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
    <DialogTrigger asChild>
        <GuardButton title={langPack('governance.delegate_button')} />
    </DialogTrigger>
    <DialogContent>
        <DialogTitle>{langPack('governance.delegation_title')}</DialogTitle>
        <div className='flex flex-col justify-center w-full'>
            {
                !delegatedUser?.address
                ? <div className='text-[grey] text-[14px] text-center'>
                      {langPack('governance.no_delegation_message')}
                  </div>
                : <div className='flex flex-col justify-center w-full items-center'>
                      <div className='text-[grey] text-[14px] mb-2'>
                          {langPack('governance.delegated_message')}
                      </div>
                      <UserCard user={delegatedUser} />
                  </div>
            }
        </div>
        <div className='flex flex-row items-center gap-4 justify-center w-full mt-5'>
            <Label htmlFor='test-mode flex flex-row gap-4'>
                {langPack('governance.delegate_to_other')}
            </Label>
            <Switch id='test-mode' onCheckedChange={whoHandler} checked={isSelf} className="data-[state=unchecked]:bg-primary"/>
            <Label htmlFor='test-mode flex flex-row gap-4'>
                {langPack('governance.delegate_to_self')}
            </Label>
        </div>
        {isSelf ?
            <div className="flex w-full gap-2 flex-row mt-5 pb-[60px]">
                <Input
                    value={userData?.address}
                    disabled
                    placeholder={langPack('default.specify_user')}
                />
                <Button onClick={() => delegate(userData?.address as `0x${string}`)} loading={isLoading}>
                    {langPack('governance.delegate_button')}
                </Button>
            </div> 
            : <div className="flex w-full mt-5">
                <FindUser onSelect={selectUser} title="Delegate" disableLabel/>
              </div>
        }
    </DialogContent>
</Dialog>
  )
}

export default DelegateModal