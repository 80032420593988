import React, { ChangeEvent } from 'react';
import { Input } from './input';

interface NumericInputProps {
    value?: number | string;
    min?: number;
    max?: number;
    step?: number;
    placeholder?: string;
    label?: string;
    onChange?: (value: number | string) => void;
    inputMode?: 'numeric' | 'decimal';
    error?: string;
    className?: string;
    disabled?: boolean
}

const NumericInput: React.FC<NumericInputProps> = ({
    value = '',
    min,
    max,
    step = 1,
    placeholder = 'Enter a number',
    onChange,
    inputMode = 'numeric',
    disabled,
    className,
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue === '' || !isNaN(Number(newValue))) {
            if (onChange) {
                onChange(newValue === '' ? '' : Number(newValue));
            }
        }
    };

    return (
            <Input
                type="number"
                className={className}
                inputMode={inputMode}
                value={value}
                onChange={handleChange}
                min={min}
                max={max}
                step={step}
                placeholder={placeholder}
                disabled={disabled}
            />
    );
};

export default NumericInput;