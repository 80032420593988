import { useEffect, useState } from 'react'
import NftBox from '../nft-box'
import {  LangStore } from '@/shared/model/store'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { UserCard } from '@/entities/user'
import { CountdownTimer } from '@/shared/ui/countdown-timer'
import { Button } from '@/shared/ui'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { ContentStore } from '@/entities/feed-block/model/content-store'
import useNotifier from '@/shared/lib/use-notifier'
import BidModal from './bid-modal'
import { IUser } from '@/entities/user/model/types'
import { useAuctionConfig } from './use-auction'
import useRequest from '@/shared/lib/use-request'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { GuardButton } from '@/features/guard-button'

export const NFTAuctionBlock = () => {
    const { langPack } = LangStore()
    const { contracts } = ContractsStore()
    const { activePost, setActivePost } = ContentStore()
    const { getAuctionData } = useAuctionConfig()
    const { fetch } = useRequest('fix')
    const { message } = useSubscribe('nft_auction_bid')
    const { message: auction_created } = useSubscribe('nft_auction_created')
	const { message: auction_canceled } = useSubscribe('nft_auction_canceled')
    const { message: auction_ended } = useSubscribe('nft_auction_ended')

  
	const [finish, setFinish] = useState<boolean>(false)

    const getBchAuc = async (disableFix: boolean = false) => {
        if(!activePost?.savva_cid || !activePost.nft.owner) return
        
        const auctionData = await getAuctionData(activePost?.savva_cid)
        
        if(
            (activePost.nft.on_auction !== auctionData?.on_auction
            || !!activePost.nft.highest_bid !== !!auctionData.highest_bid)
            && !disableFix
        ) {
            fetch({
                params: {
                    nft: activePost?.savva_cid
                }
            })
        }

        setActivePost({
            ...activePost,
            nft: {
                ...activePost.nft,
                ...auctionData
            }
        })

        setFinish(Number(auctionData?.auction_end_time) * 1000 < Date.now())

    }


    const { writeContract, isLoading: isLoadingContract } = useNotifier({
		onSuccess() {
			getBchAuc()
		}
	})

	const finishAuction = () => {
		writeContract({
			address: contracts.NFTAuction.address,
			abi: contracts.NFTAuction.abi,
			functionName: 'finalizeAuction',
			args: [activePost?.savva_cid,]
		})
	}


    useEffect(() => {
        if(!activePost?.savva_cid) return
            getBchAuc() 
    }, [activePost?.savva_cid])

    useEffect(() => {
        if(!message || message.content_id !== activePost?.savva_cid || !activePost) return
        setActivePost({
            ...activePost,
            nft: {
                ...activePost.nft,
                bidder: message.bidder,
                highest_bid: message.price,
                last_bid_contract: message.token === 'SAVVA' ? '' : 'STAKED TOKENS'
            }
        })
    }, [message])

    useEffect(() => {
        if(!auction_created || auction_created.content_id !== activePost?.savva_cid || !activePost) return
        getBchAuc(true)
    }, [auction_created])
    
    useEffect(() => {
        if(!auction_canceled || auction_canceled.content_id !== activePost?.savva_cid || !activePost) return
        getBchAuc(true)
    }, [auction_canceled])
    
    useEffect(() => {
        if(!auction_ended || auction_ended.content_id !== activePost?.savva_cid || !activePost) return
        getBchAuc(true)
    }, [auction_ended])

    const nft = activePost?.nft
  
    if(!nft || !nft.on_auction) return

  return (
    <NftBox className='mb-6'>
        <div className='flex flex-row w-full items-center justify-center font-semi text-[18px] mt-2'>
            {langPack('nft.auction')}
        </div>
        <div className='relative mt-3 flex flex-row items-center'>
				<UserCard user={activePost?.nft?.owner || ({} as IUser)} length={25} descriprion={langPack('nft.owner')} />
		</div>
        {activePost.nft?.highest_bid > 0 ? <div className='flex flex-col items-center justify-center border-2 bg-background rounded-lg p-2 pb-4 mt-4'>
            <div>{langPack('nft.last_bid')}</div>
            <UserCard user={nft.bidder} className='mt-2'/>
            <AmountBlock amount={nft?.highest_bid} perUsd size='lg' short />
            <div className='text-[grey] text-[12px] mt-2'>{nft.last_bid_contract}</div>
        </div>
        :
            <div className='flex flex-col items-center justify-center border-2 bg-background rounded-lg p-2 pb-4 mt-4'>
                <div>{langPack('nft.be_first')}</div>
                <AmountBlock amount={nft?.min_bid} perUsd size='lg' short />
            </div>    
        }
        {finish
				? 	<div className='flex flex-col justify-centter items-center mt-6 gap-4'>
                        <GuardButton title={langPack('nft.finish_auction')} onClick={finishAuction} isLoading={isLoadingContract} variant={'orange'} />
					</div>
				: <div className='flex flex-col items-center justify-center mt-4'>
					<div >{langPack('nft.auction_end_time')}</div>
					<div className='mt-2 w-full border-2 rounded-lg'>
							<CountdownTimer
								targetDate={Number(nft.auction_end_time) * 1000}
								onFinish={state => setFinish(state)}
							/>
					</div>
                    <BidModal />
				</div>
			}
    </NftBox>
  )
}

