import { UserStore } from '@/entities/user';
import { useEffect } from 'react'


const useLocalStorage = () => {
  function updateCartStore(e: StorageEvent) {
    if (e.key === 'user') {
      UserStore.persist.rehydrate();
    }
  }
  
  
  useEffect(() => {
      window.addEventListener('storage', updateCartStore);
      return () => {
        window.removeEventListener('storage', updateCartStore);
      };
  }, []);
  
  return <></>
}

export default useLocalStorage