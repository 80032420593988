import { FC, useState } from 'react'
import { useAccount, useBalance } from 'wagmi'
import { parseEther } from 'viem'
import { PlsIcon } from '@/shared/assets/index'
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Input,
	Label,
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '@/shared/ui'
import { AppDetailStore, ConfigStore, LangStore } from '@/shared/model/store'
import useNotifier from '@/shared/lib/use-notifier'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { transform } from '../lib/utils'
import { IPriceList } from '../model/types'
import { usePrice } from '../lib/hook/use-price'

type Props = {
	isOnlyBuy?: boolean
	savva_cid: string | undefined
}

export const Announcements: FC<Props> = ({ isOnlyBuy, savva_cid }) => {
	const [position, setPosition] = useState('')
	const { address } = useAccount()
	const { data: plsBalance } = useBalance({ address })
	const { domain } = ConfigStore()
	const { langPack, lang } = LangStore()
	const [secure, setSecure] = useState(false)
	const [open, setOpen] = useState(false)
	const [selectAnnouncement, setSlelectAnnouncment] = useState<IPriceList | null>(null)

	//FIXME: Возможно стоит сделать это в сторе?
	const contetnsLists = AppDetailStore(state => transform(state.appDetails.modules.content_lists.list, lang))

	const { contracts, tokenPrice } = ContractsStore()
	const { writeContract, isLoading } = useNotifier({
		onSuccess: () => setOpen(false)
	})
	const { price } = usePrice({ contetnsLists, contracts, isOnlyBuy })
	const buyAnnouncement = () => {
		if (!selectAnnouncement) return

		writeContract({
			abi: contracts.ListMarket.abi,
			address: contracts.ListMarket.address,
			functionName: 'buy',
			args: [domain, selectAnnouncement.type, savva_cid],
			value: parseEther(selectAnnouncement.price)
		})
	}

	const setAnnouncement = () => {
		if (!selectAnnouncement) return

		writeContract({
			abi: contracts.ContentRegistry.abi,
			address: contracts.ContentRegistry.address,
			functionName: 'command',
			args: [domain, 'list_add_post', selectAnnouncement.type, savva_cid, position, secure]
		})
	}

	const onSetSecure = (value: boolean) => {
		setSecure(value)
	}

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant='ghost' size='icon' className='w-full hover:text-primary'>
					{langPack('action.announce')}
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{langPack('announcements.title')}</DialogTitle>
					<DialogDescription>{langPack('announcements.description')}</DialogDescription>
				</DialogHeader>

				{isOnlyBuy && (
					<div className='mb-2 flex items-center justify-between'>
						<div>{langPack('default.balance')}</div>
						<div className='flex items-center gap-1'>
							<PlsIcon />
							<div>{plsBalance ? Number(plsBalance.formatted).toFixed(2) : ''}</div>
						</div>
					</div>
				)}

				<Select
					onValueChange={e => setSlelectAnnouncment(JSON.parse(e))}
					value={selectAnnouncement ? JSON.stringify(selectAnnouncement) : ''}
				>
					<SelectTrigger>
						<SelectValue placeholder={langPack('announcements.modal_placeholder')} />
					</SelectTrigger>
					<SelectContent>
						{price.map(el => (
							<SelectItem key={el.name} value={JSON.stringify(el)} textValue={el.name}>
								<div className='flex items-center justify-between gap-4'>
									<div>{el.name}</div>
									{isOnlyBuy && (
										<div className='flex items-center gap-1'>
											<PlsIcon className='size-4' />
											<div>{`${el.price} (${(tokenPrice.base_token_price * Number(el.price)).toFixed(2)}$)`}</div>
										</div>
									)}
								</div>
							</SelectItem>
						))}
					</SelectContent>
				</Select>

				<div>
					<p className='mb-1 text-sm text-muted-foreground'>{langPack('announcements.note1')}</p>
					<p className='text-sm text-muted-foreground'>{langPack('announcements.note2')}</p>
				</div>

				{!isOnlyBuy && (
					<>
						<Input
							placeholder={langPack('announcements.placeholder')}
							value={position}
							onChange={e => setPosition(e.target.value)}
							type='number'
						/>

						<div className='flex items-center space-x-2'>
							<Checkbox checked={secure} onCheckedChange={onSetSecure} id='secure' />
							<Label
								htmlFor='secure'
								className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
							>
								{langPack('announcements.pin')}
							</Label>
						</div>
					</>
				)}

				<DialogFooter className='sm:justify-start'>
					<Button
						loading={isLoading}
						type='button'
						disabled={!selectAnnouncement || !address || isLoading}
						onClick={isOnlyBuy ? buyAnnouncement : setAnnouncement}
					>
						{langPack('action.announce')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
