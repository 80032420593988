import { CommentCard } from '@/entities/comments/ui/comment'
import { ContentType } from '@/entities/feed-block/model/types'
import FeedBlockLine from '@/entities/feed-block/ui/feed-block-line'
import { UserCard } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Button, Input, Label, useDebounce } from '@/shared/ui'
import { RefreshCw, X } from 'lucide-react'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const SearchPage = () => {
	const { fetch: fetchSearchUser, result, isLoading, dropState } = useRequest<IUser[]>('searchUser')
	const {
		fetch: fetchFts,
		result: resultFts,
		isLoading: isLoadingFts,
		dropState: dropStateFts
	} = useRequest<{ list: ContentType[]; total: number }>('fts')
	const { langPack } = LangStore()

	const { domain } = ConfigStore()

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)

	// Получить конкретный параметр
	const query = searchParams.get('q')

	const [searchStr, setSearchStr] = React.useState<string>()
	const debouncedValue = useDebounce(searchStr, 1000)

	const dropResults = () => {
		setSearchStr('')
		dropState()
		dropStateFts()
	}

	useEffect(() => {
		if (!debouncedValue || debouncedValue.length < 3) return
		fetchSearchUser({
			params: {
				domain,
				query: debouncedValue
			}
		})
		fetchFts({
			params: {
				domain,
				query: debouncedValue,
				limit: 100,
				offset: 0
			}
		})
	}, [debouncedValue])

	useEffect(() => {
		if (!query) return
		setSearchStr(query)
	}, [query])

	const hasResults = (result && result.length) || (resultFts && resultFts?.list?.length)

	const hasLoading = isLoadingFts || isLoading

	return (
		<div>
			<div className='relative flex gap-2'>
				{hasLoading && <RefreshCw className='absolute right-36 top-3 z-[999] h-4 w-4 animate-spin' />}
				<Input
					placeholder={langPack('search.placeholder')}
					value={searchStr}
					onChange={e => setSearchStr(e.target.value)}
					className='h-10 w-full' // Ширина и высота input
				/>
				<Button onClick={dropResults} disabled={!hasResults}>
					<X className='mr-2 h-4 w-4' />
					{langPack('search.clear_results')}
				</Button>
			</div>
			{/* <Tabs>
				<TabsList className='grid w-full grid-cols-4'>
					<TabsTrigger value='all'>Все результаты</TabsTrigger>
					<TabsTrigger value='users'>Пользователи</TabsTrigger>
					<TabsTrigger value='posts'>Посты</TabsTrigger>
					<TabsTrigger value='comments'>Комментарии</TabsTrigger>
				</TabsList>
			</Tabs> */}
			{hasResults ? (
				<div className='mt-2'>
					{result && result.length && (
						<div className='mb-2 flex flex-col gap-2 p-5 shadow-md'>
							<Label>{langPack('search.users')}</Label>
							{result?.map((user: IUser) => (
								<UserCard user={user} key={user.address} className='h-6 w-6' length={55} />
							))}
						</div>
					)}
					{resultFts && resultFts?.list?.length ? (
						<div className='flex flex-col p-5 shadow-md'>
							<Label>{langPack('search.content')}</Label>
							{resultFts?.list.map((content: ContentType) =>
								content.savva_content.content_type === 'post' ? (
									<FeedBlockLine content={content} key={content.savva_cid} />
								) : (
									<CommentCard content={content} key={content.savva_cid} level={0} disableButtons />
								)
							)}
						</div>
					) : null}
				</div>
			) : (
				<div className='mt-6 flex h-9 w-full items-center justify-center text-[16px] italic text-[grey]'>
					{searchStr?.length ? langPack('search.not_found') : langPack('search.placeholder')}
				</div>
			)}
		</div>
	)
}

export default SearchPage
