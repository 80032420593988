import { UserCard, UserStore } from '@/entities/user'
import { contractRead, getEllipsisTxt } from '@/shared/lib'
import useCopyClipboard from '@/shared/lib/useCopyClipboard'
import { LangStore } from '@/shared/model/store'
import { Button, Card, Label, Switch } from '@/shared/ui'
import { StatusBadge } from '@/shared/ui/status-badge'
import dayjs from 'dayjs'
import { Link, ListChecks } from 'lucide-react'
import { useEffect, useState } from 'react'
import VotingProgressBar from './voiting'
import { IUser } from '@/entities/user/model/types'
import useRequest from '@/shared/lib/use-request'
import CreateProposal from './create-proposal'
import useGovernance from '../lib/hooks/use-governance'
import { GovStatuses, GovStatusesMap, IGovCall, IGovUnit } from '../models/governance-store'
import useBlocknumber from '@/shared/lib/use-blocknumber'
import VoteBlock from './vote-block'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { formatTimeRemaining } from '@/shared/lib/utils/formattedDate'

const GovernanceUnit = ({ data }: { data: IGovUnit }) => {
	const { blockNumber } = useBlocknumber()
	const { copy } = useCopyClipboard()
	const { userData } = UserStore()
	const [fullData, setFullData] = useState<IGovUnit>({} as IGovUnit)
	const [user, setUser] = useState<IUser | null>(null)
	const { fetch } = useRequest('getUser')
	const { langPack, lang } = LangStore()
	const { message } = useSubscribe('vote_cast')

	const getState = async () => {
		const state: number = await contractRead('Governance', 'state', [data.proposal_id])
		const currentState: GovStatuses = GovStatusesMap[state]
		setFullData(prev => ({ ...prev, state: currentState }))
	}

	const getProposalVotes = async () => {
		const vts: number[] = await contractRead('Governance', 'proposalVotes', [data.proposal_id])
		const currentVotes = vts.map(el => Number(el))
		const isUserVoited: boolean = await contractRead('Governance', 'hasVoted', [data.proposal_id, userData?.address])
		const currentVoteWeight = await contractRead('Governance', 'getVotes', [userData?.address, data.block_number])
		const quorum = await contractRead('Governance', 'quorum', [data.block_number])

		const vdata = {
			votes: currentVotes,
			voted: isUserVoited,
			currentVoteWeight: Number(currentVoteWeight),
			quorum: Number(quorum)
		}

		setFullData((prev: IGovUnit) => ({
			...prev,
			...vdata
		}))
	}

	useEffect(() => {
		if (!data.proposer || !userData?.address) return
		setFullData(data)
		getState()
		getProposalVotes()
		fetch({
			params: {
				user_addr: data.proposer
			},
			onSuccess(res) {
				setUser(res)
			}
		})
	}, [data, userData])

	useEffect(() => {
		if (!message || message.proposal_id !== data.proposal_id) return
		getProposalVotes()
	}, [message])

	return (
		<Card className='bg-background p-2'>
			<div className='grid grid-cols-5 grid-rows-1 gap-4 border-b-2 p-2'>
				<div className='col-span-1 flex flex-row items-center gap-2 text-[12px] font-semibold text-[grey]'>
					ID: {getEllipsisTxt(fullData.proposal_id)}
					<Button
						variant='outline'
						size='icon'
						className='ml-2 h-8 w-8 rounded-full'
						onClick={() => copy(fullData.proposal_id)}
					>
						<Link size={14} />
					</Button>
				</div>
				<div className='col-start-3 flex flex-row items-center text-[12px] font-semibold text-[grey]'>
					{langPack('governance.vote_start')}: #{fullData.vote_start}
				</div>
				{fullData.vote_end > blockNumber && (
					<div className='col-start-4 flex flex-row items-center text-[12px] font-semibold text-[grey]'>
						{langPack('governance.vote_end')} ~ {formatTimeRemaining((fullData.vote_end - blockNumber) * 10, lang)}
					</div>
				)}
				<div className='col-start-5 flex flex-row items-center justify-end text-[12px] font-semibold text-[grey]'>
					<StatusBadge status={fullData.state} />
				</div>
			</div>
			<div className='mt-2 flex flex-row justify-between'>
				<div className='flex w-[80%] flex-col gap-4 p-2'>
					<div className='flex flex-row'>
						<div className='flex w-[200px] text-[14px] font-semibold text-[grey]'>
							{langPack('governance.description')}
						</div>
						<div className='flex text-[12px] text-[grey]'>{fullData.description}</div>
					</div>
					<div className='flex flex-row'>
						<div className='flex w-[200px] text-[14px] font-semibold text-[grey]'>{langPack('governance.creator')}</div>
						<div className='flex text-[12px] text-[grey]'>
							<UserCard user={user} className='h-5 w-5' />
						</div>
					</div>
					<div className='flex flex-row'>
						<div className='flex w-[200px] text-[14px] font-semibold text-[grey]'>{langPack('governance.actions')}</div>
						<div className='flex flex-col text-[12px] text-[grey]'>
							{fullData?.calls?.map((el: IGovCall, index: number) => (
								<div className='flex flex-col pb-2 text-[12px] text-[grey]' key={index}>
									<div>{el.target}</div>
									<div>{el.data}</div>
									{el.value ? (
										<div>
											{langPack('governance.value')}:{el.value}
										</div>
									) : null}
								</div>
							))}
						</div>
					</div>
					{fullData.votes && dayjs(fullData.vote_start) < dayjs() && (
						<div className='flex flex-row'>
							<div className='flex w-[200px] text-[14px] font-semibold text-[grey]'>
								{langPack('governance.voting')}
							</div>
							<div className='flex w-[80%] flex-col text-[12px] text-[grey]'>
								<VotingProgressBar
									votes={fullData.votes || []}
									quorum={fullData.quorum}
									proposal_id={fullData.proposal_id}
								/>
							</div>
						</div>
					)}
				</div>
				<div className='flex w-[20%] flex-col justify-center'>
					<VoteBlock proposal={fullData} />
				</div>
			</div>
		</Card>
	)
}

const GovernanceList = () => {
	const { govList, getGoveranceList } = useGovernance()
	const { langPack } = LangStore()

	useEffect(() => {
		getGoveranceList()
	}, [])

	return (
		<div className='mt-5 flex flex-col gap-4'>
			<div className='flex flex-row items-center justify-between pl-2 font-semibold'>
				<span>{langPack('governance.active_proposals')}</span>
				<div className='flex flex-row items-center gap-4'>
					<Switch id='r1' onCheckedChange={getGoveranceList} defaultChecked={true} />
					<Label htmlFor='r1'>{langPack('governance.only_active')}</Label>
				</div>
				<CreateProposal />
			</div>
			{govList.length ? (
				govList.map((el: IGovUnit) => <GovernanceUnit data={el} key={el.proposal_id} />)
			) : (
				<div className='flex w-full flex-col items-center'>
					<ListChecks size={32} />
					<span className='text-[14px] text-[grey]'>{langPack('governance.no_proposals_yet')}</span>
				</div>
			)}
		</div>
	)
}

export default GovernanceList
