import { LangStore } from '@/shared/model/store'
import { Badge } from '@/shared/ui'

type Props = {
    vote: number
}

const Votebadge = ({vote}: Props) => {
    const { langPack } = LangStore()

    const v = {
        0: {
            title: langPack('governance.vote_against'),
            variant: 'destructive'
        },
        1: {
            title: langPack('governance.vote_for'),
            variant: 'success'
        },
        2: {
            title: langPack('governance.abstain'),
            variant: 'secondary'
        }
    }

  return (
    <Badge
        variant={v[vote].variant}
        className='w-[100px] flex-row justify-center flex rounded-sm'
    >
        {v[vote].title}
    </Badge>
  )
}

export default Votebadge