import { NoContent } from '@/entities/feed-block/ui/no-content'
import { UserStore } from '@/entities/user'
import CreateFundrise from '@/features/fundraise-block/ui/create-fundraise'
import useFundraise from '@/features/fundraise-block/ui/use-fundraise'
import { FundraiseCard, TFundraise } from '@/pages/profile/ui/wallet/fundraise'
import useRequest from '@/shared/lib/use-request'
import { LangStore } from '@/shared/model/store'
import { null_address } from '@/shared/model/types'
import { Button, Card, Checkbox, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, FormLabel, Input, Label, ScrollArea, useDebounce } from '@/shared/ui'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import LoadingIcon from '@/shared/ui/loading-icon'
import { RadioGroup, RadioGroupItem } from '@/shared/ui/radio-group'
import { TicketX } from 'lucide-react'
import { useEffect, useState } from 'react'

type Props = {
    selected: TFundraise
    onSelect: (data: TFundraise) => void
}

const FundraiseModal = ({selected, onSelect} : Props) => {
    const { userData } = UserStore()
    const { fetch, result, isLoading: isLoadingFundriseList} = useRequest<TFundraise[]>('listFundraisers')
    const { getFundRiseData, fundraise, isLoading, drop } = useFundraise()
    const [selectedFundrise, setSelectedfundraise] = useState<TFundraise>()
    const [type, setType] = useState<string>('user')
    const [open, setOpen] = useState<boolean>(false)
    // TODO FULL OBJECT SELECTED_FUNDRISE
    // const [value, setValue] = useState<number>()
    const [filter, setFilter] = useState<string>('')
    const [searchFundrise, setSearchFundrise] = useState<number>()
    const debouncedValue = useDebounce(searchFundrise, 500)
    
    const { langPack } = LangStore()
        
    const getFundrises = () => {
        fetch({
            params: {
                user: userData?.address,
            }
        })
    }

    const refetch = () => {
        fetch({
            params: {
                user: userData?.address,
            },
            onSuccess(res: TFundraise[]) {
                const last = res[0]
                setSelectedfundraise(last)
                setType('user')
            },
        })
    }

    const selectFR = (el: TFundraise) => {
        setSelectedfundraise(el)
    }

    const setTypehandler = (selected: string) => {
        setFilter('')
        setSearchFundrise(undefined)
        setSelectedfundraise({} as TFundraise)
        setType(selected)
        drop()
    }

    const closeHandle = (state) => {
        setOpen(state)
    }

    const submit = () => {
        if(!selectedFundrise?.id) return
        onSelect(selectedFundrise)
        selectFR({} as TFundraise)
        setSearchFundrise(undefined)
        setType('user')
        drop()
        setOpen(false)
    }

    useEffect(() => {
        if(!open) return

        if(type === 'user') {
            getFundrises()
        }
        
        if(!selected.id) return

        setSelectedfundraise(selected)
        
        if(selected.user.address === userData?.address){
            setType('user')
        } else {
            setType('number')
            setSearchFundrise(selected.id)
        }
        
    }, [open])


    useEffect(() => {
        if(debouncedValue && debouncedValue > 0) {
            getFundRiseData(debouncedValue)
        }
    }, [debouncedValue])

    const isDisabled = !selectedFundrise?.id

    const list = result
    ?   selectedFundrise?.id
            ? result.filter((el: TFundraise) => el.id === selectedFundrise?.id)
            : filter
                ? result.filter((el: TFundraise) => el.title.toLowerCase().includes(filter.toLowerCase()))
                : result
    : []
    
  return (
    <Dialog onOpenChange={closeHandle} open={open}>
        <DialogTrigger asChild>
            <div className='flex items-center space-x-2 cursor-pointer'>
                <Checkbox checked={false} onClick={() => {setOpen(true)}} />
                <FormLabel className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                    {langPack('fundraise.fundraise')}
                </FormLabel>
			</div>
            {/* <Button className="w-full z-50" variant='outline'>{langPack('fundraise.fundraise')}</Button> */}
        </DialogTrigger>
        <DialogContent className="sm:max-w-[90%] h-[80vh] flex flex-col justify-between">
            <div className='gap-4 flex flex-col'>
                <DialogHeader>
                    <DialogTitle>{langPack('fundraise.fundraise')}</DialogTitle>
                </DialogHeader>
                <RadioGroup value={type} onValueChange={setTypehandler} className='flex flex-row items-center gap-4'>
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="user" id="r1" />
                        <Label htmlFor="r1">{langPack('fundraise.my_list')}</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="create" id="r2" />
                        <Label htmlFor="r2">{langPack('fundraise.create_new_fundraise')}</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="number" id="r2" />
                        <Label htmlFor="r2">{langPack('fundraise.enter_amount')}</Label>
                    </div>
                </RadioGroup>
            </div>
            {type  === 'user' && 
                    <div className='flex flex-col w-full'>
                        <div className='p-2'>
                            <Input value={filter} onChange={(e) => setFilter(e.target.value)} placeholder={langPack('fundraise.placeholder_find')} disabled={!!selectedFundrise?.id} />
                        </div>
                        <ScrollArea className='flex flex-col p-2 h-[52vh]'>
                            {isLoadingFundriseList
                                ?   <div className="flex flex-row w-full h-full items-center justify-center mt-6">
                                        <LoadingIcon size={32} />
                                    </div>
                                : result?.length 
                                ?   list.map((el: TFundraise) => (
                                    <div className='relative'>
                                        <FundraiseCard el={el} />
                                        <Button
                                            className='absolute z-50 right-14 top-[9px]'
                                            onClick={() => selectFR(el.id === selectedFundrise?.id ? {} as TFundraise : el)}
                                        >
                                            {el.id === selectedFundrise?.id ? langPack('action.cancel') : langPack('action.add')}
                                        </Button>
                                    </div>
                                    ))
                                :   <NoContent isLoading={false} text={langPack('fundraise.no_fundraises')}/>
                            }
                        </ScrollArea>
                    </div>
            }
            {type === 'number' &&
                <div className='h-full'>
                    <div className='p-2'>
                        <AmountInput noDigits value={searchFundrise} onChange={setSearchFundrise} placeholder={langPack('fundraise.placeholder_number')} perUsd={false}/>
                    </div>
                    {
                        isLoading
                            ? <div className="flex flex-row w-full h-full items-center justify-center mt-6"><LoadingIcon size={32} /></div>
                            : fundraise?.user?.address
                                ? fundraise?.user?.address !== null_address
                                    ?  <div className='relative'>
                                            <FundraiseCard el={fundraise} />
                                            <Button
                                                className='absolute z-50 right-14 top-[9px]'
                                                onClick={() => selectFR(fundraise.id === selectedFundrise?.id ? {} as TFundraise : {...fundraise, id: searchFundrise})}
                                            >
                                                {fundraise.id === selectedFundrise?.id ? langPack('action.cancel') : langPack('action.add')}
                                            </Button>
                                        </div>
                                    : 
                                        <Card className='h-[200px] w-full flex flex-col items-center justify-center font-bold mt-5'>
                                            <TicketX size={52}/>
                                            {langPack('fundraise.not_created')}
                                        </Card>
                                : <></>
                    }
                </div>
            }
            {
                type === 'create' && <CreateFundrise reload={refetch} />
            }
            <DialogFooter>
                <Button onClick={submit} className="text-[white]" variant="orange" disabled={isDisabled}>
                    {langPack('action.save')}
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
  )
}

export default FundraiseModal