import { LayoutSidebar } from '@/shared/layout'
import { ContentSidebar } from '@/widgets/content-sidebar/ui/content-sidebar'
import { Post } from '@/widgets/post'

export const ContentPage = () => (
		
		<LayoutSidebar sidebar={<ContentSidebar />}>
			<Post />
		</LayoutSidebar>
)
