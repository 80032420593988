import { LangStore } from '@/shared/model/store'
import { IGovUnit, ProposalStatusEnum } from '../models/governance-store'
import Votebadge from './vote-badge'
import useBlocknumber from '@/shared/lib/use-blocknumber'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { GuardButton } from '@/features/guard-button'
import useNotifier from '@/shared/lib/use-notifier'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import useGovernance from '../lib/hooks/use-governance'
import { useEffect } from 'react'
import { UserStore } from '@/entities/user'
import { createKeccakHash } from '@/shared/lib'
import { formatTimeRemaining } from '@/shared/lib/utils/formattedDate'

type Props = {
	proposal: IGovUnit
}

const VoteBlock = ({ proposal }: Props) => {
	const { userData } = UserStore()
	const { langPack, lang } = LangStore()
	const { blockNumber } = useBlocknumber()
	const { contracts } = ContractsStore()
	const { getGoveranceList } = useGovernance()

	const { writeContract, isLoading } = useNotifier({
		onSuccess() {
			getGoveranceList()
		}
	})

	const vote = (pid: string, state: number) => {
		writeContract({
			address: contracts.Governance.address,
			abi: contracts.Governance.abi,
			functionName: 'castVote',
			args: [pid, state]
		})
	}

	const execute = async () => {
		writeContract({
			address: contracts.Governance.address,
			abi: contracts.Governance.abi,
			functionName: 'execute',
			args: [[contracts.Config.address], [0], proposal.calldata, createKeccakHash(proposal.description)]
		})
	}

	useEffect(() => {
		if (blockNumber > proposal.vote_start && proposal.state === ProposalStatusEnum.Pending) {
			getGoveranceList()
		}
	}, [blockNumber])

	if (ProposalStatusEnum.Defeated === proposal.state) {
		return <></>
	}

	if (proposal.state === ProposalStatusEnum.Active && !proposal.voted) {
		return (
			<>
				<div className='mb-2 flex flex-col justify-center'>
					<div className='text-center text-[12px] text-[grey]'>{langPack('governance.votes_will_be_counted')}</div>
					<AmountBlock amount={proposal.currentVoteWeight} withoutIcon />
				</div>
				<div className='mb-2 flex flex-row gap-4'>
					<GuardButton
						title={langPack('governance.vote_for')}
						className='w-full'
						variant='success'
						size={'sm'}
						onClick={() => vote(proposal.proposal_id, 1)}
					/>
					<GuardButton
						title={langPack('governance.vote_against')}
						className='w-full'
						variant='destructive'
						size={'sm'}
						onClick={() => vote(proposal.proposal_id, 0)}
					/>
				</div>
				<GuardButton
					variant={'secondary'}
					size='sm'
					onClick={() => vote(proposal.proposal_id, 2)}
					title={langPack('governance.abstain')}
				/>
				<div>{proposal.vote_var}</div>
			</>
		)
	}

	if (
		[ProposalStatusEnum.Canceled, ProposalStatusEnum.Succeeded].includes(proposal.state) &&
		proposal.proposer === userData?.address
	) {
		return (
			<div className='flex flex-col items-center justify-center gap-4 text-center text-[12px] text-[grey]'>
				<span>{langPack('governance.already_voted')}</span>
				<Votebadge vote={proposal.support} />
				<GuardButton
					variant={'orange'}
					className='w-[100px]'
					size={'sm'}
					onClick={execute}
					isLoading={isLoading}
					title={'Execute'}
				/>
			</div>
		)
	}

	if (
		[ProposalStatusEnum.Active, ProposalStatusEnum.Canceled, ProposalStatusEnum.Succeeded].includes(proposal.state) &&
		proposal.voted
	) {
		return (
			<div className='flex flex-col items-center justify-center gap-4 text-center text-[12px] text-[grey]'>
				<span>{langPack('governance.already_voted')}</span>
				<Votebadge vote={proposal.support} />
			</div>
		)
	}

	if (
		[ProposalStatusEnum.Defeated, ProposalStatusEnum.Canceled, ProposalStatusEnum.Succeeded].includes(proposal.state) &&
		!proposal.voted
	) {
		return <div className='text-center text-[12px] text-[grey]'>{langPack('governance.voting_ended')}</div>
	}

	if (proposal.state === ProposalStatusEnum.Active && proposal.currentVoteWeight === 0) {
		return <div className='text-center text-[12px] text-[grey]'>{langPack('governance.not_enough_votes')}</div>
	}

	if (proposal.state === ProposalStatusEnum.Pending) {
		return (
			<div className='text-center text-[12px] text-[grey]'>
				{langPack('governance.voting_starts')} <br />~{' '}
				{formatTimeRemaining((proposal.vote_start - blockNumber) * 10, lang)}
			</div>
		)
	}
}

export default VoteBlock
