import LowStake from '@/features/staking/ui/low-stake'
import { parseAmount } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button, Card, Label, Textarea } from '@/shared/ui'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { useState } from 'react'

type Props = {
    reload: () => void
}

const CreateFundraise = ({reload}: Props) => {
    const [description, setDescription] = useState<string | null>()
    const [value, setValue] = useState<number | null>(1000)
    const { contracts } = ContractsStore()
    const { langPack } = LangStore()

    const { writeContract, isLoading } = useNotifier({
		onSuccess() {
            reload()
            setDescription('')
            setValue(null)
		}
	})

    const createCampaign = async () => {
            if(!value || !description) return
    
            writeContract({
                address: contracts.Fundraiser.address,
                abi: contracts.Fundraiser.abi,
                functionName: 'createCampaign',
                args: [description, parseAmount(value)],
            })
    }

  return (
    <Card className="bg-background p-4">
        <div className="text-center font-bold">{langPack('fundraise.create_new_fundraise')}</div>
            <div className="my-4">
                <LowStake functionName='min_staked_for_fundrasing'>
                    <>
                        <div>
                            <Label htmlFor='quantity' className='text-top'>
                                {langPack('fundraise.fundraise_amount')}
                            </Label>
                            <AmountInput
                                value={value}
                                onChange={setValue}
                                placeholder={`Enter value`}
                            />
                        </div>
                        <div className="gap-2 mt-4">
                            <Label htmlFor='desctiption' className='text-top'>
                                {`${langPack('fundraise.description')} (max 255)`}
                            </Label>
                            <Textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                id="desctiption"
                                maxLength={255}
                                className="col-span-3"/>
                        </div>
                        <Button
                            className="w-full mt-4"
                            onClick={createCampaign}
                            disabled={!value || !description}
                            loading={isLoading}
                        >
                            {langPack('action.create')}
                        </Button>
                    </>         
                </LowStake>
            </div>
    </Card>
  )
}

export default CreateFundraise