import { AppDetailStore } from '@/shared/model/store'
import { LangStore } from '@/shared/model/store/lang-store'
import { Tabs, TabsList, TabsTrigger } from '@/shared/ui/tabs'

export const LangToggleRow = () => {
	const { lang, setLang } = LangStore()
	const { appDetails } = AppDetailStore()

	document.title = appDetails.locales.find(el => el.code === lang)?.title || appDetails.locales[0].title

	if (appDetails.locales.length === 1) return null

	return (
		<Tabs value={lang} className='mb-2'>
			<TabsList className={`grid w-full grid-cols-${appDetails.locales.length}`}>
				{appDetails.locales.map(el => (
					<TabsTrigger key={el.code} value={el.code} onClick={() => setLang(el.code)} key={el.code}>
						{el.code.toLocaleUpperCase()}
					</TabsTrigger>
				))}
			</TabsList>
		</Tabs>
	)
}
