import { LangStore } from '@/shared/model/store'
import { Preloader } from '@/shared/ui'
import { CardDescription, CardTitle } from '@/shared/ui/card'
import { Podcast } from 'lucide-react'

export const NoContent = ({ isLoading, text }: { isLoading: boolean; text?: string }) => {
	const { langPack } = LangStore()

	if (isLoading) return
	
	return (
		<div className='mt-3 flex size-full flex-col items-center justify-center rounded-lg border-[1px] border-dashed'>
			{isLoading ? (
				<Preloader small />
			) : (
				<div className='flex flex-col items-center justify-center'>
					<Podcast size={'52px'} />
					<CardTitle className='mt-4'>{text || langPack('feed.no_content')}</CardTitle>
					<CardDescription>{langPack('feed.no_content_description')}</CardDescription>
				</div>
			)}
		</div>
	)
}
