import { ContentType } from '@/entities/feed-block/model/types'
import { GuardButton } from '@/features/guard-button'
import { stringToBytes32 } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import useRequest from '@/shared/lib/use-request'
import { AppDetailStore, ConfigStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button } from '@/shared/ui'
import { Textarea } from '@/shared/ui/textarea'
import { createCid } from '@/widgets/post-editor/lib/utils'
import { RefreshCw } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getContentFromIpfs } from '../lib/get-content-from-yaml'
import { createCommentBody } from '../lib/utils'
import LowStake from '@/features/staking/ui/low-stake'
import { UserStore } from '@/entities/user'

type Props = {
	state: ContentType
	isEdit?: boolean
	drop?: () => void
}

type TValue = {
	[key: string]: string
} 

export const SimpleComment: FC<Props> = ({ state, drop, isEdit }) => {
	const [value, setValue] = useState<TValue>({} as TValue)
	const { signatureAddress: address} = UserStore()
	const { langPack, lang } = LangStore()
	const [editlanguage, setEditlanguage] = useState<string>(lang)
	const { contracts } = ContractsStore()
	const { baseUrl, domain } = ConfigStore()
	const { appDetails: { default_locale } } = AppDetailStore()
	const { writeContract, isLoading: isLoadingWrite } = useNotifier({
		onSuccess: () => {
			setValue({} as TValue)
			drop && drop()
		}
		
	})

	const editHandler = (val: string) => {
		if(!editlanguage) {
			setEditlanguage(lang)
		}
		const nObj = {...value}
		nObj[editlanguage || lang] = val
		setValue(nObj)
	}

	const { fetch, isLoading } = useRequest('uploadUserFiles')
	const { fetch: fetchIpfsUserDir, isLoading: isLoadingUserDir } = useRequest('ipfsUserDir')

	const addComment = () => {
		const formData = createCommentBody(state, isEdit, value)

		fetch({
			data: formData,
			isMultipart: true,
			onSuccess: async () =>
				await fetchIpfsUserDir({
					params: {
						path: 'content'
					},
					onSuccess: res => regContent(res.cid)
				})
		})
	}

	useEffect(() => {
		if(value[lang]) {
			setEditlanguage(lang)
		} else if(value[default_locale]){
			setEditlanguage(default_locale)
		} else {
			setEditlanguage(Object.keys(value)[0])
		}
	}, [lang])

	const getUserYaml = async () => {
        try{
            const data = await getContentFromIpfs(`${baseUrl}ipfs/${state.ipfs}`)

			const locales = {}
			for(const k in data) {
				locales[k] = data[k].chapters[0].data
			}

			setValue(locales)

			if(locales[lang]) {
				setEditlanguage(lang)
			} else if(locales[default_locale]){
				setEditlanguage(default_locale)
			} else {
				setEditlanguage(Object.keys(locales)[0])
			}
        } catch(e) {
			console.log('e', e)
        }
	}

	const regContent = async (cid: string) => {
		if (!address) return
		const { uid } = createCid(address, domain)
		const {
			guid,
		} = state

		writeContract({
			address: contracts.ContentRegistry.address,
			abi: contracts.ContentRegistry.abi,
			functionName: 'reg',
			args: [domain , address, isEdit ? guid : uid, cid, stringToBytes32(isEdit ? 'comment-edit' : 'comment')]
		})
	}

	useEffect(() => {
		if(isEdit) {
			getUserYaml()
		}
	}, [])
	
	if(isLoadingWrite) {
		return (
		<div className='flex flex-row text-[grey] items-center'>
			<RefreshCw className="mr-2 h-4 w-4 animate-spin" />
			<div>Send comment...</div>
		</div>
		)
	}

	return (
		<div>
			<LowStake functionName='min_staked_to_post' className='bg-background p-4 rounded-lg'>
				<>
				<div className='relative'>
					<Textarea value={value[editlanguage] || ''} onChange={e => editHandler(e.target.value)} className='mb-3' />
					<div className='absolute top-0 right-3 text-[grey]'>{editlanguage}</div>
				</div>
				<div className='flex flex-row gap-4'>
					<GuardButton
						onClick={addComment}
						requireAuth
						title={langPack( isEdit ? 'action.save' : 'action.add')}
						isLoading={isLoading || isLoadingUserDir || isLoadingWrite}
					/>
					<Button
						variant='ghost'
						loading={isLoadingWrite}
					>
						<Link to={isEdit ? `/comment-edit/${state.savva_cid}` : `/new-comment`} state={{...state, changed: value}}>
							{langPack('post.content_action')}
						</Link>
					</Button>
				</div>
				</>
			</LowStake>
		</div>
	)
}
