import useRequest from "@/shared/lib/use-request"
import { LangStore } from "@/shared/model/store"
import { Button, Card, Label, Switch } from "@/shared/ui"
import { useEffect } from "react"
import { ProfileProps } from "../profile"
import { IUser } from "@/entities/user/model/types"
import { UserCard, UserStore } from "@/entities/user"
import { AmountBlock } from "@/shared/ui/amount-block/amount-block"
import { NoContent } from "@/entities/feed-block/ui/no-content"
import { ExternalLink} from "lucide-react"
import LoadingIcon from "@/shared/ui/loading-icon"
import FundraiseDrawer from "@/features/fundraise-block/ui/fundraise-drawer"
import CreateFundrise from "@/features/fundraise-block/ui/create-fundraise"

export type TFundraise = {
    id?: number;
    user: IUser;
    title: string;
    target_amount: number;
    raised: number;
    finished?: boolean
};

export const FundraiseCard = ({el}: {el: TFundraise}) => {
    const { langPack } = LangStore()

    return (
        <Card className="flex flex-col mb-4 overflow-hidden bg-background" key={el.id}>
            <div className="bg-background flex items-start p-4 border-b-2 text-[grey] text-[12px] flex-col relative">
               
                <UserCard user={el.user}/>
                <div className="mt-2"><span className="font-bold">{langPack('fundraise.description')}: </span>{el.title}</div>
                {!el.finished ? <div className="absolute right-2 top-2 z-[1]">
                    <FundraiseDrawer id={el.id} trigger={<Button variant={'secondary'} size={'sm'}><ExternalLink size={20}/></Button>}/>
                </div>
                :
                <div className="absolute right-2 top-3 z-[1] rounded-lg flex flex-row justify-center items-center px-5 py-2 text-green-500">
                    FINISHED
                </div>
                }
            </div>
            <div className="flex flex-row justify-between w-[80%] m-auto items-center">
                <div className="p-2 flex flex-col items-center justify-center">
                    <div className="text-[12px] text-[grey]">
                        ID
                    </div>
                    <span className="font-bold">{el.id}</span>
                </div>
                <div className="flex p-2 flex-col items-center justify-center">
                    <div className="text-[12px] text-[grey]">
                        {langPack('fundraise.target')}
                    </div>
                    <AmountBlock amount={el.target_amount}/>
                </div>
                <div className="p-2 flex flex-col items-center justify-center">
                    <div className="text-[12px] text-[grey]">
                        {langPack('fundraise.founded')}
                    </div>
                    <AmountBlock amount={el.raised}/>
                </div>
                <div className="p-2 flex flex-col items-center justify-center">
                    <div className="text-[12px] text-[grey]">
                        {langPack('fundraise.percent')}
                    </div>
                    <span className="font-bold">{`${calculatePercentage(el.raised, el.target_amount).toFixed(2)}%`}</span>
                </div>
            </div>
        </Card>
    )
} 

export function calculatePercentage(part: number, total: number) {
    if (total === 0) {
        throw new Error("Общее число не может быть 0.");
    }
    return (part / total) * 100;
}

const Fundrise = ({profile}: ProfileProps) => {
    const { fetch, result, isLoading: isLoadingFundriseList} = useRequest<TFundraise[]>('listFundraisers')
    const { userData } = UserStore()
    const {langPack} = LangStore()

    const fetchList = (show_finished: boolean = false) => {
        fetch({
            params: {
                user: profile.address,
                show_finished,
            }
        })
    }

    useEffect(() => {
       
        fetchList()
    }, [])

    const isMy = userData?.address === profile.address

    return (
        <div>
            {isMy && <CreateFundrise reload={fetchList}/>}
            <Card className="bg-background mt-4 p-4 flex flex-row items-center gap-4">
                <Switch id='r1' onCheckedChange={fetchList} />
                <Label htmlFor="r1">{langPack('fundraise.show_closed')}</Label>
            </Card>
        
            <div className="mt-4">
                {isLoadingFundriseList
                    ?   <div className="flex flex-row w-full items-center justify-center mt-6">
                            <LoadingIcon size={32} />
                        </div>
                    : result?.length ?
                    result?.map((el: TFundraise) => <FundraiseCard el={el} />)
                : <NoContent isLoading={false} text={langPack('fundraise.no_fundraises')}/>
                }
            </div>
        </div>
    )
}

export default Fundrise