import { Label } from '../..'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import InputOnlyNumber from '../../input-number'
import { null_address } from '@/shared/model/types'
import NumericInput from '../../numeric-input'

type Props = {
	value: number | undefined | null
	onChange: (v: number) => void
	label?: string
	placeholder?: string
	disabled?: boolean
	perUsd?: boolean
	noDigits?: boolean
	max?: number
	defaultValue?: number
	token?: string
	contract?: `0x${string}`
}

const AmountInput = ({
	label,
	value,
	onChange,
	placeholder,
	disabled,
	perUsd = true,
	noDigits = false,
	max,
	defaultValue,
	token = 'SAVVA',
	contract,
}: Props) => {
	const { tokenPrice } = ContractsStore()

	const setQuaintyHandler = (value: number) => {
		if (!max) {
			onChange(value)
			return
		}
		if (value > max) {
			onChange(max)
		} else {
			onChange(value)
		}
	}

	const per_usd_price = contract && contract !== null_address
			? tokenPrice.tokens[contract].price
			: tokenPrice[token === 'SAVVA' ? 'savva_token_price' : 'base_token_price']

	return (
		<div className='grid w-full gap-4'>
			<div className={label ? 'relative grid grid-cols-4 items-center gap-4' : 'relative flex items-center gap-4'}>
				{!!label && (
					<Label htmlFor='quantity' className='text-right'>
						{label}
					</Label>
				)}
				{noDigits ? (
					<InputOnlyNumber
						disabled={disabled}
						id='quantity'
						value={value && Number(value > 0) ? value.toString() : null}
						onChange={setQuaintyHandler}
						className='col-span-3'
						placeholder={placeholder}
						defaultValue={defaultValue}
					/>
				) : (
					<NumericInput
						disabled={disabled}
						value={value && Number(value > 0) ? value.toString() : ''}
						className='col-span-3'
						placeholder={placeholder}
						onChange={(val: any) => setQuaintyHandler(val)}
					/>
				)}

				{perUsd && (
					<div className='absolute right-[10px] flex text-[12px] text-[grey]'>
						{Number(value) > 0 ? `~$${(per_usd_price * Number(value)).toFixed(2)}` : null}
					</div>
				)}
			</div>
		</div>
	)
}

export default AmountInput
