import { ContentStore } from '@/entities/feed-block/model/content-store'
import { UserCard, UserStore } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'
import { AppInfoStore, LangStore } from '@/shared/model/store'
import { Button, Card } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { useEffect, useState } from 'react'
import { ILang } from '@/entities/feed-block/model/types'
import { cn, contractRead } from '@/shared/lib'
import NftBox from './nft-box'
import NftMintDialog from './nft-mint-dialog'
import NftSettings from './nft-settings/nft-settings'
import NftBuy from './buy-nft/nft-buy'
import { ChevronDown, ChevronUp, RefreshCw } from 'lucide-react'
import { getContentFromHash } from '@/widgets/post-editor/lib/utils'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import defaultAvatar from '@/shared/assets/images/avatar-default.svg'

export const format = (date: string, lang: ILang) => {
	const d = new Date(date)
	const year = new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(d)
	const month = new Intl.DateTimeFormat(lang, { month: '2-digit' }).format(d)
	const day = new Intl.DateTimeFormat(lang, { day: '2-digit' }).format(d)
	return `${day}-${month}-${year}`
}

export type TNFTDescription = {
	name: string
	description: string
	external_url: string
	image: string
	attributes: Attribute[]
}
  
export interface Attribute {
	trait_type: string
	value: string
}

export const NftBlock = () => {
	const { appInfo } = AppInfoStore()
	const { activePost, setActivePost } = ContentStore()
	const { userData } = UserStore()
	const { langPack } = LangStore()
	const [more, setMore] = useState<boolean>(false)
	const [nftData, setNftData] = useState<TNFTDescription>({} as TNFTDescription)
	const { message } = useSubscribe('nft_minted')
	const [isLoading, setIsloading] = useState<boolean>(false)


	const readNFT = async () => {
		if(!activePost?.savva_cid) return
		const nftIPFS: string = await contractRead('ContentNFT', 'tokenURI', [activePost.savva_cid])
		// TODO MAKE HTTPS VARIANT
		if(nftIPFS?.startsWith('ipfs://')) {
			const hash = nftIPFS.replace('ipfs://', '')
			const data = await getContentFromHash(hash)
			const nftJSON = JSON.parse(data)
			if(nftJSON.description && nftJSON.image) {
				setNftData({
					...nftJSON,
					image: `${appInfo?.ipfs_gateways[0]}${nftJSON.image.replace('ipfs://', '')}`
				})
			}
		}
		setIsloading(false)
	}

	useEffect(() => {
		if(!activePost?.savva_cid) return
		readNFT()
	}, [activePost?.savva_cid || activePost?.nft?.owner])


	useEffect(() => {
		if(activePost?.savva_cid && message && message.content_id === activePost?.savva_cid) {
			setIsloading(true)
			setActivePost({
				...activePost,
				nft: {
					...activePost.nft,
					price: 0,
					owner: message.author
				}
			})
			setTimeout(() => {
				readNFT()
			}, 3000);
		}
	}, [message])

	if (!activePost?.savva_cid || activePost.nft?.on_auction) return <></>


	if (!activePost.nft?.owner?.address && userData?.address === activePost.author?.address) {
		return (
			<NftBox className='mb-6'>
				<div className='mt-4 text-center text-[13px] leading-4 text-[grey]'>{langPack('nft.nft_info')}</div>
				<div className='mt-4'>
					<NftMintDialog />
				</div>
			</NftBox>
		)
	}
	
	if (!activePost.nft?.owner?.address) return <></>

	return (
		<NftBox className='mb-6'>
			<div className='relative mt-3 flex flex-row items-center'>
				<UserCard user={activePost?.nft?.owner || ({} as IUser)} length={25} descriprion={langPack('nft.owner')} />
			</div>
			{isLoading 
			? <div className='mt-5 flex flex-row items-center justify-center w-full'><RefreshCw className="mr-2 h-4 w-4 animate-spin" />{langPack('action.loading')}</div>
			:<div className='mt-3 flex flex-col items-center justify-between'>
				<div className='mb-3 flex w-full flex-col justify-between px-6'>
					{activePost.nft.on_market && (
						<div className='flex flex-col items-center justify-center'>
							<div className='text-sm'>{langPack('nft.price')}</div>
							<AmountBlock amount={activePost.nft.price} size='md' perUsd usdClassName='-mt-3'/>
						</div>
					)}
				</div>
				{nftData.description ? <Card
					className={cn(
						'h-0 overflow-hidden transition duration-500 ease-in-out',
						more && 'h-auto transition duration-500 ease-in-out'
					)}
				>
					<div>
						<img className='w-full rounded-t-lg object-cover' src={nftData.image.length ? nftData.image :  defaultAvatar} alt='savva.img' />
					</div>
					<div className='p-2 text-[12px] text-[grey]'>
						{nftData.description}
					</div>
				</Card> : (activePost.nft.owner?.address === userData?.address && nftData.external_url) ? <div className='text-[red] font-semibold'>NFT spec error, remove and create again</div> : <></>}
				<div className='mt-3 flex w-full flex-row gap-3'>
					{nftData.description &&
						<div className='w-[50px]'>
						<Button variant='ghost' size='icon' onClick={() => setMore(!more)}>
								{!more ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
							</Button>
						</div>

					}
					<div className='w-full'>
						{userData?.address === activePost.nft.owner?.address && <NftSettings />}
						{activePost.nft.on_market && userData?.address !== activePost.nft.owner.address && (
							<NftBuy cid={activePost.savva_cid} />
						)}
					</div>
				</div>
			</div>}
		</NftBox>
	)
}
