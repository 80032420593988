import { ContentStore } from '@/entities/feed-block/model/content-store'
import { ContentType } from '@/entities/feed-block/model/types'
import { getCurrentTitles } from '@/entities/feed-block/model/utils'
import { UserStore } from '@/entities/user'
import ReactionsPreview from '@/features/reaction-preview/ui/reaction-preview'
import { cn } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import useRequest from '@/shared/lib/use-request'
import { AppDetailStore, ConfigStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { IBlock } from '@/shared/model/types'
import { TimeAgo } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { CardTitle, Card, CardContent } from '@/shared/ui/card'
import { UserName } from '@/shared/ui/user-name'
import { MessageCircle, Pin, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type Props = {
	data: IBlock
	className?: string
}

export const AnnounceBlock = ({ data, className }: Props) => {
	const { lang } = LangStore()
	const { setActivePost } = ContentStore()
	const { isAdmin } = UserStore()
	const { domain } = ConfigStore()
	const { contracts } = ContractsStore()
	const [currentContentList, setCurrentContentlist] = useState<IBlock | null>(null)
	const { appDetails } = AppDetailStore()
	const { modules } = appDetails
	const { fetch, result } = useRequest<{ list: ContentType[] }>('getList')


	const getList = () => {
		fetch({
			params: {
				domain: domain,
				list_name: data.list_name,
				limit: data.count
			}
		})
	}

	const { writeContract } = useNotifier({
		onSuccess(){
			getList()
		}
	})

	const removeAnnouncement = (content: ContentType) => {
		writeContract({
			address: contracts.ContentRegistry.address,
            abi: contracts.ContentRegistry.abi,
            functionName: 'command',
            args: [
				domain,
				'list_remove_post',
				data.list_name,
				content.savva_cid,
				'',
				'',
            ],
		})
	}

	useEffect(() => {
		const currentContentlistSplited = {
			...modules.content_lists.list?.[data.list_name],
			...data
		}
		setCurrentContentlist(currentContentlistSplited)
		getList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	return (
		<Card className={cn('mb-3 w-full', className)}>
			<CardTitle className='border-b p-2 pl-4 text-[18px]'>{currentContentList?.title?.[lang]}</CardTitle>

			<CardContent className='p-0'>
				{result?.list.map((content: ContentType) => (
					<div className='flex flex-col w-full border-b-2' key={content.guid}>
						<div className='px-4 pt-[10px]'>
							<div className='flex flex-row justify-between'>
								<Link to={`/${content.author.address}`} className='flex flex-row justify-between pr-1'>
									<UserName account={content.author} sliceLen={content.fund.amount > 0 ? 25 : 34} />
								</Link>
								{content.fund.amount > 0 && (
									<div className='flex flex-row items-center text-[14px] font-[400]'>
										{/* <span className='mr-1 text-[12px] uppercase text-[grey]'>{langPack('fund.title')}:</span> */}
										<AmountBlock amount={content.fund.amount} size='sm'/>
									</div>
								)}
							</div>
							<div className='flex flex-row w-full justify-between items-center'>
								<TimeAgo date={content.timestamp} />
									
									<div className='flex flex-row gap-1 items-center mt-2'>
									{isAdmin && <Trash className='cursor-pointer' color='grey' size={15} onClick={() => removeAnnouncement(content)}/>}
									{content.pinned && <Pin className='cursor-pointer rotate-45' color='grey' size={15}/>}
									</div>
							</div>
							<Link
								className='flex w-full flex-col'
								to={`/content/${content.savva_cid}`}
								key={content.savva_cid}
								state={content}
								onClick={() => setActivePost(content)}
							>
								<h2 className='text-md font-semibold text-gray-800 dark:text-gray-50 line-clamp-2'>
									{getCurrentTitles(content.savva_content.locales, 'title')}
								</h2>
								<p className='mt-1 text-[14px] text-gray-500 break-words line-clamp-2'>
									{getCurrentTitles(content.savva_content.locales, 'text_preview')}
								</p>
							</Link>
						</div>
						
						<div className='flex w-full flex-row items-center justify-between px-4 py-2'>
							<div className='flex flex-row items-center gap-4'>
								<div className='row flex items-center gap-1 text-[grey]'>
									<MessageCircle color='grey' size={15} />
									<span className='text-[12px]'>{content.total_childs}</span>
								</div>
							</div>
							
							<div className='mt-[2px] pl-4'>
								<ReactionsPreview reactions={content.reactions} />
							</div>
						</div>
					</div>
				))}
			</CardContent>
		</Card>
	)
}
