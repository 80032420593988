import { PlsIcon, SavvaIcon, Dai, Usdc } from '@/shared/assets'
import { cn, parceEthAmount, parseEthAmountDec, shortNumberFormat } from '@/shared/lib'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../tooltip'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { useEffect, useState } from 'react'
import FormatNumber from '../format-number'
import { null_address } from '@/shared/model/types'

export const getIcon = token => {
	switch (token) {
		case 'SAVVA':
			return <SavvaIcon />
		case 'PLS':
			return <PlsIcon />
		case 'DAI':
			return <Dai />
		case 'USDC':
			return <Usdc />
	}
}

type Props = {
	amount: number | bigint
	size?: keyof AmountBlockSizeType
	perUsd?: boolean
	token?: 'SAVVA' | 'PLS' | 'DAI' | 'USDC'
	usdClassName?: string
	danger?: boolean
	short?: boolean
	description?: JSX.Element
	iconPosition?: 'left' | 'right'
	contract?: `0x${string}`
	decimals?: number
	onClick?: () => void
	withoutIcon?: boolean
}

type ABUT = {
	icon: string
	text: string
	gap: string
}

type AmountBlockSizeType = {
	sm: ABUT
	md: ABUT
	lg: ABUT
}

const ICON_SIZE: AmountBlockSizeType = {
	sm: {
		icon: 'scale-[0.7]',
		text: `text-[16px] font-bold`,
		gap: 'gap-0'
	},
	md: {
		icon: 'scale-[1.05]',
		text: `text-[28px] font-bold`,
		gap: 'gap-2'
	},
	lg: {
		icon: 'scale-[1.8]',
		text: `text-[54px] font-bold`,
		gap: 'gap-4'
	}
}

export const AmountBlock = ({
	amount,
	size = 'sm',
	perUsd = false,
	token = 'SAVVA',
	contract,
	decimals,
	usdClassName,
	danger,
	short,
	description,
	iconPosition = 'left',
	withoutIcon = false,
	onClick
}: Props) => {
	const { tokenPrice } = ContractsStore()
	const [blinkUsd, setblinkUsd] = useState<boolean>(false)
	const [blink, setblink] = useState<boolean>(false)

	useEffect(() => {
		setblink(true)
		setTimeout(() => setblink(false), 500)
	}, [amount])

	useEffect(() => {
		if (token !== 'SAVVA') return
		setblinkUsd(true)
		setTimeout(() => setblinkUsd(false), 500)
	}, [token, tokenPrice.savva_token_price])

	useEffect(() => {
		if (token !== 'PLS') return
		setblinkUsd(true)
		setTimeout(() => setblinkUsd(false), 500)
	}, [token, tokenPrice.base_token_price])

	const per_usd_price =
		contract && contract !== null_address
			? tokenPrice.tokens[contract].price * Number(parseEthAmountDec({ data: amount, decimals: decimals || 18 }))
			: tokenPrice[token === 'SAVVA' ? 'savva_token_price' : 'base_token_price'] *
				Number(parseEthAmountDec({ data: amount, decimals: decimals || 18 }))

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild onClick={onClick && onClick}>
					<div className={cn('flex flex-row items-center justify-center', ICON_SIZE[size].gap)}>
						{iconPosition === 'left' && !withoutIcon && (
							<div className={cn(ICON_SIZE[size].icon, 'flex flex-row items-center')}>{getIcon(token)}</div>
						)}
						<div className='relative'>
							<div
								className={cn(
									'flex flex-row items-center',
									ICON_SIZE[size].text,
									blink && 'animate-flyin',
									danger && 'text-[red]'
								)}
							>
								{short ? (
									<FormatNumber number={amount} />
								) : (
									shortNumberFormat(Number(parseEthAmountDec({ data: amount, decimals: decimals || 18 })))
								)}
							</div>
							{perUsd && (
								<div
									className={cn(
										'-mt-5 flex w-full flex-row items-center justify-end',
										short && '-mt-3',
										blinkUsd && 'animate-flyin',
										usdClassName && usdClassName
									)}
								>
									{`~$${per_usd_price.toFixed(2)}`}
								</div>
							)}
						</div>
						{iconPosition === 'right' && !withoutIcon && (
							<div className={cn(ICON_SIZE[size].icon, 'flex flex-row items-center')}>{getIcon(token)}</div>
						)}
					</div>
				</TooltipTrigger>
				<TooltipContent className='bg-gradient-to-bl from-sky-600 via-sky-800 to-sky-900 p-3'>
					{description || (
						<p className={cn('text-[20px] text-white', blink && 'animate-flyin')}>
							{parceEthAmount(amount)} {token}
						</p>
					)}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
