import { ContentStore } from "@/entities/feed-block/model/content-store"
import { UserCard } from "@/entities/user"
import { cn } from "@/shared/lib"
import { Card } from "@/shared/ui"
import { AmountBlock } from "@/shared/ui/amount-block/amount-block"
import { useEffect } from "react"
import FundraiseDrawer from "./fundraise-drawer"
import useFundraise from "./use-fundraise"
import { null_address } from "@/shared/model/types"
import { LangStore } from "@/shared/model/store"
import { useSubscribe } from "@/shared/lib/use-subscribe"

const FundRiseBlock = () => {
    const { message } = useSubscribe('fundraiser_contribution')
    const { message: closeFundrise } = useSubscribe('fundraiser_closed')
    const { activePost } = ContentStore()
    const { getFundRiseData, fundraise, percentage, currentP, isBiggest } = useFundraise()
    const { langPack } = LangStore()
    
    const id = Number(activePost?.savva_content?.fundraiser || 0)
    
    useEffect(() => {
        if(!activePost?.savva_cid || !id) return
        getFundRiseData(id)
    }, [activePost])

    useEffect(() => {
        if(!activePost?.savva_cid || !id) return
        if(message && message.id === Number(id)) {
            getFundRiseData(id)
            fetch({
                params: {
                    id,
                }
            })
        }
    }, [message])  

    useEffect(() => {
        if(!activePost?.savva_cid || !id) return
        if(closeFundrise && closeFundrise.id === Number(id)) {
            getFundRiseData(id)
        }
    }, [closeFundrise])

    if(!fundraise?.user?.address || fundraise?.user?.address === null_address) return <></>
  
return (
    <Card className="mb-6 p-4 bg-background">
        <div className="flex flex-col items-center">
            <div className="font-semibold mb-2">{langPack('fundraise.fundraise')}</div>
            <UserCard user={fundraise.user} length={18}/>	
        </div>
        <div className="text-[14px] mt-2 text-center">
            {langPack('fundraise.description')}:
        </div>
        <div className="text-[grey] text-[12px] line-clamp-2 capitalize text-center">
            {fundraise.title}
        </div>
        <div className="flex flex-col justify-between items-center w-[80%] m-auto mt-4">
            <div className="flex flex-col items-center">
                <div className="text-[12px] text-[grey]">{langPack('fundraise.founded')}</div>
                <AmountBlock amount={fundraise.raised} size="md"/>
            </div>
            <div className="text-[12px] text-[grey]">{langPack('fundraise.of')}</div>
            <div className="flex flex-col items-center">
                {/* <div className="text-[12px] text-[grey]">FUNDED</div> */}
                <AmountBlock amount={fundraise.target_amount}  size="md"/>
            </div>
           
            
           
        </div>
        <div className={cn("relative w-[90%] h-6 bg-gray-200 m-auto mt-4 rounded-md overflow-hidden", isBiggest && 'bg-orange-400')}>
            <div
                className={`absolute top-0 left-0 h-full bg-green-500 transition-all duration-300`}
                style={{ width: `${isBiggest ? currentP : percentage}%` }}
            >
            </div>
            <div className="absolute right-1 z-10 font-bold text-[#fff]">{percentage.toFixed(2)}%</div>
        </div>
        <div className="mt-4">
            <FundraiseDrawer id={activePost?.savva_content.fundraiser || 0}/>
        </div>
    </Card>
  )
}

export default FundRiseBlock