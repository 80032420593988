import { AppInfoStore } from '../model/store';
import { useBlockNumber } from 'wagmi';


const useBlocknumber = () => {
    const { appInfo } = AppInfoStore()
    
    const { data } = useBlockNumber({
        chainId: appInfo.blockchain_id,
        watch: true, // Автоматическое обновление
    });

    const blockNumber = Number(data)
  return { blockNumber }
}

export default useBlocknumber