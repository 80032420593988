import { SavvaContracts } from '@/shared/model/store/contracts-store'
import { useEffect, useState } from 'react'
import { useReadContracts } from 'wagmi'
import { IContentList, IPriceList } from '../../model/types'
import { formatEther } from 'viem'
import { UserStore } from '@/entities/user'

type Props = {
	contracts: SavvaContracts
	contetnsLists: IContentList[]
	isOnlyBuy?: boolean
}

export const usePrice = ({ contracts, contetnsLists, isOnlyBuy }: Props) => {
	const [price, setPrice] = useState<IPriceList[]>([])

	const { data } = useReadContracts({
		contracts: contetnsLists.map(({ type }) => ({
			abi: contracts.ListMarket.abi,
			address: contracts.ListMarket.address,
			functionName: 'getPrice',
			args: [type]
		}))
	})

	useEffect(() => {
		if (data) {
			const arr = contetnsLists
				.map((item, idx) => ({
					...item,
					price: data[idx].result ? formatEther(data[idx].result) : ''
						
				}))
				.filter(el => el.on_market && (!isOnlyBuy ? true : Number(el.price)))
			
			
			setPrice(arr)
		}
	}, [data])

	return { price }
}
