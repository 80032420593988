import { ConfigStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { useEffect } from 'react'
import { useReadContract } from 'wagmi'
import { UserStore } from '../model/user-store'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { ContentStore } from '@/entities/feed-block/model/content-store'

export const useSponsorsSumm = () => {
	const { contracts } = ContractsStore()
	const { domain } = ConfigStore()
	const { sponsorsSumm, setSponsorSumm } = ContentStore()
	const { signatureAddress } = UserStore()

	const { message } = useSubscribe('content_processed')

	const { data, isSuccess, refetch } = useReadContract({
		abi: contracts.AuthorsClubs.abi,
		address: contracts.AuthorsClubs.address,
		functionName: 'claimableGain',
		args: [domain, signatureAddress]
	})

	useEffect(() => {
		if (isSuccess) {
			setSponsorSumm(data)
		}

		if (message && message?.author?.address === signatureAddress) {
			refetch()
		}
	}, [data, isSuccess, message])

	return { sponsorsSumm }
}
