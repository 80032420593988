import { useWallet } from '@/entities/wallet'
import { checkEthAddress, contractRead, DEFAULT_PREC, parseAmount, stringToBytes32 } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
	Button,
	Card,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
	Input,
	Label,
	Textarea
} from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { RadioGroup, RadioGroupItem } from '@/shared/ui/radio-group'
import SelectComponent from '@/shared/ui/select-component'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { useEffect, useState } from 'react'
import { encodeFunctionData } from 'viem'
import {
	calculateRevertUnits,
	calculateUnits,
	TTypeConfig,
	typeOptions,
	types_config,
	typesGet,
	typesSet,
	typeUnits
} from '../models/constants'
import useGovernance from '../lib/hooks/use-governance'
import dayjs from 'dayjs'
import { GuardButton } from '@/features/guard-button'
import { LangStore } from '@/shared/model/store'

const CreateProposal = () => {
	const [open, setOpen] = useState(false)
	const [type, setType] = useState<string>('deposit')
	const { langPack } = LangStore()

	const { getGoveranceList } = useGovernance()
	const { plsBalanceUnparced, address, refetchPls } = useWallet()
	const [depositedBalance, setDepositedBalance] = useState(0)
	const [proposalPrice, setProposalPrice] = useState<number>(0)
	const [description, setDescription] = useState('')
	const [amount, setAmount] = useState<number | bigint | null>()
	const [selectedOption, setSelectedOption] = useState<TTypeConfig>({} as TTypeConfig)

	const [oldValue, setOldValue] = useState<string | number>('')
	const [value, setValue] = useState<string | number>('')

	const [selectetedOptionType, setSelectedOptionType] = useState<string>('auto')

	const { contracts } = ContractsStore()

	const available = depositedBalance >= proposalPrice

	const { writeContract } = useNotifier({
		onSuccess() {
			getDepositedBalance()
			refetchPls()
		}
	})

	const { writeContract: writeContractCreate, isLoading: isLoadingCreate } = useNotifier({
		onSuccess() {
			getGoveranceList()
			setOpen(false)
			setAmount(null)
		}
	})

	const getDepositedBalance = async () => {
		const balance = await contractRead('Governance', 'balances', [address])
		setDepositedBalance(Number(balance))
	}

	const setDataType = (val: string) => {
		const n = {
			type: val,
			units: val === 'Uint' ? '' : 'str'
		}
		setSelectedOption((prev: TTypeConfig) => ({ ...prev, ...n }))
	}

	const selectedPartHandler = (val, key) => {
		setSelectedOption((prev: TTypeConfig) => ({ ...prev, [key]: val }))
	}

	const getProposalPrice = async () => {
		const price = await contractRead('Config', 'getUInt', [stringToBytes32('gov_proposal_price')])
		setProposalPrice(Number(price))
	}

	const createDeposit = async () => {
		if (type === 'deposit') {
			writeContract({
				address: contracts.Governance.address,
				abi: contracts.Governance.abi,
				functionName: 'deposit',
				args: [],
				value: parseAmount(amount)
			})
		} else {
			writeContract({
				address: contracts.Governance.address,
				abi: contracts.Governance.abi,
				functionName: 'withdraw',
				args: [parseAmount(amount)]
			})
		}
	}

	const withdrawAl = () => {
		writeContract({
			address: contracts.Governance.address,
			abi: contracts.Governance.abi,
			functionName: 'withdraw',
			args: [depositedBalance]
		})
	}

	const getOldOptionHandler = async () => {
		if (!selectedOption?.type) return
		const { type, name, units } = selectedOption

		const val = await contractRead('Config', typesGet[type], [stringToBytes32(name)])
		setOldValue(calculateUnits[units](val))
	}

	const createProposal = () => {
		if (!selectedOption?.type || !selectedOption?.name) return

		const calculatedValue = calculateRevertUnits[selectedOption?.units](value)
		const encoded = encodeFunctionData({
			abi: contracts.Config.abi,
			functionName: typesSet[selectedOption?.type],
			args: [stringToBytes32(selectedOption?.name), calculatedValue]
		})

		writeContractCreate({
			address: contracts.Governance.address,
			abi: contracts.Governance.abi,
			functionName: 'propose',
			args: [[contracts.Config.address], [0], [encoded], `${description} ${dayjs().format('DD.MM.YYYY HH:MM')}`]
		})
	}

	const mainOptions = types_config.filter(el => el.type !== 'Address').map(el => ({ label: el.name, value: el.name }))

	const setTypehandler = (selected: string) => {
		setAmount(null)
		setType(selected)
	}

	const setFunction = (selected: string) => {
		const data = types_config.find((el: TTypeConfig) => el.name === selected)
		setSelectedOption(data)
	}

	useEffect(() => {
		if (selectetedOptionType === 'manual' && selectedOption?.name) {
			const isCreated = types_config.filter(el => el.type !== 'Address').find(el => el.name === selectedOption?.name)
			if (isCreated) {
				setSelectedOption(isCreated)
			}
		}
	}, [selectedOption])

	useEffect(() => {
		if (!selectedOption?.name || !selectedOption?.type || !selectedOption?.units) return
		getOldOptionHandler()
	}, [selectedOption])

	useEffect(() => {
		if (address) getDepositedBalance()
	}, [address])

	useEffect(() => {
		if (open) getProposalPrice()
	}, [open])

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<GuardButton title={langPack('governance.create_proposal_title')} variant={'orange'} />
			</DialogTrigger>
			<DialogContent className='min-w-[725px]'>
				<DialogTitle>{langPack('governance.create_proposal_title')}</DialogTitle>
				<div className='flex flex-row items-center justify-between'>
					<Label>{langPack('governance.proposal_price')}</Label>
					<AmountBlock amount={proposalPrice} token='PLS' short={proposalPrice < 1 * DEFAULT_PREC} />
				</div>
				{depositedBalance < proposalPrice && (
					<span className='text-[12px] text-[red]'>{langPack('governance.insufficient_deposit')}</span>
				)}
				<Accordion type='single' collapsible className='box-border w-full rounded-lg border-[1px] px-2'>
					<AccordionItem value={'item1'}>
						<AccordionTrigger className='text-[12px]'>{langPack('governance.balance_management')}</AccordionTrigger>
						<AccordionContent className='flex flex-col gap-3 px-2'>
							<div className='flex flex-row items-center justify-between'>
								<Label>{langPack('governance.pls_balance')}</Label>
								<AmountBlock amount={plsBalanceUnparced} token='PLS' short={plsBalanceUnparced < 1 * DEFAULT_PREC} />
							</div>
							<div className='flex flex-row items-center justify-between'>
								<Label>{langPack('governance.pls_deposit')}</Label>
								<AmountBlock amount={depositedBalance} token='PLS' short={depositedBalance < 1 * DEFAULT_PREC} />
							</div>
							<RadioGroup value={type} onValueChange={setTypehandler} className='flex flex-row items-center gap-4'>
								<div className='flex items-center space-x-2'>
									<RadioGroupItem value='deposit' id='r1' />
									<Label htmlFor='r1'>{langPack('governance.deposit')}</Label>
								</div>
								<div className='flex items-center space-x-2'>
									<RadioGroupItem value='withdraw' id='r2' disabled={!depositedBalance} />
									<Label htmlFor='r2'>{langPack('governance.withdraw')}</Label>
								</div>
							</RadioGroup>

							<div className='flex w-full flex-col'>
								<Label>
									{type === 'deposit'
										? langPack('governance.increase_deposit')
										: langPack('governance.decrease_deposit')}
								</Label>
								<div className='mt-2 flex flex-row gap-2'>
									<AmountInput value={amount} onChange={setAmount} token='PLS' />
									<Button onClick={createDeposit} disabled={!amount}>
										{type === 'deposit' ? langPack('governance.deposit') : langPack('governance.withdraw')}
									</Button>
									{type === 'withdraw' && (
										<Button onClick={withdrawAl} disabled={!depositedBalance}>
											Withdraw all
										</Button>
									)}
								</div>
							</div>
						</AccordionContent>
					</AccordionItem>
				</Accordion>

				<div>
					<Label>{langPack('governance.proposal_description')}</Label>
					<Textarea
						disabled={!available || isLoadingCreate}
						rows={3}
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</div>
				<Card className='flex flex-col gap-5 bg-background p-2'>
					<DialogTitle>{langPack('governance.proposal_header')}</DialogTitle>
					<RadioGroup
						value={selectetedOptionType}
						onValueChange={setSelectedOptionType}
						className='flex flex-row items-center gap-4'
					>
						<div className='flex items-center space-x-2'>
							<RadioGroupItem value='auto' id='r1' />
							<Label htmlFor='r1'>{langPack('governance.from_list')}</Label>
						</div>
						<div className='flex items-center space-x-2'>
							<RadioGroupItem value='manual' id='r2' disabled={!depositedBalance} />
							<Label htmlFor='r2'>{langPack('governance.manually')}</Label>
						</div>
					</RadioGroup>

					{selectetedOptionType === 'auto' ? (
						<>
							<div className='flex w-full flex-row gap-4'>
								<div className='flex w-full flex-col gap-2'>
									<Label>{langPack('governance.parameter')}</Label>
									<SelectComponent value={selectedOption?.name} onChange={setFunction} options={mainOptions} />
								</div>
								<div className='flex w-full flex-col gap-2'>
									<Label>{langPack('governance.new_value')}</Label>
									{selectedOption?.type === 'Uint' ? (
										<AmountInput
											value={value}
											onChange={setValue}
											token={selectedOption.units}
											perUsd={['SAVVA', 'PLS'].includes(selectedOption?.units)}
											noDigits={!['SAVVA', 'PLS'].includes(selectedOption?.units)}
										/>
									) : (
										<Input
											value={value}
											onChange={e => setValue(e.target.value)}
											placeholder={langPack('governance.enter_new_value')}
										/>
									)}
								</div>
							</div>

							<div className='flex w-full flex-col gap-2'>
								{selectedOption?.description && (
									<div className='flex flex-row gap-2'>
										<span>{langPack('governance.description_label')}</span>
										<span className='text-[grey]'>{selectedOption?.description}</span>
									</div>
								)}
								<div className='flex flex-row gap-2'>
									<span>{langPack('governance.old_value')}</span>
									<span className='text-[grey]'>{oldValue || '---'}</span>
								</div>
							</div>
						</>
					) : (
						<>
							<div className='flex w-full flex-row justify-between gap-4'>
								<Input
									value={selectedOption?.name}
									onChange={e => selectedPartHandler(e.target.value, 'name')}
									placeholder={langPack('governance.enter_parameter')}
								/>
								<SelectComponent onChange={v => setDataType(v)} options={typeOptions} value={selectedOption?.type} />
								<SelectComponent
									onChange={v => selectedPartHandler(v, 'units')}
									options={typeUnits}
									disabled={['Address', 'str'].includes(selectedOption.type)}
									value={selectedOption?.units}
								/>
							</div>
							{selectedOption?.type === 'Uint' ? (
								<AmountInput
									value={value}
									onChange={setValue}
									token={selectedOption.units}
									perUsd={['SAVVA', 'PLS'].includes(selectedOption?.units)}
									noDigits={!['SAVVA', 'PLS'].includes(selectedOption?.units)}
								/>
							) : (
								<>
									<Input
										value={value}
										onChange={e => setValue(e.target.value)}
										placeholder={langPack('governance.enter_new_value')}
									/>
									{selectedOption.type === 'Address' && !checkEthAddress(value.toString()) && !!value && (
										<span className='pl-2 text-[12px] text-[red]'>No valid Eth address</span>
									)}
								</>
							)}
						</>
					)}
				</Card>
				<DialogFooter>
					<Button variant='default'>{langPack('governance.cancel')}</Button>
					<Button disabled={!available || !description} onClick={createProposal} loading={isLoadingCreate}>
						{langPack('governance.create')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default CreateProposal
