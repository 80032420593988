import { ContentType } from '@/entities/feed-block/model/types'
import { getCurrentTitles } from '@/entities/feed-block/model/utils'
import { UserCard } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'
import ContentImage from '@/features/content-image/ui/content-image'
import { cn } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Button, Input, Label, ScrollArea, useDebounce } from '@/shared/ui'
import { RefreshCw, SearchIcon, XCircleIcon } from 'lucide-react'
import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

const Search = () => {
	const { fetch: fetchSearchUser, result, isLoading, dropState } = useRequest<IUser[]>('searchUser')
	const {
		fetch: fetchFts,
		result: resultFts,
		isLoading: isLoadingFts,
		dropState: dropStateFts
	} = useRequest<{ list: ContentType[]; total: number }>('fts')

	const inputRef = useRef<HTMLInputElement>(null)
	const searchRef = useRef<HTMLDivElement>(null)

	const { domain } = ConfigStore()
	const { langPack } = LangStore()
	const [searchStr, setSearchStr] = useState<string>('')
	const debouncedValue = useDebounce(searchStr, 1000)
	const [isOpen, setIsOpen] = useState(false)

	const dropResults = useCallback(() => {
		setSearchStr('')
		dropState()
		dropStateFts()
	}, [dropState, dropStateFts])

	const closeHandler = useCallback(() => {
		setIsOpen(prev => !prev)
		if (isOpen) {
			dropResults()
		}
	}, [isOpen])

	useEffect(() => {
		fetchSearchUser({
			params: { domain, query: debouncedValue }
		})
		fetchFts({
			params: { domain, query: debouncedValue, limit: 6, offset: 0 }
		})
	}, [debouncedValue, domain])

	useEffect(() => {
		if (isOpen && inputRef.current) {
			setTimeout(() => inputRef.current?.focus(), 100)
		}
	}, [isOpen])

	const { hasResults, hasLoading } = useMemo(() => {
		const posts = resultFts?.list?.filter(el => el.savva_content.content_type === 'post') || []
		const comments = resultFts?.list?.filter(el => el.savva_content.content_type === 'comment') || []

		return {
			hasResults: (result && result.length > 0) || posts.length > 0 || comments.length > 0,
			hasLoading: isLoadingFts || isLoading,
			posts,
			comments
		}
	}, [result, resultFts, isLoadingFts, isLoading])

	return (
		<div className='relative' ref={searchRef}>
			{/* Поле поиска */}
			<div
				className={cn(
					'absolute left-0 mr-4 w-0 transform overflow-hidden duration-300 ease-in-out',
					isOpen && '-left-[710px] w-[700px]'
				)}
			>
				<Input
					placeholder={langPack('search.placeholder')}
					value={searchStr}
					onChange={e => setSearchStr(e.target.value)}
					className='h-10 w-full'
					ref={inputRef}
				/>
			</div>

			{/* Индикаторы состояния */}
			{hasLoading && isOpen && <RefreshCw className='absolute right-16 top-3 z-[999] h-4 w-4 animate-spin' />}
			{hasResults && isOpen && !hasLoading && (
				<XCircleIcon className='absolute right-16 top-3 z-[999] h-4 w-4 cursor-pointer' onClick={dropResults} />
			)}

			{/* Кнопка поиска */}
			<Button size='icon' variant='outline' onClick={closeHandler}>
				<SearchIcon />
			</Button>

			{/* Результаты поиска */}
			{hasResults && isOpen && (
				<div className='absolute -left-[710px] mt-2 w-[700px] rounded-lg bg-background p-5 shadow-lg'>
					<ScrollArea className='max-h-[650px]'>
						{/* Пользователи */}
						{result && result?.length > 0 && (
							<div className='mb-2 flex flex-col gap-2 border-b-2 pb-2'>
								<Label>{langPack('search.users')}</Label>
								{result?.map(user => (
									<UserCard
										key={user.address}
										user={user}
										className='h-6 w-6'
										length={55}
										onClick={() => {
											dropResults()
											setIsOpen(false)
										}}
									/>
								))}
							</div>
						)}

						{/* Контент */}
						<div className='mb-4'>
							<Label>{langPack('search.content')}</Label>
							{resultFts?.list.map(content =>
								content.savva_content.content_type === 'post' ? (
									<Link
										key={content.savva_cid}
										to={`/content/${content.savva_cid}`}
										onClick={() => {
											dropResults()
											setIsOpen(false)
										}}
										className='mb-2 flex items-center justify-between overflow-hidden shadow-lg'
									>
										<div className='flex flex-col p-2'>
											<Label>{getCurrentTitles(content.savva_content.locales, 'title')}</Label>
											<p className='line-clamp-2 text-[12px] text-[grey]'>
												{getCurrentTitles(content.savva_content.locales, 'text_preview')}
											</p>
										</div>
										<div className='relative overflow-hidden rounded-b-lg md:flex-shrink-0 md:rounded-b-none lg:rounded-r-lg'>
											<ContentImage content={content} show className='h-[65px] w-full object-cover md:w-[50px]' />
										</div>
									</Link>
								) : (
									<div
										key={content.savva_cid}
										className='mb-2 flex flex-col items-center justify-between overflow-hidden rounded-md p-2 shadow-lg'
									>
										<Link
											to={`/${content.author.address}`}
											className='cursor-pointer'
											onClick={() => {
												dropResults()
												setIsOpen(false)
											}}
										>
											<UserCard user={content.author} className='h-6 w-6' length={55} />
										</Link>
										<div className='mt-2 line-clamp-2 text-[12px] italic text-[grey]'>
											{getCurrentTitles(content.savva_content.locales, 'text_preview')}
										</div>
									</div>
								)
							)}
						</div>
					</ScrollArea>

					{resultFts && resultFts?.total > 0 && (
						<div className='mt-5 flex cursor-pointer items-center justify-center text-[12px] text-[grey]'>
							<Link
								to={`/search?q=${debouncedValue}`}
								onClick={() => {
									closeHandler()
									dropResults()
								}}
							>
								{langPack('search.show_all_results')}: {resultFts?.total}
							</Link>
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default Search
