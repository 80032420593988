import { IUser } from '@/entities/user/model/types'
import { useEffect, useState } from 'react'
import { IPFSProfileType, ProfileProps } from '../profile'
import { LangStore } from '@/shared/model/store'
import useRequest from '@/shared/lib/use-request'

import { Card, Checkbox, Label, Preloader } from '@/shared/ui'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import { UserCard, UserStore } from '@/entities/user'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import SponsorModal from './sponsor-modal'

export interface ISponsors {
	amount: number
	weeks: number
	user: IUser & IPFSProfileType
	domain: string
}

const Sponsors = ({ profile, getUserFetch }: ProfileProps) => {
	const { langPack } = LangStore()
	const [currentTab, setCurrentTab] = useState<string>('sponsees')
	const { userData } = UserStore()
	const [showLost, setShowLost] = useState<boolean>(true)

	const { fetch: fetchSponsors, result, isLoading: isLoadingSponsors } = useRequest<ISponsors[]>('getSponsors')
	const { fetch: fetchSponsees, result: sponses, isLoading: isLoadingSponsees } = useRequest<ISponsors[]>('getSponsees')

	const getAllSponsees = () => {
		fetchSponsees({
			params: {
				user_addr: profile?.address
			}
		})
	}

	const getAllSponsors = () => {
		fetchSponsors({
			params: {
				user_addr: profile?.address
			}
		})
	}

	const fetchAll = () => {
		getAllSponsors()
		getAllSponsees()
	}

	const toggleShowLost = () => {
		setShowLost(!showLost);
	};

	const hasMultipleWeeks = userData?.address === profile.address;

	useEffect(() => {
		fetchAll()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile.address])

	return (
		<Card className='p-8 shadow-md'>
			<Tabs defaultValue='blog' className='w-full' value={currentTab} onValueChange={setCurrentTab}>
				<TabsList className='grid w-full grid-cols-2'>
					<TabsTrigger value='sponsees'>{langPack('profile.sponsor_other')}</TabsTrigger>
					<TabsTrigger value='sponsors'>{langPack('profile.sponsors_users')}</TabsTrigger>
				</TabsList>
				{
					currentTab === 'sponsees' &&
					<div className='flex flex-row items-center justify-end my-4 w-full gap-2 p-2 pr-6'>
						<Label
							htmlFor='show_executed'
							className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
						>
							{langPack('profile.show_lost')}
						</Label>
						<Checkbox checked={showLost} onCheckedChange={toggleShowLost} id='show_executed' />
					</div>
				}
				<TabsContent value='sponsors' className='p-2'>
					<div className='mb-6 grid grid-cols-5 grid-rows-1 gap-4'>
						<div className='col-span-2'>{langPack('default.user')}</div>
						<div className='col-start-3 text-center'>{langPack('default.domain')}</div>
						<div className='col-start-4 text-center'>{langPack('profile.number_week')}</div>
						<div className='col-start-5 text-center'>{langPack('profile.sum_in_week')}</div>
					</div>

					{(result ?? []).map((el: ISponsors) => (
						<div className='grid grid-cols-5 grid-rows-1 gap-4 mb-3 items-center'>
							<div className='col-span-2 w-full text-center'>
								<UserCard user={el.user} />
							</div>
							<div className='col-start-3 text-center'>{el.domain}</div>
							<div className='col-start-4 text-center'>{el.weeks}</div>
							<div className='col-start-5 text-center'>
								<AmountBlock amount={el.amount} />
							</div>
						</div>
					))}
				</TabsContent>
				<TabsContent value='sponsees' className='p-2'>
					<div></div>
					<div className='mb-6 grid w-full grid-cols-[2fr_1fr_1fr_1fr] grid-rows-1 gap-4'>
						<div >{langPack('default.user')}</div>
						<div className='text-center'>{langPack('default.domain')}</div>
						<div
							className='text-center flex flex-row items-center justify-center gap-2 cursor-pointer'
						>
							{langPack('profile.number_week')}
						</div>
						<div
							className='text-center flex flex-row items-center justify-center  gap-2 cursor-pointer'
						>
							{langPack('profile.sum_in_week')}
						</div>
						{hasMultipleWeeks && <div />}
					</div>
					{isLoadingSponsees || isLoadingSponsors ? (
						<div className='mt-8'>
							<Preloader small />
						</div>
					) : (
						(sponses?.filter((el: ISponsors) => showLost ? true : el.weeks > 1) ?? []).map((el: ISponsors) => (
							<div className='grid grid-cols-[2fr_1fr_1fr_1fr] gap-4 mb-3 items-center'>
								<div className='w-full text-center'>
									<UserCard user={el.user} length={30} />
								</div>
								<div className='text-center'>{el.domain}</div>
								<div className='text-center'>{el.weeks > 1 ? el.weeks : el.weeks < 1 ? 'Подписка закончилась' : 'Последний платеж'}</div>
								<div className='flex flex-row items-center justify-center relative'>
									<AmountBlock amount={el.weeks >= 0 ? el.amount : 0} />
									{hasMultipleWeeks && <div className='flex absolute right-0'>
										<SponsorModal 
											profile={el.user}
											getUserFetch={() => {
												getAllSponsees()
												getUserFetch && getUserFetch()
											}}
											edit
											otherDomain={el.domain}
										/>
								</div> }
								</div>
								
							</div>
						))
					)}
				</TabsContent>
			</Tabs>
		</Card>
	)
}

export default Sponsors
