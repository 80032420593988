import { create } from "zustand"

export enum ProposalStatusEnum {
  Pending = "Pending",
  Active = "Active",
  Canceled = "Canceled",
  Defeated = "Defeated",
  Succeeded = "Succeeded",
  Queued = "Queued",
  Expired = "Expired",
  Executed = "Executed",
}

export type GovStatuses = keyof typeof ProposalStatusEnum;

export const GovStatusesMap: Record<number, ProposalStatusEnum> = {
  0: ProposalStatusEnum.Pending,
  1: ProposalStatusEnum.Active,
  2: ProposalStatusEnum.Canceled,
  3: ProposalStatusEnum.Defeated,
  4: ProposalStatusEnum.Succeeded,
  5: ProposalStatusEnum.Queued,
  6: ProposalStatusEnum.Expired,
  7: ProposalStatusEnum.Executed,
};
export interface IGovUnit {
    id: number
    type: string
    proposal_id: string
    proposer: `0x${string}`
    vote_start: number
    vote_end: number
    description: string
    tx_hash: string
    block_number: number
    time_stamp: string
    calls: IGovCall[]
    calldata: string[]
    votes: []
    voted?: boolean
    vote_var?: number
    currentVoteWeight: number
    state: GovStatuses
    quorum: number
    weight: number
    support: number
  }
  
  export interface IGovCall {
    target: string
    data: string
    value: number
  }

interface IGovStore {
    govList: IGovUnit[]
    setGovList: (state: IGovUnit[]) => void
}

export const GovernanceStore = create<IGovStore>(set => ({
    govList: [],
    setGovList: (state: IGovUnit[]) => set({ govList: state }),
}))
