import { SocketEvents } from '@/shared/ws'
import { create } from 'zustand'

export type SocketEventsTypes =
	| 'chat_new'
	| 'chat_delete'
	| 'chat_edit'
	| 'react'
	| 'chat_banned'
	| 'chat_user_banned'
	| 'chat_unbanned'
	| 'chat_user_unbanned'
	| 'fund_contributed'
	| 'fund_prize'
	| 'content_processed'
	| 'nft_removed_from_market'
	| 'nft_added_to_market'
	| 'nft_minted'
	| 'nft_price_changed'
	| 'nft_bought'
	| 'nft_burned'
	| 'nft_transferred'
	| 'token_price_changed'
	| 'banned_user'
	| 'unbanned_user'
	| 'banned_post'
	| 'unbanned_post'
	| 'club_buy'
	| 'nft_auction_bid'
	| 'nft_auction_created'
	| 'nft_auction_canceled'
	| 'nft_auction_ended'
	| 'fundraiser_contribution'
	| 'fundraiser_closed'
	| 'vote_cast'
	| 'proposal_created'

type Messages  = {
    [k in keyof SocketEventsTypes]: SocketEvents
}

interface ISocketStore {
	messages: Messages
	last: SocketEvents
	setMessage: (state: SocketEvents) => void
	dropMessages: (state: SocketEventsTypes) => void
}

export const SocketStore = create<ISocketStore>(set => ({
	messages: {} as Messages,
	last: {} as SocketEvents,
	dropMessages: (type: SocketEventsTypes) => {
		set((state) => ({ messages: {...state.messages, [type]: null} }))
	},
	setMessage: (mes: SocketEvents) => {
		set(state => ({ messages: {...state.messages, [mes.type]: mes.data} }))
		set(() => ({ last: mes}))
	}
}))
