import { cn, contractRead, parceEthAmount, stringToBytes32 } from '@/shared/lib'
import StakeModal from './staking'
import { LangStore } from '@/shared/model/store'
import { ReactNode, useEffect, useState } from 'react'
import { useWallet } from '@/entities/wallet'

type Props = {
    functionName: string
    getData?: () => void
    children?: ReactNode
    className?: string
    text?: string
}

const LowStake = ({ getData, children, functionName, className, text }: Props) => {
  const [minStake, setMinStake] = useState<number>(0)
  const { stakedBalanceUnparced } = useWallet()
  const [loaded, setLoaded] = useState<boolean>(false)
  const { langPack } = LangStore()

  const getMinStake = async() => {
      if(!functionName) return
      const res = Number((await contractRead('Config', 'getUInt', [stringToBytes32(functionName)])) || 0)
      setMinStake(Number(res))
      setLoaded(true)
  }

  const isLow = stakedBalanceUnparced < minStake

  useEffect(() => {
    getMinStake()
  }, [])

  if(!loaded) return

  if(!isLow && children) return children

  if(!isLow) return <></>

  return (
    <div className={cn('flex flex-col gap-2 w-full items-center', className && className)}>
        <div className="flex flex-row w-full text-red-600 text-[12px] justify-center">
            {`${text || langPack('default.need_stake')} ${parceEthAmount(minStake)} SAVVA`}
        </div>
        <StakeModal getData={getData} />
    </div>
  )
}

export default LowStake