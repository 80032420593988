import { cn } from '@/shared/lib'
import { LangStore } from '@/shared/model/store'
import { Button } from '@/shared/ui'
import { ArrowUp } from 'lucide-react'
import { useEffect, useState } from 'react'

type Props = {
	newPosts: string[]
	onClick: () => void
}

const NewPostButton = ({ newPosts, onClick }: Props) => {
	const { langPack } = LangStore()
	const [count, setCount] = useState<number>(0)

	const onClickHandler = () => {
		setCount(0)
		onClick()
	}

	useEffect(() => {
		setCount(newPosts.length)
	}, [newPosts])

	useEffect(() => {
		const wheelHandler = e => {
			if (e.deltaY < 0 && !!newPosts.length) {
				setCount(newPosts.length)
			} else {
				setCount(0)
			}
		}

		window.addEventListener('wheel', wheelHandler)
		return () => {
			window.removeEventListener('wheel', wheelHandler)
		}
	}, [newPosts])

	return (
		<div
			className={cn(
				'fixed -top-[90px] left-[35%] z-0',
				!!count && 'top-[120px] z-[999] transition-all delay-150 duration-300 ease-in-out'
			)}
		>
			<Button className='gap-4' onClick={onClickHandler}>
				{`${langPack('default.new_post')} (${count})`}
				<ArrowUp size={14} />{' '}
			</Button>
		</div>
	)
}

export default NewPostButton
