import { Logo } from '@/features/logo'
import { MobileMenu } from './mibile-menu'
import { DesktopMenu } from './desktop-menu'
import VersionModal from './version-modal'
import { ConfigStore } from '@/shared/model/store'
import useLocalStorage from '@/shared/lib/use-local-storage'

export const Header = () => {
	const { testMode } = ConfigStore()
	useLocalStorage()
	
	return (
		<header className='sticky top-0 z-20 border-b bg-card py-4'>
			<nav className='container flex items-center justify-between'>
				{testMode && <div className='absolute mt-[35px] animate-pulse font-bold'> TEST MODE</div>}
				<Logo />
				<VersionModal />
				<div className='hidden w-[70%] xl:block'>
					<DesktopMenu />
				</div>
				<div className='block xl:hidden'>
					<MobileMenu />
				</div>
			</nav>
		</header>
	)
}
