import { UserCard } from "@/entities/user"
import { IUser } from "@/entities/user/model/types"
import { useWallet } from "@/entities/wallet"
import { contractRead } from "@/shared/lib"
import useRequest from "@/shared/lib/use-request"
import { ConfigStore, LangStore } from "@/shared/model/store"
import { null_address } from "@/shared/model/types"
import { Card, CardDescription, CardTitle, Label } from "@/shared/ui"
import { AmountBlock } from "@/shared/ui/amount-block/amount-block"
import { useEffect, useState } from "react"
import DelegateModal from "./delegate-modal"

const Delegate = () => {
    const { stakedBalanceUnparced, address } = useWallet()
    const { domain } = ConfigStore()
    const { fetchRequest } = useRequest('getUser')
    const [votes, setVotes] = useState<bigint>(0n)
    const [delegates, setDelegates] = useState<IUser | undefined>()
    const { langPack } = LangStore()

    const getVotes = async () => {
        const vts: bigint = await contractRead('Staking', 'getVotes', [address])
        setVotes(vts)
    }

    const getDelegates = async () => {
        const vts: string = await contractRead('Staking', 'delegates', [address])
        
        if(vts !== null_address) {
            const res = await fetchRequest({
                params: {
                  domain: domain,
                  user_addr: vts,
                },
            })
            setDelegates(res)
        } else {
            setDelegates(undefined)
        }
    }
    
    const update = () => {
        getVotes()
        getDelegates()
    }



    useEffect(() => {
        if(!address) return
        update()
    }, [address])

  return (
    <div className="flex flex-row gap-4 w-full">
        <Card className="bg-background w-full p-8 flex flex-col items-start">
        <CardTitle>
            {langPack('governance.delegation_title')}
        </CardTitle>
        <CardDescription>
            {langPack('governance.delegation_description')}
        </CardDescription>
        <div className="mt-5 flex flex-row items-center justify-start gap-8 w-full">
            <div>
                <Label className="text-[grey]">{langPack('staking.stake_balance')}</Label>
                <AmountBlock amount={stakedBalanceUnparced} size={'md'}/>
            </div>
            
            <div>
                {delegates?.address && 
                <div className="flex flex-col gap-3 justify-center">
                    <Label className="text-[grey]">{langPack('governance.deligated_to')}</Label>
                    <UserCard user={delegates} className='h-[27px] w-[27px]'/>
                </div>
                }
            </div>
        </div>
        <div className="flex w-full mt-4">
            <DelegateModal onDelegate={update} delegatedUser={delegates}/>
        </div>
        </Card>
        <Card className="bg-background w-full p-8">
            <CardTitle>
                {langPack('governance.votes_title')}
            </CardTitle>
            <CardDescription>
                {langPack('governance.votes_description')}
            </CardDescription>
            <div className="flex flex-col justify-between w-full items-start">
                <div className="mt-5 flex flex-col items-start">
                    <Label className="text-[grey]">Votes</Label>
                    <AmountBlock amount={votes} size={'md'}/>
                </div>
            </div>
            <div className="flex flex-col gap-2 text-[12px] text-[grey] mt-5">
                {langPack('governance.delegation_info')}
                <ul>
                    <li>{langPack('governance.self_delegate')}</li>
                    <li>{langPack('governance.other_delegate')}</li>
                </ul>
            </div>
        </Card>
    </div>
  )
}

export default Delegate