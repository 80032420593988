import { ConnectButton } from '@rainbow-me/rainbowkit'
import { cn } from '@/shared/lib'
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/ui'
import { useAccount } from 'wagmi'
import { UserStore } from '@/entities/user'
import { AlertCircle } from 'lucide-react'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { useEffect } from 'react'
import useRequest from '@/shared/lib/use-request'
import { getContentFromHash } from '@/widgets/post-editor/lib/utils'

const Connect = ({ openConnectModal, className }: { openConnectModal: () => void; className?: string }) => (
	<Button
		onClick={openConnectModal}
		className={cn('text-md w-full font-bold transition-all duration-300 hover:scale-[1.01]', className)}
	>
		{LangStore.getState().langPack('wallet.connect')}
	</Button>
)

const ChangeNetwork = ({ openChainModal }: { openChainModal: () => void }) => (
	<Button
		onClick={openChainModal}
		className='text-md w-full font-bold transition-all duration-300 hover:scale-[1.01]'
		variant={'destructive'}
	>
		{LangStore.getState().langPack('wallet.wrong')}
	</Button>
)

const AccountModal = ({
	openAccountModal,
	displayName,
	isWrong
}: {
	openAccountModal: () => void
	displayName: string
	isWrong: boolean
}) => {
	const { langPack } = LangStore()
	return (
		<div className='relative flex w-full'>
			<Button
				onClick={openAccountModal}
				type='button'
				variant={'outline'}
				className={cn('flex w-full items-center gap-3', isWrong && 'border-[2px] border-[red]')}
			>
				<div className='flex flex-row items-center gap-4 font-bold'>
					{displayName}
					{isWrong && (
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<AlertCircle color='red' />
								</TooltipTrigger>
								<TooltipContent className='bg-slate-500'>{langPack('wallet.wrong_info')}</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
				</div>
			</Button>
		</div>
	)
}

export const Wallet = ({ className }: { className?: string }) => {
	const { address, isDisconnected } = useAccount()
	const { domain } = ConfigStore()
	const { userData, signatureAddress, setUserData, deleteUser } = UserStore()
	const fetchUser = useRequest('getUser')
	
	useEffect(() => {
		if (address && !signatureAddress) {
			
			const getUser = async () => {
				await fetchUser.fetch({
					params: {
						domain,
						user_addr: address
					},
					onSuccess: async res => {
						const userProfile = await getContentFromHash(res.profile_cid)
						const parced = userProfile ? JSON.parse(userProfile) : {}
						setUserData({ ...res, ...parced })
					}
				})
			}

			getUser()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address, signatureAddress])

	useEffect(() => {
		if(signatureAddress) return
		if(isDisconnected) {
			deleteUser()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDisconnected])

	return (
		<ConnectButton.Custom>
			{({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
				const connected = mounted && account && chain

				return (
					<div
						{...(!mounted && {
							'aria-hidden': true,
							style: {
								opacity: 0,
								pointerEvents: 'none',
								userSelect: 'none',
								width: '100%'
							}
						})}
						className='w-full'
					>
						{(() => {
							switch (true) {
								case !connected:
									return <Connect openConnectModal={openConnectModal} className={className} />
								case chain?.unsupported:
									return <ChangeNetwork openChainModal={openChainModal} />
								default:
									return (
										<AccountModal
											openAccountModal={openAccountModal}
											displayName={account.displayName}
											isWrong={signatureAddress ? address !== userData?.address : false}
										/>
									)
							}
						})()}
					</div>
				)
			}}
		</ConnectButton.Custom>
	)
}
