import { LangStore } from '@/shared/model/store'
import Delegate from './delegate'
import GovernanceList from './governance-list'

export const Governance = () => {
	const { langPack } = LangStore()

	return (
		<div className='container rounded-md border p-6'>
			<h1 className='my-10 scroll-m-20 text-center text-4xl font-extrabold tracking-tight lg:text-5xl'>
				{langPack('governance.title')}
			</h1>
			<Delegate />
			<GovernanceList />
		</div>
	)
}
