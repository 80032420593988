import { IUser } from '@/entities/user/model/types'
import { contractRead, outContractRead, stringToBytes32 } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { useState } from 'react'
import { default_token, ERC20_ABI } from '../model/const'
import { TFundraise } from '@/pages/profile/ui/wallet/fundraise'

export type TTokensFundraise = {
    name: string,
    symbol: string,
    decimals: number,
    address: `0x${string}`
    comment: string
}

export const getPercent = (part: number, total: number) => part / (total / 100)

const viewContractData = async (address: `0x${string}`) => {
    // const { contracts } = ContractsStore()
    const token = {} as TTokensFundraise

    try {
        token.name = await outContractRead(address, ERC20_ABI, 'name')
        token.symbol = await outContractRead(address, ERC20_ABI, 'symbol')
        token.decimals = Number(await outContractRead(address, ERC20_ABI, 'decimals'))
    } catch (e) {
        console.log('Error: view contract data', e)
    }
    return token

}

export const DEFAULT_FUNDRISE = {
    accepteedTokens: [] as TTokensFundraise[],
    fundraise: {} as TFundraise,
    fee: 0
}

const useFundraise = () => {
    const fetchUser = useRequest<IUser>('getUser')
    const [state, setState] = useState(DEFAULT_FUNDRISE)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getFundRiseData = async (id: number) => {
        if(!id) return 
        setIsLoading(true)
        const computed = {...state}

        computed.fee = Number(await contractRead('Config', 'getUInt', [stringToBytes32('fundraising_bb_fee')]) || 0)
        
        const tokens: `0x${string}`[] = await contractRead('Fundraiser', 'getAcceptedTokens', [])
        const contracts: TTokensFundraise[] = [default_token]

        for(let i = 0; i < tokens.length; i++) {
          const a = await viewContractData(tokens[i])
          contracts.push({...a, address: tokens[i]})
        }

        computed.accepteedTokens = contracts
        
        const res = await contractRead('Fundraiser', 'campaigns', [id])

        const user = await fetchUser.fetchRequest({
            params: {
                user_addr: res[1]
            }
        })

        computed.fundraise = {
            user,
            target_amount: Number(res[2]),
            raised: Number(res[3]),
            title: res[0],
            id,
        }
        setState(computed)
        setIsLoading(false)
        return computed.fundraise
    }

    const drop = () => setState(DEFAULT_FUNDRISE)

  return {
    getFundRiseData,
    percentage: getPercent(state.fundraise?.raised, state.fundraise?.target_amount),
    currentP: getPercent(state.fundraise?.target_amount, state.fundraise?.raised),
    isBiggest: state.fundraise?.raised > state.fundraise?.target_amount,
    isLoading,
    drop,
    ...state
  }
}

export default useFundraise