import { FundBox } from '@/features/fund-block/ui/fund-box'
import FundRiseBlock from '@/features/fundraise-block/ui/fundraise-block'
import { NFTAuctionBlock } from '@/features/nft-block/ui/auction/auction-block'
import { NftBlock } from '@/features/nft-block/ui/nft-block'

export const ContentSidebar = () => {
  return (
    <div className='flex flex-col w-full'>
        <FundRiseBlock />
        <NftBlock />
        <NFTAuctionBlock />
        <FundBox />
    </div>
  )
}
