import { ROUTER } from '@/shared/router'
import { Badge, Button } from '@/shared/ui'
import { Link } from 'react-router-dom'
import { UserStore, useSponsorsSumm } from '@/entities/user'
import { Landmark, NotebookPen } from 'lucide-react'
import { SavvaIcon } from '@/shared/assets'
import { Chat } from '@/widgets/chat'
import { cn } from '@/shared/lib'
import useGovernance from '@/widgets/governance/lib/hooks/use-governance'
import { useEffect, useState } from 'react'
import { IGovUnit } from '@/widgets/governance/models/governance-store'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import useBlocknumber from '@/shared/lib/use-blocknumber'

export const ActionMenu = ({ isMobile = false }) => {
	const [activeProposals, setActiveProposals] = useState<IGovUnit[]>([])

	const { signatureAddress, userData } = UserStore()
	const { asyncGetGoveranceList } = useGovernance()
	const { sponsorsSumm } = useSponsorsSumm()
	const { message } = useSubscribe('proposal_created')
	const { blockNumber } = useBlocknumber()
	const [bn, setBn] = useState<number | null>(null)

	const getAP = async (blockNumber?) => {
		const res = await asyncGetGoveranceList(true, blockNumber)
		setActiveProposals(res.filter(el => !el.voted))
	}

	useEffect(() => {
		if (!bn && blockNumber) {
			setBn(blockNumber)
		}
	}, [blockNumber])

	useEffect(() => {
		if (!bn) return
		getAP(bn)
	}, [bn, userData?.address])

	useEffect(() => {
		if (!message) return
		getAP(blockNumber)
	}, [message])

	return (
		<div className={cn('flex items-center gap-2', isMobile && 'justify-between')}>
			{signatureAddress && (
				<Link to={ROUTER.newPost}>
					<Button variant='outline' className='relative' size='icon'>
						<NotebookPen />
						{!!sponsorsSumm && (
							<Badge className='absolute end-[-14px] top-[-15px] flex h-6 w-max justify-center bg-transparent p-0 hover:bg-transparent'>
								<SavvaIcon />
							</Badge>
						)}
					</Button>
				</Link>
			)}

			{/* <LangToggle /> */}

			<Button variant='outline' size='icon' className='relative'>
				<Link to={ROUTER.governance}>
					<Landmark />
				</Link>
				{activeProposals?.length > 0 && (
					<Badge className='absolute -left-4 -top-2 flex h-6 w-6 justify-center'>{activeProposals?.length}</Badge>
				)}
			</Button>
			{signatureAddress && <Chat userAddress={signatureAddress} />}
		</div>
	)
}
