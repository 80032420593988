import { LangStore } from '../model/store'

type Props = {
    text?: string
}

const LoadingText = ({text }: Props) => {
    const { langPack } = LangStore()
  return (
    <div className='text-[grey] text-[12px]'>
        {text || langPack('action.loading')}
        <span className="loading-dot">.</span>
        <span className="loading-dot">.</span>
        <span className="loading-dot">.</span>
    </div>
  )
}

export default LoadingText