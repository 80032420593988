import { UserStore } from "@/entities/user"
import { ConfigStore, LangStore } from "@/shared/model/store"
import { formSchema } from "@/widgets/post-editor/model/model";
import { ContentBodyType } from "@/widgets/post-editor/model/types"
import { UseFormReturn } from "react-hook-form";
import yaml from 'yaml'
import { z } from "zod";

export const createCommentPreview = (data: string, len = 512) => (data?.length > 200 ? `${data.slice(0, len)}...` : data);

export const spec_data = {
    savva_spec_version: '2.0',
    mime_type: 'text/markdown',
    encoding: 'utf-8',
    license: 'CC0',
}
export const createCommentBody =(state, isEdit, value, form?: UseFormReturn<z.infer<typeof formSchema>>) => {
    const author = UserStore.getState().signatureAddress as `0x${string}`
    const domain = ConfigStore.getState().domain

    const locales = form?.getValues()?.locales || null

    const formData = new FormData()
    const {
        savva_cid,
        savva_content: { root_savva_cid }
    } = state
    const rd = {
        parent_savva_cid: isEdit ? state.savva_content.parent_savva_cid : savva_cid,
        root_savva_cid: root_savva_cid ? root_savva_cid : savva_cid
    }
    const valueToLocales = {}
    const transformedLocales = {}

    for(const k in value) {
        valueToLocales[k] = {
            title: '',
            data: value[k],
            tags: [],
            categories: [],
            chapters: [],
        }
    }

    if(locales) {
        for (const [key, locale] of Object.entries(locales)) {
            if (!locale.data) continue
            transformedLocales[key] = {
                text_preview: createCommentPreview(locale.data),
                data_path: locale.data ? `${key}/data.md` : '',
            }
            const data = new File([locale.data], `${key}/data.md`)
            formData.append('file', data, `/content/${key}/data.md`)
        }
    } else {
        for (const [key, locale] of Object.entries(valueToLocales)) {
            if (!locale.data) continue
            transformedLocales[key] = {
                text_preview: createCommentPreview(locale.data),
                data_path: locale.data ? `${key}/data.md` : '',
            }
            const data = new File([locale.data], `${key}/data.md`)
            formData.append('file', data, `/content/${key}/data.md`)
        }
    }
            
    
    const mainBody: ContentBodyType = {
        ...spec_data,
        author,
        thumbnail: '',
        locales: transformedLocales,
        ...rd
    }

    const yml = yaml.stringify(mainBody)
    const blob = new Blob([yml], { type: 'text/markdown' })
    const info = new File([blob], `/content/info.yaml`)

    formData.append('file', info, `/content/info.yaml`)
    formData.append('domain', domain)
    
    return formData
}