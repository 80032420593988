import useRequest from '@/shared/lib/use-request'
import { GovernanceStore } from '../../models/governance-store'
import { UserStore } from '@/entities/user'
import useBlocknumber from '@/shared/lib/use-blocknumber'

const useGovernance = () => {
	const { userData } = UserStore()
	const { govList, setGovList } = GovernanceStore()
	const { blockNumber } = useBlocknumber()

	const { fetch, fetchRequest } = useRequest('getProposals')
	const getGoveranceList = (active = true) => {
		fetch({
			params: {
				active,
				...(userData?.address && { caller: userData?.address }),
				current_block: blockNumber
			},
			onSuccess: setGovList
		})
	}

	const asyncGetGoveranceList = async (active = true, current_block?) => {
		return await fetchRequest({
			params: {
				active,
				...(userData?.address && { caller: userData?.address }),
				current_block
			}
		})
	}

	return {
		getGoveranceList,
		asyncGetGoveranceList,
		govList
	}
}

export default useGovernance
