import { UserStore } from '@/entities/user'
import useNotifier from '../../../shared/lib/use-notifier'
import { stringToBytes32 } from '../../../shared/lib/web3utils'
import { ConfigStore } from '../../../shared/model/store'
import { ContractsStore } from '../../../shared/model/store/contracts-store'

const useRemoveContent = onSuccess => {
	const { userData } = UserStore()
	const { domain } = ConfigStore()
	const { contracts } = ContractsStore()

	const { writeContract, isLoading } = useNotifier({
		onSuccess: () => {
			onSuccess()
		}
	})

	const removeContent = async (guid: string, type: 'comment' | 'post') => {
		writeContract({
			address: contracts.ContentRegistry.address,
			abi: contracts.ContentRegistry.abi,
			functionName: 'reg',
			args: [domain, userData?.address, guid, '', stringToBytes32(`${type}-edit`)]
		})
	}

	return {
		removeContent,
		isLoading
	}
}

export default useRemoveContent
