import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, Label } from '@/shared/ui'
import { DEFAULT_PREC, getSplitTransaction, parseAmount } from '@/shared/lib'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { useWallet } from '@/entities/wallet'
import { useState } from 'react'

type Props = {
    unStake?: boolean;
    getData?: () => void;
  };


const StakeModal = ({getData, unStake} : Props) => {
	const { savvaBalanceUnparced, stakedBalanceUnparced} = useWallet()
    const [quantity, setQuantity] = useState<number>()
    const [open, setOpen] = useState<boolean>(false)
    const { contracts } = ContractsStore()
    const { langPack} = LangStore()

    const { writeContract: stakingWrite, isLoading: isLoadingContract } = useNotifier({
        onSuccess() {
            getData && getData();
            setOpen(false);
        },
    })

	const { writeContract: stakingWithPermit, isLoading: isLoadingStake } = useNotifier({
		onSuccess: () => {
		getData && getData()
		setOpen(false);
		}
	})

    const unstakeHandler = () => {
      stakingWrite({
        address: contracts.Staking.address,
        abi: contracts.Staking.abi,
        functionName: 'unstake',
        args: [parseAmount(quantity || 0)]
      })
    }
  
    const hanbleSubmit = async () => {
      if(unStake) {
        unstakeHandler()
        return
      }

      const {message, v, r, s} = await getSplitTransaction({
        token: "SAVVA",
        spender: contracts!.Staking.address,
        amount: quantity,
      })
  
      stakingWithPermit({
        address: contracts.Staking.address,
        abi: contracts.Staking.abi,
        functionName: 'stakeWithPermit',
        args: [
          message.value,
          message.deadline,
          v,
          r,
          s,
        ]
      })
    }

    const closeHandle = (state) => {
        if(!state) {
            setQuantity(undefined)
        }
        setOpen(state)
    }

    const setQuaintyHandler = (value: number) => {
      if (value > Number(stakedBalanceUnparced) / DEFAULT_PREC) {
        setQuantity(Number(stakedBalanceUnparced) / DEFAULT_PREC)
      } else {
        setQuantity(value)
      }
    }

    const disable = isLoadingContract || isLoadingStake
    
  return (
    <Dialog onOpenChange={closeHandle} open={open}>
      <DialogTrigger asChild>
        <Button
			className="w-full z-50 text-[white]"
			variant='orange'
			>
				{unStake ? langPack('staking.decrease') : langPack('staking.increase')}
		</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>{!unStake ? langPack('staking.increase') : langPack('staking.decrease')}</DialogTitle>
        </DialogHeader>
        <AmountInput
          value={quantity}
          onChange={setQuaintyHandler}
          placeholder={`Enter value`}
          disabled={disable}
          label={langPack('fund.quantity')}
        />
        <div className="grid grid-cols-4 items-center gap-4 relative">
            <Label className="text-right">{langPack('default.balance')}</Label>
            <div><AmountBlock amount={savvaBalanceUnparced} size='sm' /></div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4 relative">
            <Label className="text-right">{langPack('staking.stake_balance')}</Label>
            <div><AmountBlock amount={stakedBalanceUnparced} size='sm' /></div>
        </div>
        <DialogFooter>
          <Button onClick={hanbleSubmit} type="submit"  className="text-[white]" variant='orange' disabled={disable} loading={disable}>
            {unStake ? langPack('profile.wallet_withdraw') : langPack('profile.wallet_compound')}
        </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default StakeModal