import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from './select'

type TOption = {
    value: string
    label: string
}

export type SelectInputProps = {
    onChange: (value: string) => void
    value: string | undefined
    defaultValue?: string | undefined
    options?: TOption[]
    disabled?: boolean
}

const SelectComponent = ({value, onChange, defaultValue, options, disabled}: SelectInputProps) => {
  return ( 
    <Select onValueChange={onChange} defaultValue={defaultValue} value={value} disabled={disabled}>
    <SelectTrigger>
        <SelectValue />
    </SelectTrigger>
    <SelectContent>
        <SelectGroup>
            {(options || []).map((el: TOption) => (
                <SelectItem key={el.value} value={el.value}>
                    {el.label}
                </SelectItem>
            ))}
        </SelectGroup>
    </SelectContent>
</Select>
  )
}

export default SelectComponent