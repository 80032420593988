import { Navigation } from '@/features/navigation'
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui'
import { UserCard, UserStore } from '@/entities/user'
import { ActionMenu } from './action-menu'
import { Wallet } from '@/entities/wallet'
import { Usermenu } from './user-menu'
import { ChevronDown } from 'lucide-react'
import Search from '@/widgets/search/ui/search'

export const DesktopMenu = () => {
	const { userData } = UserStore()

	return (
		<div className='flex w-full items-center justify-between'>
			<Navigation />
			<div className='flex items-center gap-2'>
				<Search />
				<ActionMenu />
				{userData?.address && (
					<Popover>
						<PopoverTrigger className='relative'>
							<UserCard user={userData} onlyAvatar forTrigger />
							<div className='absolute bottom-0 right-0 rounded-full bg-background'>
								<ChevronDown size={12} />
							</div>
						</PopoverTrigger>
						<PopoverContent>
							<Usermenu />
						</PopoverContent>
					</Popover>
				)}
				<Wallet />
			</div>
		</div>
	)
}
