import { ContentStore } from '@/entities/feed-block/model/content-store'
import { ContentType } from '@/entities/feed-block/model/types'
import { fixLengthPreview, getCurrentFullComment, getCurrentTitles } from '@/entities/feed-block/model/utils'
import { UserCard, UserStore } from '@/entities/user'
import { Media } from '@/features/md-editor/ui/media'
import ReactionsPreview from '@/features/reaction-preview/ui/reaction-preview'
import { cn, sortDesc, useTheme } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { Card, TimeAgo, CardContent } from '@/shared/ui'
import { UserName } from '@/shared/ui/user-name/ui/user-name'
import { getContentFromIpfs } from '@/widgets/post/lib/get-content-from-yaml'
import { createCommentPreview } from '@/widgets/post/lib/utils'
import { ButtonLike } from '@/widgets/post/ui/button-like'
import { SimpleComment } from '@/widgets/post/ui/simple-сomment'
import MarkDownPreview from '@uiw/react-markdown-preview'
import { FileSymlink, MessageCircle, MessageCirclePlus, MessageCircleX, Pencil, RefreshCw, Reply } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import remarkBreaks from 'remark-breaks'

type Props = {
	content: ContentType
	level: number
	isPost?: boolean
	disableButtons?: boolean
}

export const CommentCard = ({ content, level, isPost, disableButtons }: Props) => {
	const { commentList, setCommentList } = ContentStore()
	const { appInfo } = AppInfoStore()
	const { domain, baseUrl } = ConfigStore()
	const { signatureAddress } = UserStore()
	const { langPack } = LangStore()

	const { fetch, isLoading, dropState } = useRequest('getContentChildrensList')
	const { message } = useSubscribe('react')
	const { theme } = useTheme()

	const [parsed, setParsed] = useState<any>(null)
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isEdit, setIsEdit] = useState<boolean>(false)

	const ch: ContentType[] = commentList.filter(
		(el: ContentType) => el.savva_content.parent_savva_cid === content.savva_cid
	)
	const childrens = ch.sort(sortDesc)
	const fullData = parsed ? createCommentPreview(getCurrentFullComment(parsed), 2048) : ''

	const getUserYaml = async () => {
		try {
			const data = await getContentFromIpfs(`${baseUrl}ipfs/${content?.ipfs}`)
			setParsed(data)
		} catch (error) {
			throw new Error(`COMMENT ERROR: ${error}`)
		}
	}

	useEffect(() => {
		if (!content?.ipfs) return
		getUserYaml()
	}, [content])

	const closeHandler = () => {
		setIsOpen(false)
		setIsEdit(false)
	}

	const addCommentHandler = (isEdit = false) => {
		console.log('OPEN')
		setIsOpen(true)
		setIsEdit(isEdit)
	}

	const showMore =
		commentList.filter((el: ContentType) => el.savva_content.parent_savva_cid === content.savva_cid).length < 1 &&
		content.total_childs > 0

	const loadChildrens = () => {
		if (isLoading) return
		if (!showMore) {
			const filtred = commentList.filter((el: ContentType) => el.savva_content.parent_savva_cid !== content.savva_cid)
			setCommentList(filtred)
			dropState()
			return
		}

		fetch({
			params: {
				domain: domain,
				savva_cid: content.savva_cid,
				max_deep: 1,
				my_addr: signatureAddress,
				limit: 1000
			},
			onSuccess(res) {
				const filtred = res.list.filter((el: ContentType) => el.savva_cid !== content.savva_cid)
				setCommentList([...commentList, ...filtred])
			}
		})
	}

	useEffect(() => {
		if (!message) return
		if (message.object_id === content.savva_cid) {
			const lst = [...commentList]
			const index = lst.findIndex((el: ContentType) => el.savva_cid === content.savva_cid)

			if (index > -1) {
				lst[index] = {
					...lst[index],
					reactions: message.data.reactions,
					...(message.user.address === signatureAddress && { my_reaction: message.data.my_reaction })
				}

				setCommentList(lst)
			}
		}
	}, [message])

	return (
		<Card className={cn('mb-4 w-full rounded-md border-none p-0 shadow-none', level < 1 && 'bg-card')}>
			<CardContent className='relative mt-2 flex flex-row items-start gap-3 p-0'>
				<div className='flex justify-between pl-4 pt-2'>
					<UserCard user={content.author} descriprion={<TimeAgo date={content.timestamp} />} onlyAvatar />
				</div>
				<div className={cn('relative flex w-full flex-col rounded-2xl bg-transparent px-4 pb-5 pt-2')}>
					<div className='flex flex-row items-center justify-between'>
						<div className='flex flex-row items-center gap-2'>
							<Link to={`/${content.author.address}`} className='cursor-pointer'>
								<UserName account={content.author} sliceLen={level > 0 ? 60 : 30} />
							</Link>
							{level < 1 && !isPost && (
								<>
									<div className='text-[12px] text-[grey]'>{langPack('post.comment_for')}</div>
									<Link
										to={`/content/${content.savva_content.root_savva_cid}`}
										className='text-[12px] font-bold uppercase text-[grey]'
									>
										{fixLengthPreview(getCurrentTitles(content.savva_content.root_post?.locales, 'title'), 50)}
									</Link>
								</>
							)}
						</div>
						<TimeAgo date={content.timestamp} />
					</div>
					<MarkDownPreview
						source={fullData}
						remarkPlugins={[remarkBreaks]}
						style={{ backgroundColor: 'inherit', marginTop: 8 }}
						urlTransform={url => {
							return url.includes('http') ? url : `${appInfo.ipfs_gateways[0]}${content.ipfs}/${url}`
						}}
						wrapperElement={{
							'data-color-mode': theme
						}}
						components={{
							img: Media
						}}
					/>
					<div className='mt-5 flex w-full flex-row items-center gap-4'>
						<div className='flex flex-row items-center gap-1 text-[grey]'>
							<MessageCircle color='grey' size={15} />
							<span className='text-[12px]'>{content.total_childs}</span>
						</div>
						{!disableButtons && (
							<>
								{level < 6 ? (
									<div className='flex cursor-pointer flex-row items-center gap-1'>
										{!isOpen ? (
											<div className='flex flex-row items-center gap-2' onClick={() => addCommentHandler(false)}>
												<Reply color='grey' size={15} />
												<span className='text-[12px] text-[grey]'>{langPack('action.replay')}</span>
											</div>
										) : (
											<div className='flex flex-row items-center gap-2' onClick={closeHandler}>
												<Reply size={15} color='grey' />
												<span className='text-[12px] text-[grey]'>{langPack('action.cancel')}</span>
											</div>
										)}
									</div>
								) : (
									<Link to='' className='flex cursor-pointer flex-row items-center gap-1'>
										<FileSymlink color='grey' size={15} />
										<span className='text-[12px] text-[grey]'>{langPack('comment.go_post')}</span>
									</Link>
								)}
							</>
						)}
						{content.author.address === signatureAddress && !disableButtons && (
							<div className='flex cursor-pointer flex-row items-center gap-1' onClick={() => addCommentHandler(true)}>
								<Pencil color='grey' size={12} />
								<span className='text-[12px] text-[grey]'>{langPack('action.edit')}</span>
							</div>
						)}
						{level > 0 && content.total_childs > 0 && (
							<div className='flex cursor-pointer flex-row items-center gap-1' onClick={loadChildrens}>
								{isLoading ? (
									<>
										<RefreshCw className='mr-2 h-4 w-4 animate-spin' />
										<span className='text-[12px] text-[grey]'>{langPack('action.loading')}</span>
									</>
								) : (
									<>
										{!showMore ? (
											<MessageCircleX color='grey' size={15} />
										) : (
											<MessageCirclePlus color='grey' size={15} />
										)}
										<span className='text-[12px] text-[grey]'>
											{!showMore ? langPack('comment.less_answers') : langPack('comment.more_answers')}
										</span>
									</>
								)}
							</div>
						)}
						<div className='flex'>{signatureAddress && <ButtonLike activePost={content} ghost />}</div>
						<div className='ml-auto flex flex-row items-center'>
							<div className='mt-[2px] pl-6'>
								<ReactionsPreview reactions={content.reactions} />
							</div>
						</div>
					</div>
					{isOpen && level < 6 && (
						<div className='mt-5 flex w-full flex-col'>
							<SimpleComment state={content} isEdit={isEdit} drop={closeHandler} />
						</div>
					)}
					{childrens.length > 0 && (
						<div className='mt-4 border-l-4 pt-4'>
							{childrens.map((el: ContentType) => (
								<CommentCard content={el} level={level + 1} key={el.guid} />
							))}
						</div>
					)}
				</div>
			</CardContent>
		</Card>
	)
}
