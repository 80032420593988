import { MdEditor } from '@/features/md-editor'
import { LangStore } from '@/shared/model/store'
import {
	Button,
	Checkbox,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	MultiSelect,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '@/shared/ui'
import { useEffect } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { formSchema, transformCategories } from '../model/model'
import { cn } from '@/shared/lib'
import { Trash2, XIcon } from 'lucide-react'
import useRequest from '@/shared/lib/use-request'
import { GuardButton } from '@/features/guard-button'
import { createChapters, getNameData } from '../lib/utils'
import { useCheckStaking } from '../lib/hook/use-check-staking'
import { UserStore } from '@/entities/user'
import { FundraiseCard, TFundraise } from '@/pages/profile/ui/wallet/fundraise'
import FundraiseModal from './fundrise-modal'

type Props = {
	contentLang: string
	setContentLang: React.Dispatch<React.SetStateAction<string>>
	form: UseFormReturn<z.infer<typeof formSchema>>
	onSubmit: (values: z.infer<typeof formSchema>) => void
	pathToFolder: string
	isLoading: boolean
	contentType: 'post' | 'comment' | 'post-edit' | 'comment-edit'
	fundraise?: TFundraise
	setFundrise?: (data: TFundraise) => void
}

export const ContentForm: React.FC<Props> = ({
	setContentLang,
	contentLang,
	form,
	onSubmit,
	pathToFolder,
	isLoading,
	contentType,
	fundraise,
	setFundrise
}) => {
	const { langPack } = LangStore()
	const { getValues, setValue, reset, formState } = form
	const { signatureAddress } = UserStore()
	useWatch({ name: `locales.${contentLang}.chapters`, control: form.control })
	useWatch({ name: `locales.${contentLang}.currentChapter`, control: form.control })
	const { isLowStake } = useCheckStaking({ address: signatureAddress as `0x${string}` })

	const { isComment, locales } = getValues()
	const { chapters, currentChapter } = locales[contentLang]

	const { fetch: deleteFile } = useRequest('deleteFile')

	const addChapter = () => {
		const newChapters = createChapters(chapters)
		setValue(`locales.${contentLang}.chapters`, newChapters)

		setValue(`locales.${contentLang}.currentChapter`, newChapters[newChapters.length - 1].id)
	}

	const removeChapter = (id: string) => {
		const chapter = chapters.length === 2 ? [] : chapters.filter(el => el.id !== id)
		setValue(`locales.${contentLang}.chapters`, chapter)

		if (currentChapter === id || !chapter.length) {
			setValue(`locales.${contentLang}.currentChapter`, '')
		}
	}

	const onValueChange = (value: string) => {
		if (!value) return
		setValue(`locales.${contentLang}.currentChapter`, value)
	}

	const onRemoveForm = async () => {
		reset()
		await deleteFile({ params: { path: pathToFolder } })
	}

	useEffect(() => {
		if (!!chapters?.length && !currentChapter) {
			setValue(`locales.${contentLang}.currentChapter`, chapters[0].id)
		}
		console.log('getValues()', getValues())
	}, [])

	useEffect(() => {
		if (formState?.errors?.locales) {
			const key = Object.keys(formState.errors.locales)[0]
			setContentLang(key)
			const chapter = Object.values(formState.errors.locales)[0]?.chapters

			if (chapter) {
				const idx = Object.keys(chapter)[0]

				setValue(`locales.${key}.currentChapter`, locales[key].chapters[idx].id)
			} else {
				setContentLang(Object.keys(formState.errors.locales)[0])
				// setValue(`locales.${key}.currentChapter`, locales[key].chapters[0].id)
			}
		}
	}, [formState?.errors])

	return (
		<form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col space-y-8' key={contentLang}>
			{!isComment && (
				<FormField
					control={form.control}
					name={`locales.${contentLang}.title`}
					render={({ field }) => (
						<FormItem className='w-full'>
							<FormLabel>{langPack('editor.title')}</FormLabel>
							<div className='flex items-center gap-3'>
								<FormControl>
									<Input {...field} />
								</FormControl>
								<Button type='button' onClick={addChapter} className={cn(isComment && 'hidden')}>
									{langPack('editor.add_chapter')}
								</Button>
							</div>
							<FormMessage />
						</FormItem>
					)}
				/>
			)}

			{!!chapters?.length && (
				<div className='flex gap-2'>
					<Select onValueChange={onValueChange} value={currentChapter}>
						<SelectTrigger>
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							{chapters?.map((item, idx) => (
								<div key={item.id} className='flex items-center justify-between'>
									<SelectItem value={item.id}>{`${idx !== 0 ? idx : ''} ${item.title}`}</SelectItem>
									<Button
										className={cn(idx === 0 && 'hidden')}
										type='button'
										size='icon'
										variant={'ghost'}
										onClick={() => removeChapter(item.id)}
									>
										<Trash2 />
									</Button>
								</div>
							))}
						</SelectContent>
					</Select>
					<Button onClick={() => setValue(`locales.${contentLang}.chapters`, [])}>
						<Trash2 />
					</Button>
				</div>
			)}

			{!!chapters?.length &&
				chapters.map(
					(item, idx) =>
						currentChapter === item.id &&
						idx !== 0 && (
							<FormField
								key={item.id}
								control={form.control}
								name={`locales.${contentLang}.chapters.${idx}.title`}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{langPack('editor.chapter')}</FormLabel>
										<FormControl>
											<Input {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						)
				)}

			<FormField
				key={getNameData(chapters, currentChapter)}
				control={form.control}
				name={`locales.${contentLang}.${getNameData(chapters, currentChapter)}`}
				render={({ field }) => {
					return (
						<FormItem>
							<FormLabel>{langPack('editor.text')}</FormLabel>
							<FormControl>
								<MdEditor pathToFolder={pathToFolder} value={field.value} onChange={field.onChange} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)
				}}
			/>

			{!isComment && (
				<FormField
					control={form.control}
					name={`locales.${contentLang}.categories`}
					render={({ field }) => (
						<FormItem className='relative'>
							<FormLabel>{langPack('editor.categories')}</FormLabel>
							<MultiSelect
								{...field}
								defaultOptions={transformCategories(contentLang)}
								maxSelected={1}
								hidePlaceholderWhenSelected
								hideClearAllButton
							/>
							<FormMessage />
						</FormItem>
					)}
				/>
			)}

			{!isComment && (
				<FormField
					control={form.control}
					name={`locales.${contentLang}.tags`}
					render={({ field }) => (
						<FormItem>
							<FormLabel>{langPack('editor.tags_title')}</FormLabel>
							<FormControl>
								<MultiSelect
									{...field}
									maxSelected={8}
									hidePlaceholderWhenSelected
									inputProps={{ maxLength: 32 }}
									creatable
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			)}

			{!isComment && fundraise && setFundrise && !fundraise.id && (
				<FundraiseModal onSelect={setFundrise} selected={fundraise} />
			)}

			{/* <FormField
				control={form.control}
				name={'fundraiser'}
				render={({ field }) => (
					<FormItem>
						<FormControl>
							<div className='flex items-center space-x-2'>
								<Checkbox checked={field.value} onCheckedChange={field.onChange} />
								<FormLabel className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
									{langPack('fundraise.fundraise')}
								</FormLabel>
							</div>
						</FormControl>
					</FormItem>
				)}
			/> */}

			{fundraise && setFundrise && fundraise.id && (
				<div className='flex w-full flex-col'>
					<FormLabel>{langPack('fundraise.fundraise')}</FormLabel>
					<div className='relative mt-3'>
						<FundraiseCard el={fundraise} />
						<Button
							className='absolute right-14 top-[9px] z-50'
							onClick={() => setFundrise({} as TFundraise)}
							size={'icon'}
						>
							<XIcon />
						</Button>
					</div>
				</div>
			)}

			{!isComment && pathToFolder !== 'new-post' && (
				<FormField
					control={form.control}
					name={'isRegisterNewPost'}
					render={({ field }) => (
						<FormItem>
							<FormControl>
								<div className='flex items-center space-x-2'>
									<Checkbox checked={field.value} onCheckedChange={field.onChange} />
									<FormLabel className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
										{langPack('editor.register_new_post')}
									</FormLabel>
								</div>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			)}

			{!isComment && (
				<FormField
					control={form.control}
					name={'isNsfw'}
					render={({ field }) => (
						<FormItem>
							<FormControl>
								<div className='flex items-center space-x-2'>
									<Checkbox checked={field.value} onCheckedChange={field.onChange} />
									<FormLabel className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
										{langPack('default.advisory')}
									</FormLabel>
								</div>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			)}
			<div className='flex flex-row gap-4'>
				<GuardButton
					type='submit'
					title={langPack(`action.${contentType === 'comment' || contentType === 'post' ? 'create' : 'update'}`)}
					requireAuth
					disabled={isLowStake || isLoading}
					isLoading={isLoading}
				/>
				<Button type='button' onClick={onRemoveForm} disabled={isLoading}>
					{langPack('action.clear')}
				</Button>
			</div>
		</form>
	)
}
