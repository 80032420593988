import { AbiFunction } from 'viem';
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type ContractsType = {
	abi_cid: string;
	address: `0x${string}`;
	abi: AbiFunction[];
}

export type ABIsType = {
	[key in ContracNames]: ContractsType
}

export type ContracNames = keyof SavvaContracts

export type ITokenPriceTokens = {
	[key: string]:  {
		price: number
		gain: number
	}
} 

export interface ITokenPrice {
	base_token_price: number
	base_token_gain: number
	savva_token_price: number
	savva_token_gain: number
	tokens: ITokenPriceTokens
}
  

export type SavvaContracts = {
	Config: ContractsType;
	SavvaToken: ContractsType;
	ContentNFT: ContractsType;
	ContentRegistry: ContractsType;
	Staking: ContractsType;
	ContentFund: ContractsType;
	NFTMarketplace: ContractsType;
	SavvaFaucet: ContractsType;
	UserProfile: ContractsType;
	RandomOracle: ContractsType;
	Governance: ContractsType;
	Promo: ContractsType;
	ListMarket: ContractsType;
	AuthorsClubs: ContractsType;
	NFTAuction: ContractsType;
	Fundraiser: ContractsType;
}

interface IContractsStore {
	contracts: SavvaContracts
	tokenPrice: ITokenPrice
	setAbi: (state: ABIsType) => void
	setTokenPrice: (state: ITokenPrice) => void
}

export const ContractsStore = create<IContractsStore>()(
	persist(
		(set) => ({
			contracts: {} as SavvaContracts,
			tokenPrice: {} as ITokenPrice,
			setTokenPrice: state => set({ tokenPrice: state }),
			setAbi: state => set({ contracts: state }),
		}),
		{
			name: 'abi',
			partialize: state => ({ contracts: state.contracts })
		}
	)
)