import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, TelegramShareButton, XIcon } from 'react-share';
import { FacebookIcon, WhatsappIcon, TelegramIcon } from 'react-share';
import { Button } from '../button';
import { Link } from 'lucide-react';
import useCopyClipboard from '@/shared/lib/useCopyClipboard';

interface Props {
    shareUrl: string
    shareText?: string
    label?: string
}

const SocialShare = ({shareUrl, shareText, label}: Props) => {
    const { copy } = useCopyClipboard()
    
  return (
    <div className="social-share">
      {label && <h2>Share this content:</h2>}
      <div className='flex flex-row gap-2'>
        <TelegramShareButton url={shareUrl} title={shareText}>
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <WhatsappShareButton url={shareUrl} title={shareText}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <TwitterShareButton url={shareUrl} title={shareText}>
            <XIcon size={32} round />
        </TwitterShareButton>
        <FacebookShareButton url={shareUrl} quote={shareText}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <Button variant='outline' size='icon' className="h-8 w-8 rounded-full" onClick={() => copy(shareUrl)}>
            <Link size={14}/>
        </Button>
      </div>
    </div>
  );
};

export default SocialShare;