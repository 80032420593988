import { useEffect, useState } from 'react'
import { IPFSProfileType } from '../profile'
import {
	Button,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Label
} from '@/shared/ui'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { contractRead, DEFAULT_PREC, getSplitTransaction, parseAmount, stringToBytes32 } from '@/shared/lib'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import useNotifier from '@/shared/lib/use-notifier'
import StakeModal from '../wallet/modals/stake-modal'
import { GuardButton } from '@/features/guard-button'
import { useWallet } from '@/entities/wallet'
import { Edit } from 'lucide-react'
import { IUser } from '@/entities/user/model/types'
import { UserStore } from '@/entities/user'


export interface ISponsorModal {
	profile: IUser & IPFSProfileType
	getUserFetch?: () => void
	other?: boolean
	otherDomain?: string
	edit?: boolean
}

const SponsorModal = ({ profile, getUserFetch, other = false, otherDomain, edit }: ISponsorModal) => {
	const { langPack } = LangStore()
	const { contracts } = ContractsStore()
	const { userData } = UserStore()
	const { domain: configDomain } = ConfigStore()
	const [quantity, setQuantity] = useState<number>()
	const [weeks, setWeeks] = useState<number>(1)
	const [open, setOpen] = useState<boolean>(false)
	const [isLoadingModal, setIsloadingModal] = useState<boolean>(false)
	const {stakedBalance} = useWallet()
	const domain = otherDomain || configDomain

	const closeHandle = (state: boolean) => {
		if (!state) {
			setQuantity(0)
			setWeeks(1)
		}
		setOpen(state)
	}

	const setQuaintyHandler = (value: number) => {
		setQuantity(value)
	}

	const setWeeksHandler = (value: number) => {
		if (value > 52) {
			setWeeks(52)
		} else {
			setWeeks(value)
		}
	}

	const { writeContract, isLoading } = useNotifier({
		onSuccess: () => {
			getUserFetch && getUserFetch()
			setOpen(false)
		}
	})

	const unSubscribeHandler = () => {
		writeContract({
			address: contracts.AuthorsClubs.address,
			abi: contracts.AuthorsClubs.abi,
			functionName: 'stop',
			args: [domain, profile.address]
		})
	}

	const hanbleSubmit = async () => {
		if (!quantity) {
			unSubscribeHandler()
			return
		}
		const { message, v, r, s }: any = await getSplitTransaction({
			token: 'SAVVA_VOTES', 
			spender: contracts!.AuthorsClubs.address,
			amount: Number(quantity) * Number(weeks),
			contract: 'Staking'
		})

		writeContract({
			address: contracts.AuthorsClubs.address,
			abi: contracts.AuthorsClubs.abi,
			functionName: 'buyWithPermit',
			args: [domain, profile.address, parseAmount(quantity), Number(weeks), message.deadline, v, r, s]
		})
	}

	const getSubscriptionData = async () => {
		if(!userData) return
		setIsloadingModal(true)
		const getSub = await contractRead('AuthorsClubs', 'getSub', [domain, userData?.address, profile.address])
		const getClub = await contractRead('AuthorsClubs', 'getClub', [domain, profile.address])
		setWeeks(Number(getSub[1]) - Number(getClub[1]) + 1)
		setQuantity(Number(getSub[0]) / DEFAULT_PREC)
		setIsloadingModal(false)
	}

	useEffect(() => {
		if(edit && open) {
			getSubscriptionData() 
		}
	}, [open])

	const total = Number(quantity) * Number(weeks) || 0
	const upperThenBalance = total  > stakedBalance

	return (
		<Dialog open={open} onOpenChange={closeHandle}>
			<DialogTrigger asChild>
				<GuardButton
					title={edit && !other ? <Edit size={20}/> : edit ? <div className='gap-2 flex flex-row items-center'>{langPack('profile.you_sponsored')}<Edit size={16}/></div> : langPack('profile.sponsor_user')}
					variant={edit && !other ? 'ghost' : edit ? 'outline' : 'orange'}
					size={'sm'}
				/>
			</DialogTrigger>
			<DialogContent className='sm:max-w-[525px]' loading={isLoadingModal || isLoading}>
				<DialogHeader>
					<DialogTitle> {langPack('profile.sponsor_user')}</DialogTitle>
				</DialogHeader>
				<div className='text-[14px] text-[grey]'>{langPack('profile.amount_info')}</div>
				{quantity && quantity > Number(stakedBalance) ? (
					<span className='text-destructive'>{langPack('profile.balance_error')}</span>
				) : null}
				<AmountInput
					value={quantity}
					onChange={setQuaintyHandler}
					placeholder={`Enter value`}
					label={langPack('fund.quantity')}
				/>
				<AmountInput
					value={weeks}
					onChange={setWeeksHandler}
					placeholder={`Enter value`}
					label={langPack('profile.week_count')}
					perUsd={false}
					// defaultValue={1}
					disabled={stakedBalance === 0}
					noDigits
				/>
				<div className='relative grid grid-cols-4 items-center gap-4'>
					<Label className='text-right'>{langPack('profile.total')}</Label>
					<div className='flex flex-col items-start'>
						<AmountBlock amount={total * DEFAULT_PREC} size='sm' danger={upperThenBalance} />
					</div>
				</div>
				<div className='relative grid grid-cols-4 items-center gap-4'>
					<Label className='text-right'>{langPack('profile.stake_balance')}</Label>
					<div className='flex flex-col items-start'>
						<AmountBlock amount={stakedBalance * DEFAULT_PREC} size='sm' />
					</div>
				</div>
				<DialogFooter>
					{stakedBalance === 0 ? (
						<div className='flex w-full flex-col'>
							<div className='mb-3 text-center text-[13px] text-[red]'>{langPack('profile.low_stake')}</div>
							<StakeModal getData={() => {}} />
						</div>
					) : (
						<>
						{edit && weeks > 1 && <Button
							onClick={unSubscribeHandler}
							className='text-[white]'
							variant={'destructive'}
							loading={isLoading}
						>
							{langPack('action.delete')}
						</Button>}
						<Button
							onClick={hanbleSubmit}
							className='text-[white]'
							variant='orange'
							loading={isLoading}
							disabled={upperThenBalance || stakedBalance === 0 || quantity > Number(stakedBalance) || !quantity || !weeks}
						>
							{langPack('action.save')}
						</Button>
						</>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default SponsorModal
