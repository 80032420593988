import { stringToBytes32 } from '@/shared/lib'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { useEffect, useState } from 'react'
import { formatEther } from 'viem'
import { useReadContracts } from 'wagmi'

// TODO REMOVE
export const useCheckStaking = ({ address }: { address: `0x${string}` | undefined }) => {
	const [isLowStake, setIsLowStake] = useState(false)
	const { contracts } = ContractsStore()

	const { data, refetch } = useReadContracts({
		contracts: [
			{
				abi: contracts.Config.abi,
				address: contracts.Config.address,
				functionName: 'getUInt',
				args: [stringToBytes32('min_staked_to_post')]
			},
			{
				abi: contracts.Staking.abi,
				address: contracts.Staking.address,
				functionName: 'balanceOf',
				args: [address]
			}
		]
	})
	useEffect(() => {
		if (data && data[0].status === 'success' && data[1].status === 'success') {
			
			setIsLowStake(Number(formatEther(data[0].result)) > Number(formatEther(data[1].result)))
		}
	}, [address, setIsLowStake, data])

	return {
		isLowStake,
		minStake: (data && data[0].status === 'success' && formatEther(data[0].result)) || null,
		refetch
	}
}
