export const ROUTER = {
	leaders: 'leaders',
	actual: 'actual',
	new: 'new',
	forYou: 'for-you',
	comments: 'comments',
	content: 'content',
	newPost: 'new-post',
	postEdit: 'post-edit',
	commentEdit: 'comment-edit',
	newComment: 'new-comment',
	configuration: 'configuration',
	promoCode: 'promo-code',
	governance: 'governance',
	fundraise: 'fundraise',
	search: 'search'
} as const 