import { UserCard } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'
import useRequest from '@/shared/lib/use-request'
import { LangStore } from '@/shared/model/store'
import { Button, Dialog, DialogContent, DialogHeader, DialogTrigger, ScrollArea } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { useEffect, useState } from 'react'
import Votebadge from './vote-badge'

type Props = {
	proposal_id: string
}

type TVoter = {
	voter: IUser
	support: number
	weight: number
}

const VoitersModal = ({ proposal_id }: Props) => {
	const { langPack } = LangStore()
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const { fetch, result } = useRequest<TVoter[]>('listVoters')

	useEffect(() => {
		if (isOpen) {
			fetch({
				params: {
					proposal: proposal_id
				}
			})
		}
	}, [isOpen])

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogTrigger asChild>
				<Button variant={'link'} className='px-0'>
					{langPack('governance.voters_list')}
				</Button>
			</DialogTrigger>
			<DialogContent className='max-h-[700px] max-w-[800px] overflow-hidden'>
				<DialogHeader>{langPack('governance.voters_list')}</DialogHeader>
				<ScrollArea className='max-h-[600px] pr-4'>
					{(result || []).map((el: TVoter) => (
						<div className='mb-3 flex w-full flex-row items-center justify-between gap-4' key={el.voter.address}>
							<UserCard user={el.voter} length={15} />
							<div className='flex w-[40%] flex-row justify-between gap-4'>
								<div>
									<Votebadge vote={el.support} />
								</div>
								<div>
									<AmountBlock amount={el.weight} withoutIcon />
								</div>
							</div>
						</div>
					))}
				</ScrollArea>
			</DialogContent>
		</Dialog>
	)
}

export default VoitersModal
