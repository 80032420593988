import { Bot, Loader2Icon, RefreshCw, User } from 'lucide-react'
import React, { FC, useState } from 'react'
import { ConfigStore } from '@/shared/model/store'
import { Avatar, AvatarFallback, AvatarImage } from '@/shared/ui'
import defaultAvatar from '@/shared/assets/images/avatar-default.svg'
import { UserName } from '@/shared/ui/user-name'
import { IUser } from '../model/types'
import { Link } from 'react-router-dom'
import { cn } from '@/shared/lib'

type Props = {
	user: IUser | null
	descriprion?: string | React.ReactNode
	length?: number
	onlyAvatar?: boolean
	forTrigger?: boolean
	className?: string
	onClick?: () => void
}

const url = ConfigStore.getState().baseUrl

export const getUserProfileLink = (user: IUser | null | undefined) =>
	user ? (user.name ? `/@${user.name}` : `/${user.address}`) : ''

export const UserCard: FC<Props> = ({ user, descriprion, length, onlyAvatar, forTrigger, className, onClick }) => {
	const [imgLoading, setImgLoading] = useState<boolean>(true)
	const link = getUserProfileLink(user)

	if (forTrigger) {
		return (
			<div>
				<Avatar className={cn('bg-secondary', className)}>
					<AvatarImage
						onLoad={() => setImgLoading(false)}
						src={user?.avatar ? `${url}ipfs/${user.avatar}` : defaultAvatar}
						alt='name'
					/>
					<AvatarFallback>
						{imgLoading ? (
							<RefreshCw className='h-4 w-4 animate-spin' />
						) : user?.avatar === 'system' ? (
							<Bot />
						) : (
							<User />
						)}
					</AvatarFallback>
				</Avatar>
			</div>
		)
	}

	return (
		<Link className='flex items-center gap-1' to={link} onClick={onClick && onClick}>
			<Avatar className={className}>
				<AvatarImage
					src={user?.avatar ? `${url}ipfs/${user.avatar}` : defaultAvatar}
					alt='name'
					onLoad={() => setImgLoading(false)}
				/>
				<AvatarFallback>
					{imgLoading ? <RefreshCw className='h-4 w-4 animate-spin' /> : user?.avatar === 'system' ? <Bot /> : <User />}
				</AvatarFallback>
			</Avatar>
			{!onlyAvatar && (
				<div className='ml-3 flex h-full flex-col justify-evenly'>
					<UserName account={user ?? ({} as IUser)} sliceLen={length} />
					<div>
						<p className='text-sm text-muted-foreground'>{descriprion}</p>
					</div>
				</div>
			)}
		</Link>
	)
}
