import { cn } from "@/shared/lib";
import VoitersModal from "./voiters";

interface VotingProgressBarProps {
  votes: number[]; 
  quorum: number;
  proposal_id: string
}

const VotingProgressBar = ({ votes, quorum, proposal_id }: VotingProgressBarProps) => {
  const [againstVotes, forVotes, abstainVotes] = votes;

  // Вычисляем общее количество голосов
  const totalVotes = againstVotes + forVotes + abstainVotes;

  // Вычисляем процент для каждого типа голосов
  const againstPercentage = (againstVotes / totalVotes) * 100 || 0;
  const forPercentage = (forVotes / totalVotes) * 100 || 0;
  const abstainPercentage = (abstainVotes / totalVotes) * 100 || 0;
  const quorumPercentage = (totalVotes / quorum) * 100 || 0;

  const red = quorumPercentage > 100 ? "text-red-500" : 'text-stone-500'
  const green = quorumPercentage > 100 ? "text-green-500" : 'text-gray-500'
  const grey = quorumPercentage > 100 ? "text-gray-500" : 'text-zinc-500'

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="w-full h-4 bg-gray-200 rounded-md overflow-hidden">
        <div
          className={cn("h-full inline-block bg-red-500")}
          style={{ width: `${againstPercentage}%` }}
        />
        <div
          className={cn("h-full inline-block bg-green-500")}
          style={{ width: `${forPercentage}%` }}
        />
        <div
          className={cn("h-full inline-block bg-gray-500")}
          style={{ width: `${abstainPercentage}%` }}
        />
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className={red}>NO:{againstPercentage.toFixed()}%</div>
        <div className={green}>YES:{forPercentage.toFixed()}%</div>
        <div className={grey}>ABSTAIN:{abstainPercentage.toFixed()}%</div>
        <div className={quorumPercentage > 100 ? "text-green-500" : 'text-red-500'}>QUORUM:{quorumPercentage.toFixed()}%</div>
      </div>
      {totalVotes > 0 && 
        <div>
          <VoitersModal proposal_id={proposal_id} />
        </div>
      }
     
    </div>
    
  );
};

export default VotingProgressBar;