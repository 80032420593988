import { Clock, Check, X, Play, CheckCircle, Circle, Zap } from "lucide-react";
import { cn } from "../lib";

const statusIcons = {
    Pending: <Clock className="w-4 h-4 mr-2" />,
    Active: <Play className="w-4 h-4 mr-2" />,
    Canceled: <X className="w-4 h-4 mr-2" />,
    Defeated: <X className="w-4 h-4 mr-2" />,
    Succeeded: <Check className="w-4 h-4 mr-2" />,
    Queued: <Circle className="w-4 h-4 mr-2" />,
    Expired: <Zap className="w-4 h-4 mr-2" />,
    Executed: <CheckCircle className="w-4 h-4 mr-2" />,
};

export interface StatusBadgeProps {
    status: "Pending" | "Active" | "Canceled" | "Defeated" | "Succeeded" | "Queued" | "Expired" | "Executed";
}
  

export const StatusBadge = ({ status }: StatusBadgeProps) => {
    const statusStyles = {
        Pending: "bg-yellow-500 hover:bg-yellow-600 text-white",
        Active: "bg-blue-500 hover:bg-blue-600 text-white",
        Canceled: "bg-gray-500 hover:bg-gray-600 text-white",
        Defeated: "bg-red-500 hover:bg-red-600 text-white",
        Succeeded: "bg-green-500 hover:bg-green-600 text-white",
        Queued: "bg-purple-500 hover:bg-purple-600 text-white",
        Expired: "bg-orange-500 hover:bg-orange-600 text-white",
        Executed: "bg-teal-500 hover:bg-teal-600 text-white",
    };

    return (
        <div
            className={cn(
            "inline-flex items-center justify-center rounded-full px-3 py-1 text-sm font-semibold transition-colors animate-fade-in",
            statusStyles[status]
        )}
        >
        {statusIcons[status]}
        {status}
        </div>
    );
};