import { DrawerTrigger, DrawerContent, Drawer, Button } from '@/shared/ui'
import { LangStore } from '@/shared/model/store'
import { useState } from 'react'
import { XIcon } from 'lucide-react'
import Fundraise from './fundraise'

type Props = {
  id: number
  trigger?: JSX.Element
}

const FundraiseDrawer = ({ id, trigger }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const { langPack } = LangStore()
  
  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        {trigger || <Button variant="orange" className='w-full'>{langPack('fundraise.fundraise')}</Button>}
      </DrawerTrigger>
      <DrawerContent className='h-[98vh]'>
        <div className='flex flex-row absolute right-2 top-2 items-center justify-between gap-4 z-[999]'>
          <Button onClick={() => setOpen(false)} size={'icon'} variant={'outline'}>
            <XIcon onClick={() => setOpen(false)}/>
          </Button>
        </div>
        <Fundraise id={id}/>
    </DrawerContent>
    </Drawer>
  )
}

export default FundraiseDrawer