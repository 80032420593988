import { Button, Card, Label, ScrollArea, Separator } from '@/shared/ui'
import React, { useEffect, useState } from 'react'
import useFundraise, { getPercent, TTokensFundraise } from './use-fundraise'
import { AmountBlock, getIcon } from '@/shared/ui/amount-block/amount-block'
import { cn, DEFAULT_PREC, getSplitTransaction, outContractRead } from '@/shared/lib'
import { UserCard, UserStore } from '@/entities/user'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { LangStore } from '@/shared/model/store'
import { useWallet } from '@/entities/wallet'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import useNotifier from '@/shared/lib/use-notifier'
import { null_address } from '@/shared/model/types'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { Check, RefreshCw, TicketX } from 'lucide-react'
import { ERC20_ABI } from '../model/const'
import LoadingIcon from '@/shared/ui/loading-icon'
import useRequest from '@/shared/lib/use-request'
import { IUser } from '@/entities/user/model/types'
import SocialShare from '@/shared/ui/share-block/share-block'

type Props = {
    id: number
}

type TFundraisersUser = {
    amount: number
    user: IUser
}

const DepositBlock = ({children, title, className}: {children: React.ReactNode; title?: string; className?: string}) => (
    <div className='flex flex-col  w-[100%] md:w-[30%] overflow-hidden'>
       {title && <div className='text-center text-[16px] font-bold mt-4 mb-2'>{title}</div>}
        <Card className={cn('relative h-full min-h-[300px] flex flex-col', className && className)}>
            {children}
        </Card>
    </div>
)

const Fundraise = ({id}: Props) => {
    const { getFundRiseData, fundraise, percentage, currentP, isBiggest, accepteedTokens, fee, isLoading: isLoadingFundrise } = useFundraise()
    const { message } = useSubscribe('fundraiser_contribution')
    const { message: closeFundrise } = useSubscribe('fundraiser_closed')
    const { fetch, result} = useRequest<TFundraisersUser[]>('listFrDonators')
    const { contracts } = ContractsStore()
    const { userData } = UserStore()
    const { plsBalanceUnparced, savvaBalanceUnparced } = useWallet()
    const [selectedToken, setSelectedToken] = useState<TTokensFundraise>()
    const [currentBalance, setCurrentbalance] = useState<number>(0)
    const [quantity, setQuantity] = useState<number | null | undefined>()
    const [loadingBalance, setLoadingBalance] = useState<boolean>(false)
    const { langPack } = LangStore()
    
    const getBalance = async (address: `0x${string}`) => {
        if(!userData?.address) return
        setLoadingBalance(true)
        const balance = await outContractRead(address, ERC20_ABI, 'balanceOf', [userData?.address])
        setCurrentbalance(Number(balance))
        setLoadingBalance(false)
    }

    const selectToken = async (token: TTokensFundraise) => {
        setQuantity(undefined)
        switch (token.symbol) {
            case 'PLS':
                setCurrentbalance(Number(plsBalanceUnparced))
            break;
            case 'SAVVA':
                setCurrentbalance(Number(savvaBalanceUnparced))
            break;
            default:
                getBalance(token.address)
            break;
        }
        setSelectedToken(token)
    }

    const { writeContract, isLoading } = useNotifier({
        onSuccess: () => {
            setQuantity(undefined)
            getFundRiseData(id)
        }
    })

    const closeCampaing = () => {
        writeContract({
            address: contracts.Fundraiser.address,
            abi: contracts.Fundraiser.abi,
            functionName: 'closeCampaign',
            args: [
              Number(id),
            ],
          })
    }
    const handleDeposit = async () => {
        if(selectedToken?.symbol === 'PLS'){
            writeContract({
                address: contracts.Fundraiser.address,
                abi: contracts.Fundraiser.abi,
                functionName: 'contribute',
                args: [
                  id,
                  null_address,
                  BigInt((quantity || 0) * DEFAULT_PREC)
                ],
                value: BigInt((quantity || 0) * DEFAULT_PREC)
              })
        } else {
            const {message, v, r, s} = await getSplitTransaction({
            token: selectedToken?.symbol === 'SAVVA' ? selectedToken?.symbol : selectedToken?.name,
            spender: contracts!.Fundraiser.address,
            amount: quantity,
            ...(selectedToken?.symbol !== 'SAVVA' && {
                address: selectedToken?.address,
                decimals: selectedToken?.decimals
            }),
        })

        writeContract({
            address: contracts.Fundraiser.address,
            abi: contracts.Fundraiser.abi,
            functionName: 'contributeWithPermit',
            args: [
              Number(id),
              selectedToken?.address,
              message.value,
              message.deadline,
              v,
              r,
              s,
            ],
          })}
    }
    
    useEffect(() => {
        if(!id) return
        getFundRiseData(id)
        fetch({
            params: {
                id,
            }
        })
    }, [id])
 
    useEffect(() => {
        if(id && message && message.id === Number(id)) {
            getFundRiseData(id)
            fetch({
                params: {
                    id,
                }
            })
        }
    }, [message])  

    useEffect(() => {
        if(id && closeFundrise && closeFundrise.id === Number(id)) {
            getFundRiseData(id)
        }
    }, [closeFundrise])
    

    useEffect(() => {
        if(accepteedTokens.length > 1) {
            setSelectedToken(accepteedTokens.find((el: TTokensFundraise) => el.symbol === 'SAVVA'))
            setCurrentbalance(Number(savvaBalanceUnparced))
        }
    },[accepteedTokens])

    const isMy = userData?.address === fundraise?.user?.address

    if(isLoadingFundrise && !fundraise?.title) return (
        <div className='flex flex-row w-full h-full items-center justify-center'>
            <LoadingIcon className='w-14 h-14 text-[grey]'/>
        </div>
    )

    if(!fundraise.user?.address || fundraise?.user?.address === null_address) return (
    <Card className='h-[200px] w-full flex flex-col items-center justify-center font-bold'>
        <TicketX size={52}/>
        {langPack('fundraise.not_created')}
    </Card>
    )


  
  return (
    <div className='flex flex-col w-max-90vw overflow-hidden box-border'>
        <div className='w-full text-center text-[24px] font-bold relative mb-5 uppercase'>
            {`${langPack('fundraise.fundraise')} #${fundraise.id}`}
        </div>

        <div className='flex flex-row flex-wrap  w-[90%]  md:justify-between  md:h-[210px]  m-auto gap-4'>
            <DepositBlock className='bg-background min-h-0 p-4'>
                <UserCard user={fundraise.user}/>
                <Separator orientation='horizontal' className='mt-3'/>
                <div className='text-[grey] text-[12px] mt-3'>{fundraise.title}</div>
            </DepositBlock>
            <DepositBlock className='p-7 bg-background relative min-h-0 justify-center items-center'>
                <div className='flex flex-row w-full justify-between items-center'>
                    <div className='flex flex-col items-center'>
                        <div className=' text-[grey]'>{langPack('fundraise.founded')}</div>
                        <AmountBlock amount={fundraise.raised} size='md' perUsd usdClassName='-mt-3 absolute'/>
                    </div>
                    <div className='text-[35px] mt-5'>/</div>
                    <div className='flex flex-col items-center'>
                        <div className='text-[grey]'>{langPack('fundraise.target')}</div>
                        <AmountBlock amount={fundraise.target_amount} size='md' perUsd usdClassName='-mt-3 absolute'/>
                    </div>
                </div>
                {isMy && <Button loading={isLoading} className='w-[80%] mt-8' onClick={closeCampaing}>{langPack('fundraise.finish')}</Button>}
            </DepositBlock>
            <DepositBlock className='items-center p-7 bg-background min-h-0'>
                <div className='flex flex-col items-center'>
                    <div className='text-[grey]'>{langPack('fundraise.percent')}</div>
                    <div className='text-[35px] font-bold'>{percentage.toFixed(2)}%</div>
                </div>
                <div className={cn("relative w-[90%] h-6 bg-gray-200 m-auto mt-4 rounded-md overflow-hidden", isBiggest && 'bg-orange-400')}>
                    <div
                        className={`absolute top-0 left-0 h-full bg-green-500 transition-all duration-300`}
                        style={{ width: `${isBiggest ? currentP : percentage}%` }}
                    >
                    </div>
                    <div className="absolute right-1 z-10 font-bold text-[#fff]">{percentage.toFixed(2)}%</div>
                </div>
            </DepositBlock>
        </div>

        <div className='flex flex-row flex-wrap m-auto justify-between w-[90%]'>
            <DepositBlock title={langPack('fundraise.select_token')}>
                {
                    accepteedTokens.map((el: TTokensFundraise, index: number) => (
                        <div
                            key={index}
                            onClick={() => selectToken(el)}
                            className={cn('flex flex-row gap-4 items-center p-4 hover:opacity-70 cursor-pointer relative', selectedToken?.symbol === el.symbol && 'bg-background/75')}
                        >
                            <div className="scale-[1] ">{getIcon(el.symbol)}</div>
                            <div>
                                <div className='flex flex-row items-start'>
                                    <div className='text-[32px] font-bold'>{el.symbol}</div>
                                    <div className='text-[12px] text-[grey] mt-1'>{el.comment}</div>
                                </div>
                                <div className='text-[14px] text-[grey]'>{el.name}</div>
                            </div>
                            {selectedToken?.name === el.name && 
                                <div className={cn('absolute -translate-y-1/2 right-4 bottom-2 border-2 rounded-full border-[green] opacity-100 p-1')}>
                                    <Check className={'stroke-[green] opacity-100'}/>
                                </div>
                            }
                        </div>
                    ))
                }
            </DepositBlock>
            <DepositBlock title={langPack('fundraise.enter_deposit_data')} className='items-center pt-4'>
                {!!selectedToken?.symbol && <>
                    <div className='relative flex flex-col gap-4 items-center justify-center'>
                        <Label className='text-right font-bold text-[16px]'>{langPack('default.balance')}</Label>
                        <div className='flex flex-col items-start justify-center h-7'>
                            {loadingBalance
                                ? <RefreshCw className="mt-2 h-8 w-8 animate-spin" />
                                : <AmountBlock
                                    amount={currentBalance}
                                    size='md' danger={false}
                                    token={selectedToken.symbol}
                                    decimals={selectedToken.decimals}
                                    contract={selectedToken.address}
                                    short={false}
                                    perUsd
                                    usdClassName='-mt-3 absolute'
                                />
                            }
                        </div>
                    </div>
                    <div className='flex w-[80%] flex-col gap-2 mt-10'>
                        <AmountInput
                            value={quantity}
                            onChange={setQuantity}
                            placeholder={`${langPack('fund.quantity')}*`}
                            token={selectedToken.symbol}
                            contract={selectedToken.address}
                        />
                    </div>
                    <div className='relative flex flex-col items-center gap-4 mt-4 w-[80%]'>
                        <Button
                            onClick={handleDeposit}
                            variant={'orange'}
                            loading={isLoading}
                            className='w-full'
                            disabled={currentBalance / Math.pow(10, selectedToken.decimals) < quantity || !quantity || isLoading}
                        >
                            {langPack('fundraise.deposit')}
                        </Button>
                    </div>

                    <div className='absolute bottom-1 flex flex-row w-full px-8 text-[10px] text-[grey]'>
                        <span>*</span>
                        <span>{`${langPack('fundraise.fee_desctiption')} ${fee / 100}%`}</span>
                    </div>
                   
                </>
            }
            </DepositBlock>
            <DepositBlock title={langPack('fundraise.list_of_depositor')} className='p-2'>
                <ScrollArea className='flex flex-col w-full h-[365px]'>
                    {result && result.map((el: TFundraisersUser) => (
                        <Card className='flex flex-col items-start justify-start w-full bg-background p-2 mb-3'>
                            <UserCard user={el.user}/>
                            <Separator  className='my-2'/>
                            <div className='flex flex-row items-center justify-between w-full'>
                                <AmountBlock amount={el.amount}/>
                                <span className='font-bold'>
                                    {getPercent(el.amount, fundraise.raised).toFixed(2)}%
                                </span>
                            </div>
                        </Card>
                    ))}
                </ScrollArea>
            </DepositBlock>
        </div>
        <div className='flex flex-row justify-center w-full mt-4'>
            <SocialShare shareUrl={`${window.location.origin}/fundraise/${id.toString()}`} />
        </div> 
    </div>
  )
}

export default Fundraise
