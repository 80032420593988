import { UserStore } from '@/entities/user'
import { getUserProfileLink } from '@/entities/user/ui/user-card'
import { AppSetup } from '@/features/app-setup'
import { Auth } from '@/features/auth'
import { LangToggleRow } from '@/features/lang-toggle/ui/lang-toggle-row'
import { TelegramConnect } from '@/features/telegram-connect'
import { ThemeToggleRow } from '@/features/theme-toggle/ui/theme-toggle-row'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button } from '@/shared/ui'
import { Link } from 'react-router-dom'
import { useAccount } from 'wagmi'

export const Usermenu = () => {
	const { signatureAddress, userData, isAdmin } = UserStore()
	const { isConnected, isDisconnected, address } = useAccount()
	const { langPack } = LangStore()
	const { gear } = ConfigStore()

	const userStatuses = [
		{ name: langPack('default.reader'), desk: langPack('default.reader_desk') },
		{ name: langPack('default.maecenas'), desk: langPack('default.maecenas_desk') },
		{
			name: `${langPack('default.writer')} / ${langPack('default.companion')}`,
			desk: langPack('default.companion_desk')
		},
		{ name: langPack('default.advanced'), desk: langPack('default.advanced_desk') }
	]

	const getStatus = () => {
		switch (true) {
			case !signatureAddress && isConnected:
				return langPack('default.writer')
			case signatureAddress && isDisconnected:
				return langPack('default.companion')
			case signatureAddress && isConnected:
				return langPack('default.advanced')
			default:
				return langPack('default.advanced')
		}
	}

	//FIXME: Вынести в auth

	return (
		<div>
			<Accordion type='single' collapsible className='mb-2'>
				<AccordionItem value='item-1'>
					<AccordionTrigger className='flex items-center'>
						<p className='text-sm font-medium leading-none'>
							{`${langPack('default.status')}:`} {getStatus()}
						</p>
					</AccordionTrigger>
					<AccordionContent>
						{userStatuses.map(el => (
							<div className='class="ml-4 mb-3 space-y-1' key={el.name}>
								<p className='mb-1 text-sm font-medium leading-none'>{el.name}</p>
								<p className='text-sm text-muted-foreground'>{el.desk}</p>
							</div>
						))}
					</AccordionContent>
				</AccordionItem>
				{signatureAddress && (
					<AccordionItem value='item-2'>
						<AccordionTrigger className='flex items-center'>
							<p className='text-sm font-medium leading-none'>{langPack('default.connect_telegram')}</p>
						</AccordionTrigger>
						<AccordionContent>
							<TelegramConnect />
						</AccordionContent>
					</AccordionItem>
				)}
			</Accordion>
			<div>
				<ThemeToggleRow />
				<LangToggleRow />
				{gear && <AppSetup />}
			</div>
			{(address || signatureAddress) && (
				<Button variant={'secondary'} className='mb-2 w-full'>
					<Link to={userData ? getUserProfileLink(userData) : address}>{langPack('default.in_profile')}</Link>
				</Button>
			)}
			{isAdmin && (
				<Button variant={'secondary'} className='mb-2 w-full'>
					<Link to={`/configuration`} className='font-bold'>
						{langPack('default.admin_panel')}
					</Link>
				</Button>
			)}
			<Auth />
			{isDisconnected && !signatureAddress && (
				<p className='text-sm text-muted-foreground'>{langPack('default.auth_error')}</p>
			)}
		</div>
	)
}
