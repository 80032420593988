import { useEffect } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'
import { toast } from './use-toast'
import { LangStore } from '../model/store'


const useCopyClipboard = () => {
    const [value, copy] = useCopyToClipboard()
    const { langPack } = LangStore()

    useEffect(() => {
        if(!value) return
        toast({
			duration: 5000,
			key: 'copy_promo',
			title: langPack('profile.copied_text'),
		})
    }, [value])

  return {
    copy,
    value
  }
}

export default useCopyClipboard