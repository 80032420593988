export const formattedDate = (date: string | Date, lang: string) => {
	if (!date || !lang) return
	const formattedDate = new Date(date)
	const formattedLang = `${lang}-${lang.toUpperCase()}`

	const now = new Date()
	const diffInMs = now.getTime() - formattedDate.getTime()
	const secondsDiff = Math.round(diffInMs / 1000)
	const minutesDiff = Math.round(diffInMs / (1000 * 60))
	const hoursDiff = Math.round(diffInMs / (1000 * 60 * 60))
	const daysDiff = Math.round(diffInMs / (1000 * 60 * 60 * 24))

	// Создаём экземпляр Intl.RelativeTimeFormat с нужной локалью
	const relativeFormatter = new Intl.RelativeTimeFormat(formattedLang, { numeric: 'auto' })

	// Если прошло меньше 7 дней, показываем относительное время
	if (Math.abs(daysDiff) < 7) {
		if (Math.abs(secondsDiff) < 60) {
			return relativeFormatter.format(-secondsDiff, 'second')
		} else if (Math.abs(minutesDiff) < 60) {
			return relativeFormatter.format(-minutesDiff, 'minute')
		} else if (Math.abs(hoursDiff) < 24) {
			return relativeFormatter.format(-hoursDiff, 'hour')
		} else {
			return relativeFormatter.format(-daysDiff, 'day')
		}
	}

	// Если прошло больше недели, показываем полную дату
	const dateFormatter = new Intl.DateTimeFormat(lang, {
		dateStyle: 'medium',
		timeStyle: 'short'
	})

	return dateFormatter.format(formattedDate)
}

export const formatTimeRemaining = (seconds: number, lang: string) => {
	if (!seconds || !lang) return

	const formattedLang = `${lang}-${lang.toUpperCase()}`

	// Создаём экземпляр Intl.RelativeTimeFormat с нужной локалью
	const relativeFormatter = new Intl.RelativeTimeFormat(formattedLang, { numeric: 'auto' })

	// Рассчитываем дни, часы, минуты и секунды
	const days = Math.floor(seconds / (3600 * 24))
	const hours = Math.floor((seconds % (3600 * 24)) / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const secs = seconds % 60

	// Формируем результат
	if (days > 0) {
		return relativeFormatter.format(days, 'day')
	}
	if (hours > 0) {
		return relativeFormatter.format(hours, 'hour')
	}
	if (minutes > 0) {
		return relativeFormatter.format(minutes, 'minute')
	}
	return relativeFormatter.format(secs, 'second')
}
